import React, { useState } from 'react'
import Login from '../../Login/Login'
import Footer from './Footer'
import logoImg from "../../../Images/logo.svg"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import de from 'react-phone-input-2/lang/de.json'
import contactBg from "../../../Images/contactBg.svg"
import mediaIcon01 from "../../../Images/mediaIcon01.svg"
import mediaIcon03 from "../../../Images/mediaIcon03.svg"
import "./Footer.css"
import { SendContactUsEmail } from '../../../Services/LoginAccount/accountservices'
import { msg } from '../../../Services/Axios'
import { AiFillCheckCircle } from "react-icons/ai";
const Kontakt = () => {
    const [UserRole, setUserRole] = useState('')
    const [FirstName, setFirstName] = useState("")
    const [LastName, setLastName] = useState("")
    const [Email, setEmail] = useState("")
    const [Mobile, setMobile] = useState("")
    const [Country, setCountry] = useState("")
    const [Subject, setSubject] = useState("")
    const [Message, setMessage] = useState("")
    const [errors, setErros] = useState({});
    const [show, setShow] = useState("")
    const optionValue = [
        'Land auswählen',
        'Deutschland', 'Afghanistan', 'Ägypten',
        'Albanien', 'Algerien', 'Amerikanische Jungferninseln',
        'Amerikanisch-Samoa', 'Andorra',
        'Angola', 'Anguilla', 'Antigua und Barbuda',
        'Äquatorialguinea', 'Argentinien', 'Armenien',
        'Aruba', 'Aserbaidschan', 'Äthiopien',
        'Australien', 'Bahamas', 'Bahrain',
        'Bangladesch', 'Barbados', 'Belarus',
        'Belgien', 'Belize', 'Benin',
        'Bermuda', 'Bhutan', 'Bolivien',
        'Bosnien und Herzegowina', 'Botsuana',
        'Bouvetinsel', 'Brasilien', 'Brunei',
        'Bulgarien', 'Burkina Faso', 'Burundi',
        'Chile', 'China', 'Cookinseln',
        'Costa Rica', 'Dänemark', 'Dominica',
        'Dominikanische Republik', 'Dschibuti',
        'Ecuador', 'El Salvador', 'Elfenbeinküste',
        'Eritrea', 'Estlandi', 'Färöer',
        'Fidschi', 'Finnland', 'Frankreich',
        'Gabun', 'Gambia', 'Georgien',
        'Ghana', 'Gibraltar', 'Grenada',
        'Griechenland', 'Grönland', 'Großbritannien',
        'Guadeloupe', 'Guam', 'Guatemala',
        'Guinea', 'Guinea-Bissau', 'Guyana',
        'Haiti', 'Honduras', 'Hongkong',
        'Indien', 'Indonesien', 'Irak',
        'Iran', 'Irland', 'Island',
        'Italien', 'Jamaika', 'Japan',
        'Jemen', 'Jordanien', 'Kambodscha',
        'Kamerun', 'Kanada', 'Kasachstan',
        'Katar', 'Kenia', 'Kirgisistan',
        'Kolumbien', 'Komoren', 'Kongo',
        'Kongo Demokratische Republik',
        'Kroatien', 'Kuba', 'Kuwait',
        'Laos', 'Lesotho', 'Lettland',
        'Libanon', 'Liberia', 'Libyen',
        'Liechtenstein', 'Litauen', 'Luxemburg',
        'Macau', 'Madagaskar', 'Malawi',
        'Malaysia', 'Malediven', 'Mali',
        'Malta', 'Marokko', 'Martinique',
        'Mauretanien', 'Mauritius', 'Mexiko',
        'Moldau', 'Monaco', 'Mongolei',
        'Montenegro', 'Montserrat', 'Mosambik',
        'Myanmar', 'Namibia', 'Nepal',
        'Neuseeland', 'Nicaragua', 'Niederlande',
        'Niger', 'Nigeria', 'Nodrkorea',
        'Nordmazedonien', 'Norwegen', 'Oman',
        'Österreich', 'Pakistan', 'Palau',
        'Panama', 'Papua-Neuguinea', 'Paraguay',
        'Peru', 'Philippinen', 'Polen',
        'Portugal', 'Puerto Rico', 'Ruanda',
        'Rumänien', 'Russland', 'Saint-Martin',
        'Salomonen', 'Sambia', 'Samoa',
        'San Marino', 'Saudi-Arabien', 'Schweden',
        'Schweiz', 'Senegal', 'Serbien',
        'Seychellen', 'Sierra Leone', 'Simbabwe',
        'Singapur', 'Slowakei', 'Slowenien',
        'Somalia', 'Spanien', 'Sri Lanka',
        'Südafrika', 'Sudan', 'Südkorea',
        'Südsudan', 'Suriname', 'Syrien',
        'Tadschikistan', 'Taiwan', 'Tansania',
        'Thailand', 'Togo', 'Tonga',
        'Trinidad und Tobago', 'Tschad', 'Tschechien',
        'Tunesien', 'Türkei', 'Turkmenistan',
        'Uganda', 'Ukraine', 'Ungarn',
        'Uruguay', 'Usbekistan', 'Vatikanstadt',
        'Venezuela', 'Vereinigte Arabische Emirate',
        'Vereinigte Staaten', 'Vietnam', 'Zentralafrikanische Republik', 'Zypern'
    ];
    const sendContactUsEmail = async () => {
        if (!validateForm()) {
            return
        }
        try {
            const payload = {
                FirstName: FirstName,
                LastName: LastName,
                UserRole: UserRole,
                Email: Email,
                Mobile: Mobile,
                Country: Country,
                Subject: Subject,
                Message: Message

            }
            const response = await SendContactUsEmail(payload)

            if (response?.data?.status) {
                setUserRole("")
                setFirstName("")
                setLastName("")
                setEmail("")
                setMobile("")
                setCountry("")
                setSubject("")
                setMessage("")
                setShow(response?.data?.message)
                setTimeout(() => {
                    setShow("")
                }, 2000);
            }
            else {
                console.log(response?.data?.message)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const validateForm = () => {
        var isValid = true;
        var emailValid = Email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (FirstName.trim() === "") {
            errors.FirstName = msg.firstnamekontakt
            isValid = false;
        }
        else {
            errors.FirstName = "";
        }
        if (LastName.trim() === "") {
            errors.LastName = msg.lastnamekontakt
            isValid = false;
        }
        else {
            errors.LastName = "";
        }
        if (UserRole.trim() === "") {
            errors.UserRole = msg.userrolekonkat
            isValid = false;
        }
        else {
            errors.UserRole = "";
        }
        if (Email.trim() === "") {
            errors.Email = msg.emailkonkat
            isValid = false;
        }
        else if (!emailValid) {
            errors.Email = msg.emailpatternkonkat;
            isValid = false;

        }
        else {
            errors.Email = "";
        }
        if (Mobile.trim() === "") {
            errors.Mobile = msg.mobilenokonkat
            isValid = false;
        }

        else {
            errors.Mobile = "";
        }
        if (Country.trim() === "") {
            errors.Country = msg.countrykonkat
            isValid = false;
        }

        else {
            errors.Country = "";
        }
        if (Subject.trim() === "") {
            errors.Subject = msg.subjectkonkat
            isValid = false;
        }

        else {
            errors.Subject = "";
        }
        if (Message.trim() === "") {
            errors.Message = msg.messageKonkat
            isValid = false;
        }

        else {
            errors.Message = "";
        }
        setErros({ ...errors, errors });
        return isValid;
    }
    
    return (
        <div>
            <section className="banner_sec innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="logo">
                                <img src={logoImg} alt="logo" />
                            </div>
                            <Login />
                        </div>
                    </div>
                </div>
            </section>
            <section className="heading_innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Kontaktieren Sie uns</h3>
                            <p>Sollten Sie Fragen haben, so können Sie uns <br /> jederzeit direkt kontaktieren.</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="contact_sec">
                    <div className="row">
                        <div className="col-sm-12">
                            <h4>Ihre Nachricht</h4>
                        </div>
                        <div className="col-sm-12 col-md-6">

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="Name">Name*</label>
                                        <input type="text" name="FirstName" value={FirstName} onChange={(e) => setFirstName(e.target.value)} className="form-control" placeholder="Vorname" />
                                        {!!errors.FirstName && <div style={{ color: 'red' }}>{errors.FirstName}</div>}
                                    </div>

                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-none d-lg-block" htmlFor="nachname">&nbsp;</label>
                                        <input type="text" className="form-control" name="LastName" value={LastName} onChange={(e) => setLastName(e.target.value)} placeholder="Nachname" />
                                        {!!errors.LastName && <div style={{ color: 'red' }}>{errors.LastName}</div>}
                                    </div>

                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="gender">Sie sind*</label>
                                        <select className="form-control" name="UserRole" value={UserRole} onChange={(e) => setUserRole(e.target.value)}>
                                            <option value="0">Wählen</option>
                                            <option value="Location-Suchende">Location-Suchende</option>
                                            <option value="Location-Anbieter">Location-Anbieter</option>
                                            <option value="Sonstiges">Sonstiges</option>
                                        </select>
                                        {!!errors.UserRole && <div style={{ color: 'red' }}>{errors.UserRole}</div>}
                                    </div>

                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="Name">E-Mail*</label>
                                        <input type="email" value={Email} name="Email" onChange={(e) => setEmail(e.target.value)} className="form-control" />
                                        {!!errors.Email && <div style={{ color: 'red' }}>{errors.Email}</div>}
                                    </div>

                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="mobil">Mobile</label>
                                        <PhoneInput
                                            placeholder="Rufnummer"
                                            localization={de}
                                            country={'de'}
                                            name="Mobile"
                                            value={Mobile}
                                            onChange={(Mobile) => setMobile(Mobile)}
                                        />
                                        {!!errors.Mobile && <div style={{ color: 'red' }}>{errors.Mobile}</div>}
                                    </div>

                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="Name">Land</label>
                                        <select className="form-control country-dropdown" name="Country" value={Country} onChange={(e) => setCountry(e.target.value)} placeholder="Select an option">
                                            {
                                                optionValue.map((item, index) => (
                                                    <option key={index} value={item}>{item}</option>
                                                ))
                                            }
                                        </select>
                                        {!!errors.Country && <div style={{ color: 'red' }}>{errors.Country}</div>}
                                    </div>

                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="betreff">Betreff*</label>
                                        <input type="text" className="form-control" name="Subject" value={Subject} onChange={(e) => setSubject(e.target.value)} />
                                        {!!errors.Subject && <div style={{ color: 'red' }}>{errors.Subject}</div>}
                                    </div>

                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="nachricht">Nachricht*</label>
                                        <textarea className="form-control" name="Message" value={Message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                        {!!errors.Message && <div style={{ color: 'red' }}>{errors.Message}</div>}
                                    </div>

                                </div>
                            </div>
                            <button className="theme_btn" onClick={sendContactUsEmail}>Senden</button>
                            <p className="appterms">
                                * Es gelten die Datenschutzbestimmungen und Allgemeinen Geschäftsbedingungen von VENOON
                            </p>
                            <div style={{ paddingTop: "20px" }}><p className="msg_show"> {show ? <span><AiFillCheckCircle className='icon_color' style={{ fontSize: "30px" }} /> {show}</span> : ""}</p></div>
                        </div>

                        <div className="col-sm-12 col-md-6 align-self-center">
                            <img className="contactBg" src={contactBg} alt="contact-img" />
                        </div>
                        <div className="col-sm-12">
                            <div className="media_contact">
                                <h4>Kontakt</h4>
                                <ul className="list_contact">
                                    <li>
                                        <div className="icon_round"><span><img src={mediaIcon01} alt="img" /></span></div>
                                        <div className="contacttext">
                                            <h6>Kontaktperson</h6>
                                            <p>Support Team</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon_round"><span><img src={mediaIcon03} alt="img" /></span></div>
                                        <div className="contacttext">
                                            <h6>E-Mail Adresse</h6>
                                            <a href="mailto:support@venoon.com">support@venoon.com</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Kontakt