import React from 'react'
import Sidenav from '../../Layout/SideNav/SideNav'
import "./CreateLocation.css"
const CreateLocation = () => {
  return (
    <React.Fragment>
      <div> 
        <Sidenav/>
      <p className='hellomsg'>Sieht so aus, als hättest du noch keine Location hinzugefügt</p>
      </div>
    
                     
    </React.Fragment>
  )
}

export default CreateLocation