import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './SubscriptionView.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetUserSubscription1 } from '../../../Services/Allservices/Admin/adminservices';
import moment from 'moment';
import Loader from '../../Utills/Loader';


const SubscriptionView = () => {
  const { state } = useLocation()
  const [subscriptionView, setSubscriptionView] = useState([])
  const [loading, setloading] = useState(false)
  const navigate = useNavigate()
  const getsubscriptionView = async () => {
    setloading(true)
    try {
      const payload = {
        InvoiceNo: state.id
      }
      const response = await GetUserSubscription1(payload)
      setSubscriptionView(response?.data?.result)
      if (response?.data?.status) {
        setloading(false)
      }
      else {
        console.log(response?.data?.result?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getsubscriptionView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const back = () => {
    navigate("/subscriptionlist")
  }
  return (
    <React.Fragment>
      {
        loading ? <Loader /> : <>
          <div className="main_container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading_div">
                  <h3 className="main_heading">Abonnementdetails</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="subscriptview">
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                      <ul className="list_group text-right listheadsub">
                        <li>RG-Nr. :</li>
                        <li>UserID :</li>
                        <li>UserTyp :</li>
                        <li>Vorname :</li>
                        <li>Nachname :</li>
                        <li>Straße :</li>
                        <li>Firmenname :</li>
                        <li>Nr. :</li>
                        <li>PLZ :</li>
                        <li>Ort :</li>
                        <li>E-Mail :</li>
                        <li>Rufnummer :</li>
                        <li>Office Nummer :</li>
                        <li>Steuernummer :</li>
                        <li>UStID :</li>
                        <li>Abo :</li>
                        <li>Abo-Start :</li>
                        <li>Abo-Ende :</li>
                        <li>Betrag :</li>
                      </ul>
                    </div>
                    <div className="col-6 col-sm-6 col-md-8 col-lg-9">
                      {
                        subscriptionView.map((item) => (
                          <ul className="list_group" key={item?.invoiceNo}>
                            <li>{item?.invoiceNo ? item?.invoiceNo : "-"}</li>
                            <li>{item?.userID ? item?.userID : "-"}</li>
                            <li>{item?.userType ? item?.userType : "-"}</li>
                            <li>{item?.firstName ? item?.firstName : "-"}</li>
                            <li>{item?.lastName ? item?.lastName : "-"}</li>
                            <li>{item?.road ? item?.road : "-"}</li>
                            <li>{item?.companyName ? item?.companyName : "-"}</li>
                            <li>{item?.num ? item?.num : "-"}</li>
                            <li>{item?.postCode ? item?.postCode : "-"}</li>
                            <li>{item?.place ? item?.place : "-"}</li>
                            <li>{item?.email ? item?.email : "-"}</li>
                            <li>{item?.mobileNumber ? item?.mobileNumber : "-"}</li>
                            <li>{item?.officeNumber ? item?.officeNumber : "-"}</li>
                            <li>{item?.taxNumber ? item?.taxNumber : "-"}</li>
                            <li>{item?.vstId ? item?.vstId : "-"}</li>
                            <li>{item?.subscriptionType ? item?.subscriptionType : "-"}</li>
                            <li>{item?.isDeleted ? "-" : moment(item?.subscriptionStart).format("DD.MM.YYYY HH:mm:ss")} </li>
                            <li>{item?.isDeleted ? "-" : moment(item?.subscriptionEnd).format("DD.MM.YYYY HH:mm:ss")}</li>
                            <li>{item?.isDeleted ? "-" : item?.amount ? item?.amount : "-"}</li>
                          </ul>
                        ))
                      }
                    </div>
                  </div>
                </div>
                <button type="submit" className="theme_btn" onClick={back}>Zurück</button>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default SubscriptionView
