/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import infoicon from "../../../Assests/dashboard/images/infoicon.svg"
import { Button, Card, CardBody, Col, } from 'reactstrap';
import "./CreateForm.css"
import { Calendar } from 'react-nice-dates'
import { GetLocationDates, SaveLocationCalender, SkipLocationCalenderAvailability } from '../../../Services/Allservices/LocationProvider/locationservices';
import moment from 'moment';
import { de } from 'date-fns/locale'
import 'react-nice-dates/build/style.css'
import { getDate, getMonth, getYear, isSameDay } from 'date-fns';
import { Modal } from 'react-bootstrap';
const MeinKalender = (props) => {
  const navigate = useNavigate()
  const { state } = useLocation();
  const { isEdit, editId } = state || {}
  const minDate = new Date(); // Today
  const [start, setStart] = useState({});
  const [end, setEnd] = useState({});
  const [rDates, setRDates] = useState([]);
  const [bDates, setBDates] = useState([]);
  const [period, setPeriod] = useState({});
  const [isClear, setClear] = useState(false);
  const [AvailableDates, setAvailableDates] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [idvalue, setIdvalue] = useState()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (isEdit === "edit" || isEdit === 'copy') {
      getLocationDates()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const setcleardate = () => {
    setAvailableDates([])
    setStart({})
    setPeriod({})
    setEnd({})
    setRDates([])
    setBDates([])
    setIdvalue()
    getLocationDates()

  }
  const getLocationDates = async () => {
    const response = await GetLocationDates({ locationId: (isEdit === 'edit' || isEdit === 'copy') ? editId : props.locationId })
    if (response.data.status) {

      setAvailableDates(response.data.result.locationDates.locationDates)
      let AvailableDates = response.data.result.locationDates.locationDates;
      let ReservedDates = response.data.result.locationDates.reservationDates;
      let BookedDates = response.data.result.locationDates.bookedDates;

      let newAvailObject = {};
      let newReserveObject = {};
      let newBookedObject = {};

      let availableFinal = [];
      let reserveFinal = [];
      let bookedFinal = [];

      if (AvailableDates != null && AvailableDates?.length) {
        availableFinal = AvailableDates.map((a) => {
          return (moment(a.avaliableDate).format("YYYY-MM-DD"))
        })


        availableFinal.forEach((day) => {
          newAvailObject[day] = {
            color: "#7E2845",
            textColor: 'white'
          };
        });
      }
      if (ReservedDates != null && ReservedDates?.length) {
        reserveFinal = ReservedDates.map((r) => {
          return moment(r.dateOfReservations).format("YYYY-MM-DD")
        })

        reserveFinal.forEach((day) => {
          newReserveObject[day] = {
            color: '#7B4397',
            textColor: 'white',
          };
        });
      }

      if (BookedDates != null && BookedDates?.length) {
        bookedFinal = BookedDates.map((b) => {
          return moment(b.dateOfReservations).format("YYYY-MM-DD")
        })

        bookedFinal.forEach((day) => {
          newBookedObject[day] = {
            color: '#C31352',
            textColor: 'white',
          };
        });
      }
      let wholePeriod = {

        ...newAvailObject,
        ...newReserveObject,
        ...newBookedObject
      }

      if (isEdit === "edit" || isEdit === 'copy') {
        setStart({});
        setEnd({});
        setRDates({ ...newReserveObject })
        setBDates({ ...newBookedObject })
        setPeriod(wholePeriod);
        setIdvalue(availableFinal)

      }
      if (isEdit === "create") {
        setStart({});
        setEnd({});
        setRDates(reserveFinal)
        setBDates(bookedFinal)
        setPeriod(wholePeriod);


      }
    }
  }

  const modifiers = {

    selected: date => [...Object.keys(period)].some(dates => isSameDay(new Date(dates), date)),
    highlight: date => [...Object.keys(AvailableDates)].some(dates => isSameDay(new Date(dates), date)),
    highlight1: date => [...Object.keys(rDates)].some(dates => isSameDay(new Date(dates), date)),
    highlight2: date => [...Object.keys(bDates)].some(dates => isSameDay(new Date(dates), date)),

  }

  const modifiersClassNames = {
    highlight: "-availble",
    highlight1: "-reserve",
    highlight2: "-booked",

  }

  function getDateString(timestamp) {
    const date = new Date(timestamp)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    let dateString = `${year}-`
    if (month < 10) {
      dateString += `0${month}-`
    } else {
      dateString += `${month}-`
    }
    if (day < 10) {
      dateString += `0${day}`
    } else {
      dateString += day
    }
    return dateString
  }

  function getPeriod(startTimestamp, endTimestamp) {
    const tempPeriod = {}
    let currentTimestamp = startTimestamp
    while (currentTimestamp < endTimestamp) {
      const dateString = getDateString(currentTimestamp)
      if (rDates?.length && rDates?.includes(dateString)) {
        currentTimestamp += 24 * 60 * 60 * 1000
      }
      else if (bDates?.length && bDates?.includes(dateString)) {
        currentTimestamp += 24 * 60 * 60 * 1000
      }
      else {
        tempPeriod[dateString] = {
          color: "#7E2845",
          textColor: 'white',
        }
        currentTimestamp += 24 * 60 * 60 * 1000
      }
    }
    while (currentTimestamp >= endTimestamp) {
      const dateString = getDateString(currentTimestamp)
      if (rDates?.length && rDates?.includes(dateString)) {
        currentTimestamp -= 24 * 60 * 60 * 1000
      }
      else if (bDates?.length && bDates?.includes(dateString)) {
        currentTimestamp -= 24 * 60 * 60 * 1000
      }
      else {
        tempPeriod[dateString] = {
          color: "#7E2845",
          textColor: 'white',
        }
        currentTimestamp -= 24 * 60 * 60 * 1000
      }
    }
    return { ...period, ...tempPeriod }

  }

  function setDay(dayObj) {
    const dateString = getDateString(dayObj)
    const day = getDate(dayObj)
    const month = getMonth(dayObj) + 1
    const year = getYear(dayObj)
    let selectDate = `${year}-`
    if (month < 10) {
      selectDate += `0${month}-`
    } else {
      selectDate += `${month}-`
    }
    if (day < 10) {
      selectDate += `0${day}`
    } else {
      selectDate += day
    }


    let indexofDate = AvailableDates.findIndex(x => moment(x.avaliableDate).format("YYYY-MM-DD") === getDateString(dayObj))

    if (indexofDate > -1) {
      AvailableDates.splice(indexofDate, 1);
    } else {
      AvailableDates.push({
        "locationId": (isEdit === 'edit' || isEdit === 'copy') ? editId : props.locationId,
        "avaliableDate": selectDate,
        "isAvaliable": true,
      });
    }


    if (isClear && Object.keys(period)?.length && Object.keys(period)?.includes(dateString)) {
      const { [dateString]: _, ...tempPeriod } = period
      setPeriod(tempPeriod)
    }
    else {
      if (rDates?.length && rDates?.includes(dateString)) { }
      else if (bDates?.length && bDates?.includes(dateString)) { }
      else {
        // timestamp returned by dayObj is in 12:00AM UTC 0, want local 12:00AM
        const timestamp = new Date(year, month - 1, day).getTime()
        const newDayObj = { ...dayObj, timestamp }
        // if there is no start day, add start. or if there is already a end and start date, restart
        const startIsEmpty = !!!Object.keys(start).length
        const endIsEmpty = !!!Object.keys(end).length

        if (startIsEmpty || !startIsEmpty && !endIsEmpty) {
          const tempPeriod = {
            [dateString]: {
              color: "#7E2845",
              textColor: 'white',
            },
          }
          let wholePeriod = { ...period, ...tempPeriod }

          setStart(newDayObj);
          setEnd({});
          setPeriod(wholePeriod);

        }
        else {
          const { timestamp: savedTimestamp } = start
          const tempPeriod = getPeriod(timestamp, timestamp)
          setStart(start);
          setEnd(end);
          setPeriod(tempPeriod);
        }
      }
    }
  }
  const CalenderSubmit = async () => {
    try {
      if (AvailableDates.length === 0) {
        const payload = {
          "LocationId": (isEdit === 'edit' || isEdit === 'copy') ? editId : props.locationId,
          "IsSkipDatesAvailability": true

        }
        const response = await SkipLocationCalenderAvailability(payload)

        if (response?.data?.status) {
          navigate("/mylocationlist")

        }
        else {
          console.log(response?.data?.result?.transactionMessage?.message)
        }
      }

      else {
        const payload = {
          "locationId": (isEdit === 'edit' || isEdit === 'copy') ? editId : props.locationId,
          "locationDates": AvailableDates,
          "setSkipDates": false,


        }
        const response = await SaveLocationCalender(payload)

        if (response?.data?.status) {
          navigate("/mylocationlist")
        }
        else {
          console.log(response?.data?.result?.transactionMessage?.message)
        }
      }
    }
    catch (err) {
      console.log(err)
    }
  }


  return (

    <React.Fragment>
      <div className="form_outer calendarblock">
        <div className="row">
          <Col sm="12">
            <Card>
              <h5>Mein Kalender</h5>
              <label className="deletecheckbox">Löschen
                <input type="checkbox" value="Löschen" onClick={() => setClear(!isClear)} />
                <span className="checkmarkcal"></span>
              </label>

              <img className="infoicon" onClick={() => setShow(true)} src={infoicon} alt="" />
              <ul className="tileme">
                <li><span className="dot"></span><span className="dottext">Verfügbar</span></li>
                <li><span className="dot one"></span><span className="dottextone">Reserviert</span></li>
                <li><span className="dot two"></span><span className="dottexttwo">Gebucht</span></li>
                <li><button className="theme_btn resetbutton" onClick={setcleardate}>Zurücksetzen</button></li>
              </ul>
              <CardBody>
                <div className="row justify-content-center">
                  <div className="line_top"></div>
                  <div className="col-sm-12">
                    <Calendar
                      minimumDate={new Date(moment(minDate).add(0, "days").format("YYYY-MM-DD"))}

                      locale={de}
                      modifiers={modifiers}
                      modifiersClassNames={modifiersClassNames}

                      onDayClick={setDay}
                    />

                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>

      <div className="nextPrevBtn">
        <Button className="theme_btn" onClick={() => props.onSelect('forth')} >
          Zurück
        </Button>
        <button style={{ cursor: 'pointer' }} className="theme_btn" onClick={CalenderSubmit} >
          Speichern
        </button>

      </div>

      <Modal className="infopop new_Pop" show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title>Kalender nutzen</Modal.Title>
          <button type="button" onClick={() => setShow(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="modalbg">
          <p>1. Bei Auswahl eines Zeitraums: Die gewünschten Tage bitte einzeln selektieren</p>
          <p>2. Bei Auswahl eines einzelnen Datums: Klicke einfach auf das jeweilige Datum</p>
          <p>3. Um ein Datum abzuwählen: Bitte vorher die Kalender-Funktion "Löschen" aktivieren. Die zu löschenden Tage bitte einzeln selektieren</p>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default MeinKalender