import React, { useEffect, useState } from 'react'
import { useLocation, NavLink } from 'react-router-dom';
import { Modal, Carousel } from 'react-bootstrap';
import infoicon from "../../../Assests/dashboard/images/infoicon.svg"
import { GetLocationDates, LocationDetailsForProvider, SaveLocationCalender } from '../../../Services/Allservices/LocationProvider/locationservices';
import locationimg from "../../../Assests/dashboard/images/locationimg01.jpg"
import "./ChangeLocation.css"
import { Calendar } from 'react-nice-dates'
import Loader from "../../Utills/Loader"
import moment from 'moment';
import { de } from 'date-fns/locale'
import { getDate, getMonth, getYear, isSameDay } from 'date-fns';
import VideoModal from 'react-modal-video';

const ChangeLocation = () => {
  const [show3, setShow3] = useState(false)
  const [show4, setShow4] = useState(false)
  const [period, setPeriod] = useState({});
  const [location, setlocation] = useState({})
  const [photo_video, setPhoto_Video] = useState([])
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [show, setShow] = useState(false)
  const { state } = useLocation();
  const [AvailableDates, setAvailableDates] = useState([]);
  const [start, setStart] = useState({});
  const [end, setEnd] = useState({});
  const [rDates, setRDates] = useState([]);
  const [bDates, setBDates] = useState([]);
  const [isClear, setClear] = useState(false);
  const [locid, setlocid] = useState()
  const [loading, setloading] = useState(false)

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');

  const handleVideoClick = (videoUrl) => {
   // console.log("Video clicked:", videoUrl);
    setSelectedVideoUrl(videoUrl);
    setShow4(true);
  };

  const handleCloseModal = () => {
    setShowVideoModal(false);
  };

  
  useEffect(() => {
    Changelocation();
    getLocationDates()
    setlocid(state.editId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locid])
  const Changelocation = async () => {
    setloading(true)
    try {
      const payload = {
        locationId: parseInt(state.editId)

      }
      const response = await LocationDetailsForProvider(payload)
      if (response?.data?.status) {
        setlocation(response?.data?.result?.reservationAndBooking)
        setPhoto_Video(response?.data?.result?.photosAndVideosList)
        setloading(false)
      }
      else {
        console.log(response?.data?.result?.transactionMessage?.message)
      }
    }
    catch (err) {
      console.log(err)
    }


  }
  const getLocationDates = async () => {
    const response = await GetLocationDates({ locationId: locid })
    if (response.data.status) {
      setAvailableDates(response.data.result.locationDates.locationDates)
      let AvailableDates = response.data.result.locationDates.locationDates;
      let ReservedDates = response.data.result.locationDates.reservationDates;
      let BookedDates = response.data.result.locationDates.bookedDates;

      let newAvailObject = {};
      let newReserveObject = {};
      let newBookedObject = {};

      let availableFinal = [];
      let reserveFinal = [];
      let bookedFinal = [];

      if (AvailableDates != null && AvailableDates?.length) {
        availableFinal = AvailableDates.map((a) => {
          return (moment(a.avaliableDate).format("YYYY-MM-DD"))
        })


        availableFinal.forEach((day) => {
          newAvailObject[day] = {
            color: "#7E2845",
            textColor: 'white'
          };
        });

      }
      if (ReservedDates != null && ReservedDates?.length) {
        reserveFinal = ReservedDates.map((r) => {
          return moment(r.dateOfReservations).format("YYYY-MM-DD")
        })

        reserveFinal.forEach((day) => {
          newReserveObject[day] = {
            color: '#7B4397',
            textColor: 'white',
          };
        });
      }

      if (BookedDates != null && BookedDates?.length) {
        bookedFinal = BookedDates.map((b) => {
          return moment(b.dateOfReservations).format("YYYY-MM-DD")
        })
        bookedFinal.forEach((day) => {
          newBookedObject[day] = {
            color: '#C31352',
            textColor: 'white',
          };
        });
      }

      let wholePeriod = {
        ...newAvailObject,
        ...newReserveObject,
        ...newBookedObject
      }

      setStart({});
      setEnd({});
      setRDates({ ...newReserveObject })
      setBDates({ ...newBookedObject })
      setPeriod(wholePeriod);
    }
  }
  const modifiers = {
    selected: date => [...Object.keys(period)].some(dates => isSameDay(new Date(dates), date)),
    highlight: date => [...Object.keys(AvailableDates)].some(dates => isSameDay(new Date(dates), date)),
    highlight1: date => [...Object.keys(rDates)].some(dates => isSameDay(new Date(dates), date)),
    highlight2: date => [...Object.keys(bDates)].some(dates => isSameDay(new Date(dates), date))
  }

  const modifiersClassNames = {
    highlight: '-availble',
    highlight1: "-reserve",
    highlight2: "-booked"
  }
  function getDateString(timestamp) {
    const date = new Date(timestamp)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    let dateString = `${year}-`
    if (month < 10) {
      dateString += `0${month}-`
    } else {
      dateString += `${month}-`
    }
    if (day < 10) {
      dateString += `0${day}`
    } else {
      dateString += day
    }
    return dateString
  }

  function getPeriod(startTimestamp, endTimestamp) {
    const tempPeriod = {}
    let currentTimestamp = startTimestamp
    while (currentTimestamp < endTimestamp) {
      const dateString = getDateString(currentTimestamp)
      if (rDates?.length && rDates?.includes(dateString)) {
        currentTimestamp += 24 * 60 * 60 * 1000
      }
      else if (bDates?.length && bDates?.includes(dateString)) {
        currentTimestamp += 24 * 60 * 60 * 1000
      }
      else {
        tempPeriod[dateString] = {
          color: "#7E2845",
          textColor: 'white',
        }
        currentTimestamp += 24 * 60 * 60 * 1000
      }
    }
    while (currentTimestamp >= endTimestamp) {
      const dateString = getDateString(currentTimestamp)
      if (rDates?.length && rDates?.includes(dateString)) {
        currentTimestamp -= 24 * 60 * 60 * 1000
      }
      else if (bDates?.length && bDates?.includes(dateString)) {
        currentTimestamp -= 24 * 60 * 60 * 1000
      }
      else {
        tempPeriod[dateString] = {
          color: "#7E2845",
          textColor: 'white',
        }
        currentTimestamp -= 24 * 60 * 60 * 1000
      }
    }
    return { ...period, ...tempPeriod }

  }

  function setDay(dayObj) {

    const dateString = getDateString(dayObj)
    const day = getDate(dayObj)
    const month = getMonth(dayObj) + 1
    const year = getYear(dayObj)
    let selectDate = `${year}-`
    if (month < 10) {
      selectDate += `0${month}-`
    } else {
      selectDate += `${month}-`
    }
    if (day < 10) {
      selectDate += `0${day}`
    } else {
      selectDate += day
    }
    let indexofDate = AvailableDates.findIndex(x => moment(x.avaliableDate).format("YYYY-MM-DD") === getDateString(dayObj))

    if (indexofDate > -1) {
      AvailableDates.splice(indexofDate, 1);
    } else {
      AvailableDates.push({
        "locationId": locid,
        "avaliableDate": selectDate,
        "isAvaliable": true,
      });
    }


    if (isClear && Object.keys(period)?.length && Object.keys(period)?.includes(dateString)) {
      const { [dateString]: _, ...tempPeriod } = period
      setPeriod(tempPeriod)

    }
    else {
      if (rDates?.length && rDates?.includes(dateString)) { }
      else if (bDates?.length && bDates?.includes(dateString)) { }
      else {
        // timestamp returned by dayObj is in 12:00AM UTC 0, want local 12:00AM
        const timestamp = new Date(year, month - 1, day).getTime()
        const newDayObj = { ...dayObj, timestamp }
        // if there is no start day, add start. or if there is already a end and start date, restart
        const startIsEmpty = !!!Object.keys(start).length
        const endIsEmpty = !!!Object.keys(end).length

        // eslint-disable-next-line no-mixed-operators
        if (startIsEmpty || !startIsEmpty && !endIsEmpty) {
          const tempPeriod = {
            [dateString]: {
              color: "#7E2845",
              textColor: 'white',
            },
          }
          let wholePeriod = { ...period, ...tempPeriod }

          setStart(newDayObj);
          setEnd({});
          setPeriod(wholePeriod);

        }
        else {
          const { timestamp: savedTimestamp } = start
          const tempPeriod = getPeriod(timestamp, timestamp)
          setStart(start);
          setEnd(end);
          setPeriod(tempPeriod);
        }
      }
    }
  }
  const CalenderSubmit = async () => {

    try {
      const payload = {
        "locationId": locid,
        "locationDates": AvailableDates,
        "setSkipDates": false,
      }
      const response = await SaveLocationCalender(payload)

      if (response?.data?.status) {
        setShow2(false)
      }
      else {
        console.log(response?.data?.result?.transactionMessage?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  const setcleardate = async () => {
    setAvailableDates([])
    setStart({})
    setPeriod({})
    setEnd({})
    setRDates([])
    setBDates([])
    getLocationDates()
  }
  return (
    <React.Fragment>{loading ? <Loader /> : <div className="main_container">

      <div className="row">
        <div className="col-sm-12">
          <div className="heading_div">
            <h3 className="main_heading">Locationdaten ändern</h3>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="locationimg_block">
            <img src={location.photoUrl ? `https://venoon.com/API${location.photoUrl}` : locationimg} alt="img" />
            <div>
              <NavLink className="editbutton" state={{ isEdit: 'edit', editId: location?.locationId, purpose: 'edit' }} to={{ pathname: "/alllocation" }}>BEARBEITEN</NavLink>
            </div>
          </div>
          <div className="locationDetail">
            <h4>{!!location.locationName && location.locationName}</h4>
            <ul>
              <li>
                <h5>Reservierungen</h5>
                <div className="detail_text">
                  <div className="left_part">
                    <h6>Reserviert(%)</h6>
                    <p>{!!location.reservationPercentage ? location.reservationPercentage : "0"}</p>
                  </div>
                  <div className="right_part">
                    <h6>Aktive Reservierungen</h6>
                    <p>{!!location.activeReservation ? location.activeReservation : "0"}</p>
                  </div>
                </div>
              </li>
              <li>
                <h5>Buchungen</h5>
                <div className="detail_text">
                  <div className="left_part">
                    <h6>Gebucht (%)</h6>
                    <p>{!!location.bookingPercentage ? location.bookingPercentage : "0"}</p>
                  </div>
                  <div className="right_part">
                    <h6>Aktive Buchungen</h6>
                    <p>{!!location.activeBooking ? location.activeBooking : "0"}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_text">
                  <div className="left_part">
                    <h5>Freie Tage </h5>
                    <p>{!!location.freeDays ? location.freeDays : "0"}</p>
                  </div>
                  <div className="right_part">
                    <h5>Interessenten</h5>
                    <p>{!!location.interestedPerson ? location.interestedPerson : "0"}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="new_btn">
            <NavLink className="theme_btn" onClick={() => setShow1(true)}>Fotos und Videos </NavLink>
            <NavLink className="theme_btn" onClick={() => setShow2(true)}>Verfügbarkeit bearbeiten</NavLink>
            <NavLink className="theme_btn" to="/mylocationlist">Zurück</NavLink>
          </div>
        </div>
      </div>

      <Modal className="new_Pop" show={show2} onHide={() => setShow2(false)} >
        <Modal.Header>
          <Modal.Title>Verfügbarkeit bearbeiten</Modal.Title>
          <button type="button" onClick={() => setShow2(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="modalbg">
          <h6>Mein Kalender</h6>
          <label className="deletecheckbox1"><span className="textblock">Löschen</span>
            <input type="checkbox" onClick={() => setClear(!isClear)} />
            <span className="checkmark1"></span>
          </label>
          <ul className="tileme">
            <li><span className="dot"></span><span className="dottext">Verfügbar</span></li>
            <li><span className="dot one"></span><span className="dottextone">Reserviert</span></li>
            <li><span className="dot two"></span><span className="dottexttwo">Gebucht</span></li>
            <li><img className="infoicon" onClick={() => setShow(true)} src={infoicon} alt="" /></li>
          </ul>
          <Calendar
            locale={de}
            modifiers={modifiers} modifiersClassNames={modifiersClassNames} onDayClick={setDay} />

          <div className="text-center mt-3">
            <NavLink className="theme_btn" onClick={() => setcleardate()}>Zurücksetzen</NavLink>
            <NavLink className="theme_btn" onClick={CalenderSubmit} >Speichern</NavLink>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="new_Pop" show={show1} onHide={() => setShow1(false)}  centered>
        <Modal.Header>
          <Modal.Title>{!!location.locationName && location.locationName}</Modal.Title>
          <button type="button" onClick={() => setShow1(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="modalbg">
          <h5>Foto & Video</h5>
          <p className="modalPara text-left"><span>Foto</span> – Hochgeladen</p>
          {photo_video && photo_video.length ?
            <ul className="uploadedItem">
              {
                photo_video.map((item) => (
                  !item.isVideo ?
                    <li key={item.id}><img onClick={() => setShow3(true)} src={`https://venoon.com/API${item?.url}`} alt="img" /></li>
                    :
                    ''
                ))
              }
            </ul>
            : ''
          }
          <p className="modalPara text-left"><span>Video</span> – Hochgeladen</p>
          {/*photo_video && photo_video.length ?
            <ul className="uploadedItem mb-2">
              {
                photo_video.map((item) => (
                  item.isVideo ?
                    <li key={item.id}>
                      <video onClick={() => setShow4(true)} autoplay>
                        <source src={`https://venoon.com/API${item?.url}`} type="video/mp4" />
                      </video>
                    </li>
                    :
                    ''
                ))
              }
            </ul>
            : ''
          */}

{photo_video && photo_video.length ? (
        <ul className="uploadedItem mb-2">
          {photo_video.map((item) => (
            item.isVideo ? (
              <li key={item.id}>
                <video onClick={() => handleVideoClick(`https://venoon.com/API${item?.url}`)}>
                  <source src={`https://venoon.com/API${item?.url}`} type="video/mp4" />
                </video>
              </li>
            ) : (
              null
            )
          ))}
        </ul>
      ) : (
        null
      )}

      <VideoModal isOpen={showVideoModal} onClose={handleCloseModal} videoUrl={selectedVideoUrl} />


        </Modal.Body>
      </Modal>
      <Modal className="infopop new_Pop" show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title>Kalender nutzen</Modal.Title>
          <button type="button" onClick={() => setShow(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="modalbg">
          <p>1. Bei Auswahl eines Zeitraums: Die gewünschten Tage bitte einzeln selektieren</p>
          <p>2. Bei Auswahl eines einzelnen Datums: Klicke einfach auf das jeweilige Datum</p>
          <p>3. Um ein Datum abzuwählen: Bitte vorher die Kalender-Funktion "Löschen" aktivieren. Die zu löschenden Tage bitte einzeln selektieren</p>
        </Modal.Body>
      </Modal>
      <Modal className="infopop new_Pop" show={show3} onHide={() => setShow3(false)} aria-labelledby="contained-modal-title-vcenter" centered>
      <button type="button" onClick={() => setShow3(false)} className="close videoblockclose" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
        {
          photo_video.length === 1 ?

            photo_video.map((item) => (
              !item.isVideo ?

                <img src={`https://venoon.com/API${item?.url}`} alt="img" className="d-block w-100" style={{ height: "320px" }}  />

                :
                ''
            ))
            :
            <Carousel>
              {
                photo_video.map((item) => (
                  !item.isVideo ?
                    <Carousel.Item>
                      <img src={`https://venoon.com/API${item?.url}`} alt="img" className="d-block w-100" style={{ height: "320px" }} />
                    </Carousel.Item>
                    :
                    ''
                ))
              }
            </Carousel>
        }


      </Modal>

      <Modal className="infopop new_Pop" show={show4} onHide={() => setShow4(false)} aria-labelledby="contained-modal-title-vcenter" centered>
      <button type="button" onClick={() => setShow4(false)} className="close videoblockclose" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
       <div className="video-modal">
              <div className="modal-content">
                <video controls>
                  <source src={selectedVideoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {/*<button onClick={() => setShow4(false)}>Close</button>*/}
              </div>
            </div>

        {/*
          photo_video.length === 1 ?
            photo_video.map((item) => (
              item.isVideo ?
                <video controls style={{ width: "100%", height: "320px" }}>
                  <source src={`https://venoon.com/API${item?.url}`} type="video/mp4" />
                </video>
                : ""))
            :
            <Carousel>
              {
                photo_video.map((item) => (
                  item.isVideo ?
                    <Carousel.Item>
                      <video controls style={{ width: "100%", height: "320px" }} >
                        <source src={`https://venoon.com/API${item?.url}`} type="video/mp4" />
                      </video>
                    </Carousel.Item>
                    :
                    ''
                ))
              }
            </Carousel>
        */}
      </Modal>
    </div>}
    </React.Fragment>

  )
}

export default ChangeLocation