import React from 'react'
import "./Loader.css"
const Loader = () => {
  return (
    <div className='spin'>
    <div className="spinner-border" role="status">
<span className="visually-hidden"></span>
</div>
</div>
  )
}

export default Loader