import locationHttpsservices from "./locationHttpsservices"
import { config } from "../../Axios"
export const GetLocationProviderDashboard = () => {
  return locationHttpsservices.post(config.Dashboard, {})
}
export const LocationListForProvider = (search) => {
  return locationHttpsservices.post(config.LocationList + search, {})
}
export const CheckSubscription = () => {
  return locationHttpsservices.post(config.CheckSubscription, {})
}
export const DeleteLocation = (payload) => {
  return locationHttpsservices.post(config.DeleteLocation, payload)
}
export const CopyLocation = (payload) => {
  return locationHttpsservices.post(config.CopyLocation, payload)
}
export const ProblemMaidenByProvider = (payload) => {
  return locationHttpsservices.post(config.ProblemMaidenByProvider, payload)
}
export const LocationDetailsForProvider = (payload) => {
  return locationHttpsservices.post(config.LocationDetailsForProvider, payload)
}
export const SaveLocation = (payload) => {
  return locationHttpsservices.post(config.SaveLocation, payload)
}
export const SavePhotosAndVideos = (payload) => {
  return locationHttpsservices.formpost(config.SavePhotosAndVideos, payload)
}
export const SaveLocationPrice = (payload) => {
  return locationHttpsservices.post(config.SaveLocationPrice, payload)
}
export const OccasionList = () => {
  return locationHttpsservices.post(config.OccasionList, {})
}
export const SaveLocationServices = (payload) => {
  return locationHttpsservices.post(config.SaveLocationServices, payload)
}
export const DeletePhotosAndVideos = (payload) => {
  return locationHttpsservices.post(config.DeletePhotosAndVideos, payload)
}
export const ServicesList = (payload) => {
  return locationHttpsservices.post(config.ServicesList,payload)
}
export const FeaturesList = () => {
  return locationHttpsservices.post(config.FeaturesList, {})
}
export const SaveLocationFeatures = (payload) => {
  return locationHttpsservices.post(config.SaveLocationFeatures, payload)
}
export const SaveLocationCalender = (payload) => {
  return locationHttpsservices.post(config.SaveLocationCalender, payload)
}
export const GetLocation = (payload) => {
  return locationHttpsservices.post(config.GetLocation, payload)
}
export const GetPhotosAndVideos = (payload) => {
  return locationHttpsservices.post(config.GetPhotosAndVideos, payload)
}
export const GetLocationPrice = (payload) => {
  return locationHttpsservices.post(config.GetLocationPrice,payload)
}
export const GetLocationServices = (payload) => { 
  return locationHttpsservices.post(config.GetLocationServices,payload)
}
export const GetLocationFeatures = (payload) => {
  return locationHttpsservices.post(config.GetLocationFeatures,payload)
}
export const GetLocationDates = (payload) => {
  return locationHttpsservices.post(config.GetLocationDates, payload)
}
export const SkipLocationCalenderAvailability = (payload) => {
  return locationHttpsservices.post(config.SkipLocationCalenderAvailability, payload)
}

export const LocationListForAdmin=()=>{
  return locationHttpsservices.post(config.LocationListForAdmin,{})
}

export const GetReservationList=()=>{
  return locationHttpsservices.post(config.GetReservationList,{})
}
export const ActiveDeactiveLocation=(payload)=>{
  return locationHttpsservices.post(config.ActiveDeactiveLocation,payload)
}
export const BookLocation=(payload)=>{
  return locationHttpsservices.post(config.BookLocation,payload)
}
