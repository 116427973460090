import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './SubscriptionList.css';
import { Modal } from 'react-bootstrap';
import eye from '../../../Assests/dashboard/images/eye.svg';
import bin from '../../../Assests/dashboard/images/bin.svg';
import { NavLink } from 'react-router-dom';
import { GetUserSubscription, SubscriptionTypeDelete, UserSubscriptionSearch, UserSubscriptionSearchAdmin } from '../../../Services/Allservices/Admin/adminservices';
import Loader from '../../Utills/Loader';
import moment from 'moment';


const SubscriptionList = () => {
  const [show, setShow] = useState(false)
  const [loading, setloading] = useState(false)
  const [subscriptionlist, setSubscriptionlist] = useState([])
  const [deleteid, setDeleteid] = useState()
  const [invoiceid, setInvoiceid] = useState()
  const [userId, setuserId] = useState()
  const [FirstName, setFirstName] = useState()
  const [IsBusiness, setIsBusiness] = useState()
  const [LastName, setLastName] = useState()
  const [SubscriptionTypeId, setSubscriptionTypeId] = useState()
  const [ort, setort] = useState()
  const [streat, setstreat] = useState()
  const getsubscriptionList = async () => {
    setInvoiceid("")
    setuserId("")
    setIsBusiness(2)
    setFirstName("")
    setLastName("")
    setort("")
    setstreat("")
    setSubscriptionTypeId(0)
    setloading(true)
    try {
      const response = await GetUserSubscription()
      setSubscriptionlist(response?.data?.result)
      setloading(false)
      if (response?.data?.status) {

      }
      else {
        console.log(response?.data?.result?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  const SubscriptionTypedelete = async (id) => {
    const payload = {
      Id: id,
    }
    const response = await SubscriptionTypeDelete(payload)
    if (response?.data?.status) {
      getsubscriptionList()
      setShow(false)
    }
    else {
      console.log(response?.data?.message)

    }
  }
  const userSubscriptionSearch = async () => {
    if (SubscriptionTypeId === "4") {
      const payload = {
        "SubscriptionTypeId": 4
      }

      const response = await UserSubscriptionSearchAdmin(payload)
      if (response?.data?.status) {
        setSubscriptionlist(response?.data?.result?.reservationList)
      }
      else {
        console.log(response?.data?.message)

      }
    }
    else {
      const payload =
      {
        "InvoiceNo": invoiceid ? parseInt(invoiceid) : 0,
        "UserID": userId ? parseInt(userId) : 0,
        "IsBusiness": IsBusiness ? parseInt(IsBusiness) : 2,
        "FirstName": FirstName ? FirstName : "",
        "LastName": LastName ? LastName : "",
        "Road": streat ? streat : "",
        "Place": ort ? ort : "",
        "SubscriptionTypeId": SubscriptionTypeId ? parseInt(SubscriptionTypeId) : 0,
        // "Isverified":2

      }

      const response = await UserSubscriptionSearch(payload)
      if (response?.data?.status) {
        //  getsubscriptionList()
        setSubscriptionlist(response?.data?.result?.reservationList)
      }
      else {
        console.log(response?.data?.message)

      }
    }

  }


  useEffect(() => {
    getsubscriptionList()
  }, [])
  return (
    <React.Fragment>
      {
        loading ? <Loader /> : <>
          <div className="main_container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading_div">
                  <h3 className="main_heading">Abonnement verwalten</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="filter_div">
                  <h4>Filter</h4>
                  <form>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="UserID" name="userId" value={userId} onChange={(e) => setuserId(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select className="form-control" name="IsBusiness" value={IsBusiness} onChange={(e) => setIsBusiness(e.target.value)}>
                            <option disabled value="2" selected>UserTyp</option>
                            <option value="0">Privat</option>
                            <option value="1">Geschäftlich</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Vorname" name="FirstName" value={FirstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Nachname" name="LastName" value={LastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Ort" name="ort" value={ort} onChange={(e) => setort(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Straße" name="streat" value={streat} onChange={(e) => setstreat(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select className="form-control" name="SubscriptionTypeId" value={SubscriptionTypeId} onChange={(e) => setSubscriptionTypeId(e.target.value)}>
                            <option disabled value="0" selected>Abo</option>
                            <option value="2">Basis</option>
                            <option value="3">Premium</option>
                            <option value="4">Kein Abo</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="RG-Nr." name="invoiceid" value={invoiceid} onChange={(e) => setInvoiceid(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <button type="button" className="theme_btn" onClick={userSubscriptionSearch}>Suchen</button>
                    <button type="button" className="theme_btn" onClick={getsubscriptionList}>Filter löschen</button>
                  </form>
                </div>
                <div className="table-responsive new_table">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>RG-Nr.</th>
                        <th>UserID</th>
                        <th>UserTyp</th>
                        <th>Name<br />&amp; Adresse</th>
                        <th>Abo</th>
                        <th>Abo-Start</th>
                        <th>Abo-Ende</th>
                        <th>Betrag</th>
                        <th>Aktiv</th>
                        <th>Aktion</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        subscriptionlist && subscriptionlist.length > 0 ?
                          subscriptionlist.map((item) => (
                            <tr key={item?.invoiceNo}>
                              <td>
                                {item?.invoiceNo}
                                <div>{item?.isDeleted ? "storniert" : ""}</div>
                              </td>
                              <td>{item?.userID}</td>
                              <td>{item?.isBusiness ? "Geschäftlich" : "Privat"}</td>
                              <td><span>{item?.firstName}</span>  <span>{item?.lastName}</span><br />{item?.address}</td>
                              <td>{item?.subscriptionType ? item?.subscriptionType : "-"}</td>
                              <td>{item?.isDeleted ? "-" : moment(item?.subscriptionStart).format("DD.MM.YYYY")}</td>
                              <td>{item?.isDeleted ? "-" : moment(item?.subscriptionEnd).format("DD.MM.YYYY")}</td>
                              <td>{item?.isDeleted ? "-" : item?.amount}</td>
                              <td>
                                {
                                  item?.isActive ? <label className="custom_check">
                                    <input type="checkbox" checked={item?.isActive} />
                                    <span className="checkmark cancelledcheck"></span>
                                  </label> : ""
                                }
                              </td>
                              <td>
                                <NavLink to="/subscriptionview" state={{ id: item?.invoiceNo }}><img src={eye} alt="img" /></NavLink>
                                <button className='button_outline deletesubs' disabled={item?.isDeleted} onClick={() => { setDeleteid(item?.invoiceNo); setShow(true) }}><img src={bin} alt="img" /></button>
                              </td>

                            </tr>
                          ))
                          :
                          <div className="no_record"> Kein Eintrag gefunden!</div>
                      }
                    </tbody>
                  </table>
                </div>
              </div>

            </div>

            <Modal className="new_Pop" show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Body className="modalbg">
                <form>
                  <div className="text-center">
                    <p className="deletetext">Möchtest du dieses Abonnement wirklich löschen?</p>
                  </div>
                  <div className="text-center">
                    <NavLink className="theme_btn" onClick={() => SubscriptionTypedelete(deleteid)}>Ja</NavLink>
                    <NavLink className="theme_btn" onClick={() => setShow(false)}>Nein</NavLink>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default SubscriptionList
