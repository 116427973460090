import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './ProfileDetail.css';
import { NavLink, useLocation } from 'react-router-dom';
import { GetUserProfilesById } from '../../../Services/Allservices/Admin/adminservices';
import moment from 'moment';
import Loader from '../../Utills/Loader';

const ProfileDetail = function () {
  const { state } = useLocation()
  const [userData, setUserData] = useState([])
  const [loading, setloading] = useState(false)
  const getData = async () => {
    setloading(true)
    try {
      const response = await GetUserProfilesById(parseInt(state.id))
      if (response?.data?.status) {
        setUserData(response?.data?.result)
        setloading(false)
      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <React.Fragment>
      {
        loading ? <Loader /> : <>
          <div className="main_container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading_div">
                  <h3 className="main_heading">Benutzerdetails</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="subscriptview profileblock">
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                      <ul className="list_group text-right listheadsub">
                        <li>UserID :</li>
                        <li>UserTyp :</li>
                        <li>ProfileTyp:</li>
                        <li>Vorname :</li>
                        <li>Nachname :</li>
                        <li>Straße :</li>
                        <li>Firmenname :</li>
                        <li>Nr. :</li>
                        <li>PLZ :</li>
                        <li>Ort :</li>
                        <li>E-Mail :</li>
                        <li>Rufnummer :</li>
                        <li>Office Nummer :</li>
                        <li>Steuernummer :</li>
                        <li>UStID :</li>
                        <li>Letzte Anmeldung :</li>
                        <li>Verifiziert:</li>
                        <li>Aktiv :</li>
                        <li>Erstellt am :</li>
                      </ul>
                    </div>
                    <div className="col-6 col-sm-6 col-md-8 col-lg-9">
                      <ul className="list_group">

                        <li>{userData.id ? userData.id : "-"}</li>
                        <li>{userData.isBusiness ? <span>Geschäftlich</span> : <span>Privat</span>}</li>
                        <li>{userData.userRoleId === 2 ? " Location-Anbieter" : userData.userRoleId === 3 ? " Location-Suchende" : "Administrator"}</li>
                        <li>{userData?.firstName ? userData?.firstName : "-"}</li>
                        <li>{userData?.lastName ? userData?.lastName : "-"}</li>
                        <li>{userData?.road ? userData?.road : "-"}</li>
                        <li>{userData?.companyName ? userData?.companyName : "-"}</li>
                        <li>{userData?.num ? userData?.num : "-"}</li>
                        <li>{userData?.postCode ? userData?.postCode : "-"}</li>
                        <li>{userData?.place ? userData?.place : "-"}</li>
                        <li>{userData?.userName ? userData?.userName : "-"}</li>
                        <li>{userData?.mobileNumber ? userData?.mobileNumber : "-"}</li>
                        <li>{userData?.mobileNumber2 ? userData?.mobileNumber2 : "-"}</li>
                        <li>{userData?.taxNumber ? userData?.taxNumber : "-"}</li>
                        <li>{userData?.vatId ? userData?.vatId : "-"}</li>
                        <li>{userData?.login ? <span>{moment(userData?.login).format("DD.MM.YYYY")} {moment(userData?.login).add(1,'hours').format("HH:mm:ss")}</span> : "-"}</li>
                        <li>{userData?.isVarified ? "Ja" : "Nein"}</li>
                        <li>{userData?.isActive ? "Ja" : "Nein"}</li>
                        <li>{userData?.createdDate ? <span >{moment(userData?.createdDate).format("DD.MM.YYYY")} {moment(userData?.createdDate).add(1,'hours').format("HH:mm:ss")}</span> : "-"}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <NavLink to="/profilelist">
                  <button className="theme_btn">Zurück</button>
                </NavLink>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </>
      }

    </React.Fragment>
  )
}

export default ProfileDetail
