import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routing from './Routing '
const AllRoutes = () => {
  return (
    <div>
<Router>
    <Routing/>
</Router>
    </div>
  )
}

export default AllRoutes