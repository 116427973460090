import React from 'react'
import { NavLink } from 'react-router-dom'
import facebook from "../../../Images/facebook.svg"
import instagram from "../../../Images/instagram.svg"
import youtube from "../../../Images/youtube.svg"
const Footer = () => {
    return (
        <div>
            <footer className="botSpace">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <ul className="social_links">
                                <li><a href="https://www.facebook.com/profile.php?id=61552637648555&mibextid=LQQJ4d"  target='_blank'><img src={facebook} alt="facebook" /></a></li>               
                                <li><a href="https://instagram.com/_venoon_?igshid=MzRlODBiNWFlZA=="  target='_blank'><img src={instagram} alt="instagram" /></a></li>               
                                <li><a href="https://youtube.com/@VENOON?feature=shared"  target='_blank'><img src={youtube} alt="youtube" /></a> </li>
                            </ul>
                            <a href="/kontakt">Kontakt</a>
                            <span className="line">&nbsp;|</span> <a href="/impressum">Impressum</a>
                            <span className="line">&nbsp;|</span> <a href="/agb">AGB</a>
                            <span className="line">&nbsp;|</span> <a href="/datenschutz">Datenschutz</a>
                            {/* <span className="line">&nbsp;|</span> <NavLink to="/account-delete-request">Delete Account Request</NavLink> */}
                            <p>Copyright © 2024 VENOON</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer