import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Sidenav from '../Component/Layout/SideNav/SideNav'
import AdminDashboard from '../Component/Admin/Dashboard/Dashboard'
import AnfragenandIncidents from '../Component/Admin/AnfragenandIncidents/AnfragenandIncidents'
import ReplyMessage from '../Component/Admin/ReplyMessage/ReplyMessage'
import Mitteilung from '../Component/Admin/Mitteilung/Mitteilung'
import SubscriptionList from '../Component/Admin/SubscriptionList/SubscriptionList'
import SubscriptionView from '../Component/Admin/SubscriptionView/SubscriptionView'
import ManageInvoice from '../Component/Admin/ManageInvoice/ManageInvoice'
import ManageInvoiceDetail from '../Component/Admin/ManageInvoiceDetail/ManageInvoiceDetail'
import InvoiceUpload from '../Component/Admin/InvoiceUpload/InvoiceUpload'
import ProfileList from '../Component/Admin/ProfileList/ProfileList'
import ProfileDetail from '../Component/Admin/ProfileDetail/ProfileDetail'
import LocationList from '../Component/Admin/LocationList/LocationList'
import Reservierungen from '../Component/Admin/Reservierungen/Reservierungen'

import Home from '../Component/Home/Home'
import LocationDashboard from '../Component/LocationProvider/Dashboard/Dashboard'
import MyLocationList from '../Component/LocationProvider/MyLocationList/MyLocationList'
import {getItemCookies, getJsonObjCookies} from '../Component/Utills/Utills'
import AllLocation from '../Component/LocationProvider/CreateForm/AllLocation'
import ReportProblem from '../Component/LocationProvider/ReportProblem/ReportProblem'
import ChangeLocation from '../Component/LocationProvider/ChangeLocation/ChangeLocation'
import CreateLocation from '../Component/LocationProvider/CreateLocation/CreateLocation'
import Changepassword from '../Component/Changepassword/ChangePassword'
import Profile from '../Component/LocationProvider/Profile/Profile'
import Login from '../Component/Login/Login'
import NotFound from '../Component/NotFound/NotFound'
const AllRouting = () => {
  return (
    <div>
      {
        getItemCookies("auth_token") ?
          <div>
            <Sidenav />
            <Routes>
           <Route exact path="/*" element={<NotFound/>}/>
              <Route exact path="/admindashboard" element={<AdminDashboard />} />
              <Route exact path="/anfragenandincidents" element={<AnfragenandIncidents/>}/>
              <Route exact path="/replymessage" element={<ReplyMessage/>}/>
              <Route exact path='/mitteilung' element={<Mitteilung/>}/>
              <Route exact path='/subscriptionlist' element={<SubscriptionList/>}/>
              <Route exact path='/subscriptionview' element={<SubscriptionView/>}/>
              <Route exact path='/invoicelist' element={<ManageInvoice/>}/>
              <Route exact path='/invoicedetail' element={<ManageInvoiceDetail/>}/>
              <Route exact path='/invoiceupload' element={<InvoiceUpload/>}/>
              <Route exact path='/profilelist' element={<ProfileList/>}/>
              <Route exact path='/profiledetail' element={<ProfileDetail/>}/>
              <Route exact path='/locationlist' element={<LocationList/>}/>
              <Route exact path='/reservierungen' element={<Reservierungen/>}/>
           
              <Route exact path="/dashboard" element={<LocationDashboard />} />
              <Route exact path="/mylocationlist" element={<MyLocationList />} />
              <Route exact path="/alllocation" element={<AllLocation />} />
              <Route exact path="/reportproblem" element={<ReportProblem/>}/>
              <Route exact path="/changeLocation" element={<ChangeLocation/>}/>
              <Route exact path="/createlocation" element={<CreateLocation/>}/>
              <Route exact path="/changepassword" element={<Changepassword/>}/>
              <Route exact path="/editprofile" element={<Profile/>}/>
            </Routes>
          </div>
          :
           <NotFound />



      }

    </div>
  )
}

export default AllRouting