import React,{useState} from 'react'
import { Button } from 'react-bootstrap'
import Login from '../../Login/Login'
import logoImg from "../../../Images/logo.svg"
import Footer from '../Footer/Footer'
import { MailToAdmin } from '../../../Services/Allservices/Admin/adminservices'
import { msg } from '../../../Services/Axios'
import { AiFillCheckCircle } from "react-icons/ai";
const Accountdeleterequest = () => {
  const[email,setEmail]=useState("")
  const[reason,setReason]=useState("")
  const [errors, setErros] = useState({});
  const[show,setShow]=useState("")
  const handleClick=async()=>{
    if (!validateForm()) {
        return
    }
   try {
        const payload = {
    UserName:email,
    message:reason
        }
  
        const response = await MailToAdmin(payload)
if (response?.status) {
            setShow(response?.data?.result?.transactionMessage?.message)
            setTimeout(() => {
                setShow("")
            },5000);
        }
  
      }
      catch (err) {
        console.log(err)
      }
  }
 
  const validateForm = () => {
    var emailValid = email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    var isValid = true;

    if (email.trim() === "") {
        errors.email = msg.emailkonkat
        isValid = false;
    }
    else if (!emailValid) {
        errors.email = msg.correctformat;
        isValid = false;

    }
    else {
        errors.email = "";
    }

    if (reason.trim() === "") {
        errors.reason = msg.solution;
        isValid = false;

    }
else {
        errors.reason = ""
    }
   
    setErros({ ...errors, errors });
    return isValid;
}
  return (
    <div> 
      <section className="banner_sec innerPage">
    <div className="container">
        <div className="row">
        <div className="col-sm-12">
           <div className="logo">
           <img src={logoImg} alt="logo"/>
           </div> 
            <Login />
        </div>    
        </div>
    </div>
</section>

      <section className="about_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 ">
              <div className="">
              <div className="heading_div">
                        <h3 className="main_heading">Benutzerkonto löschen</h3>
                    </div>
                    <div className="deleteaccount">
                        <p>Geben Sie lhre E-Mail Adresse und den Grund für die Kontolöschung ein:</p>
                        <form>
                        {/* <div className="col-sm-12 col-md-12 col-lg-6"> */}
                                        <div className="form-group">
                                            <label htmlFor="Name">E-Mail*</label>
                                            <input type="email"  className="form-control" placeholder="E-Mail-Adresse" name="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                                            {!!errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
                                        </div>
{/*                                         
                                    </div> */}
                            <div className="form-group">
                            <label htmlFor="Name">Nachricht*</label>
                                <textarea  className="form-control text_msg" placeholder="Grund fur die Kontolöschung" name="reason" value={reason} onChange={(e)=>setReason(e.target.value)}></textarea>
                                {!!errors.reason && <div style={{ color: 'red' }}>{errors.reason}</div>}
                            </div>
                            <Button  className="theme_btn" onClick={handleClick}>Senden</Button>
                           

                        </form>
                    </div>
                    <div style={{ paddingTop: "20px" }}><p className="msg_show"> {show ? <span><AiFillCheckCircle className='icon_color' style={{ fontSize: "30px" }} /> {show}</span> : ""}</p></div>
              </div>
            </div>
          </div>
        </div>
      </section>

    
    

    <Footer/>
        </div>
  )
}

export default Accountdeleterequest