import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { NavLink } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import "./Mitteilung.css"
import blank_img from '../../../Assests/dashboard/images/blank_img.svg';
import edit from '../../../Assests/dashboard/images/edit.svg';
import add from '../../../Assests/dashboard/images/add.svg';
import bin2 from '../../../Assests/dashboard/images/bin2.svg';
import 'react-nice-dates/build/style.css'
import { GetAllAnnouncement, AnnoucmentByAdmin, DeleteAndUpdateAnnouncement } from '../../../Services/Allservices/Admin/adminservices';
import moment from 'moment';
import Loader from '../../Utills/Loader';
const Mitteilung = () => {
  const [show, setshow] = useState(false)
  const [show1, setshow1] = useState(false)
  const [show2, setshow2] = useState(false)
  const [announcement, setannouncement] = useState([])
  const [announce, setannounce] = useState("")
  const [Deleteid, setDeleteId] = useState()
  const [announcevalue, setannouncevalue] = useState("")
  const [loading, setloading] = useState(false)
  const [editId, seteditid] = useState("")

  const getAllAnnouncement = async () => {
    setloading(true)
    try {
      const response = await GetAllAnnouncement()

      setannouncement(response?.data?.result)
      if (response?.data?.status) {
        setloading(false)
      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const annoucmentByAdmin = async () => {
    setloading(true)
    try {
      const payload = {
        Id: show1 ? editId : 0,
        Announcement: announce
      }
      const response = await AnnoucmentByAdmin(payload)

      if (response?.status) {

        if (editId) {
          setshow1(false)
          setloading(false)
        }
        if (show) {
          setshow(false)
          setloading(false)
        }
        getAllAnnouncement()
      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  const Deleteannouncement = async (id, value) => {
    try {
      const payload = {

        Id: id,
        Announcement: value,
        IsDeleted: true

      }
      const response = await DeleteAndUpdateAnnouncement(payload)

      if (response?.status) {
        getAllAnnouncement()

      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const getSemester = (date) => {
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
     // Check for specific date conditions
    if (month === 2 && day === 31) { // March 31
        return 2;
    } else if (month === 8 && day === 30) { // September 30
        return 1;
    } 
    
    let iSfirstSem = [3,4,5,6,7,8].includes(month);
    let iSsecondSem = [0,1,2,9,10,11].includes(month); 
    return iSfirstSem ? 2:1;    
    }


  const Editannouncement = async (id, value) => {
    try {
      const payload = {
        Id: id,
        Announcement: value,
        IsDeleted: false
      }
      const response = await DeleteAndUpdateAnnouncement(payload)
      setannounce(value)
      if (response?.status) {
        seteditid(id)

      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getAllAnnouncement()
  }, [])

  return (
    <React.Fragment>
      {
        loading ?
          <Loader />
          :
          <div className="main_container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading_div pb-4">
                  <h3 className="main_heading">Mitteilung </h3>
                  <NavLink className="add_feed" onClick={() => { setshow(true); setannounce("") }}><img src={add} alt="img" /></NavLink>

                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="feedback_msg">
                  <div className="support_div">
                    <h4>Mitteilungen / Updates</h4>
                    <ul className="message_list">
                      {
                        announcement.map((item) => (
                          <li key={item?.id}>
                            <div className="for_message">
                              <div className="img_round"><img src={blank_img} alt="img" /></div>
                              <div className="text_message">
                                <h6>{item?.announcement}  </h6>
                                <p>Datum: {moment(item?.announcementCreatedDate).format('DD.MM.YYYY')}  Uhrzeit:{moment(item?.announcementCreatedDate).add(getSemester(item?.announcementCreatedDate),'hours').format('HH:mm:ss')}</p>
{/*<p>Datum: {moment(item?.announcementCreatedDate).format('DD.MM.YYYY')}  Uhrzeit: {moment(item?.announcementCreatedDate).add(item?.announcementCreatedDate.getMonth() >= 3 && item?.announcementCreatedDate.getMonth() <= 9 ? 1 : 2, 'hours').format('HH:mm:ss')}</p>*/}
                              </div>
                            </div>
                            <div className="d-flex ml-2">
                              <NavLink className="mr-3" onClick={() => { Editannouncement(item?.id, item?.announcement); setshow1(true) }}><img src={edit} alt="img" /></NavLink>
                              <NavLink onClick={() => { setDeleteId(item?.id); setannouncevalue(item?.announcement); setshow2(true) }}><img src={bin2} alt="img" /></NavLink>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <Modal className="new_Pop" show={show} onHide={() => setshow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header>
                <Modal.Title>Mitteilung schreiben</Modal.Title>
                <button type="button" onClick={() => setshow(false)} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body className="modalbg">
                <form>
                  <div className="form-group">
                    <textarea className="form-control messageText" placeholder="Bitte eine Nachricht schreiben" name="announce" value={announce} onChange={(e) => setannounce(e.target.value)}></textarea>
                  </div>
                  <div className="text-center">
                    <NavLink className="theme_btn" onClick={() => { annoucmentByAdmin() }}>Speichern</NavLink>
                  </div>
                </form>
              </Modal.Body>
            </Modal>

            <Modal className="new_Pop" show={show2} onHide={() => setshow2(false)} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Body className="p-4 modalbg">
                <p className="modalPara">Möchtest du diese Nachricht wirklich löschen?</p>
                <div className="text-center">
                  <NavLink className="theme_btn" onClick={() => { Deleteannouncement(Deleteid, announcevalue); setshow2(false) }}>Ja</NavLink>
                  <NavLink className="theme_btn" onClick={() => setshow2(false)}>Nein</NavLink>
                </div>
              </Modal.Body>
            </Modal>

            <Modal className="new_Pop" show={show1} onHide={() => setshow1(false)} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header>
                <Modal.Title>Mitteilung schreiben</Modal.Title>
                <button type="button" onClick={() => setshow1(false)} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body className="modalbg">
                <form>
                  <div className="form-group">
                    <textarea name="announce" value={announce} onChange={(e) => setannounce(e.target.value)} className="form-control messageText" ></textarea>
                  </div>
                  <div className="text-center">
                    <NavLink className="theme_btn" onClick={annoucmentByAdmin} >Speichern</NavLink>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
      }
    </React.Fragment>
  )
}

export default Mitteilung




