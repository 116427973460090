import accountHttpsservices from "./accountHttpsservices"
import { config } from "../Axios"
export const UserLogin = (payload) => {
    return accountHttpsservices.post(config.Login, payload)
}

export const Logout=(payload)=>{
    return accountHttpsservices.post(config.Logout, payload)
}
export const UserRegistration = (payload) => {
    return accountHttpsservices.post(config.UserRegistration, payload)
}
export const UserProfilePicture = (payload) => {
    return accountHttpsservices.post(config.UserProfilePicture, payload)
}
export const ChangePassword = (payload) => {
    return accountHttpsservices.post(config.ChangePassword, payload)
}

export const GetUser = (payload) => {
    return accountHttpsservices.post(config.GetUser, payload)
}
export const DeleteProfilePicture = (payload) => {
    return accountHttpsservices.post(config.DeleteProfilePicture, payload)
}
export const SendAppLink = (payload,payload1) => {
    return accountHttpsservices.post(config.SendAppLink+`?email=${payload}&IsAndroid=${payload1}`)
}
export const SendContactUsEmail=(payload)=>{
    return accountHttpsservices.post(config.SendContactUsEmail, payload)
}
export const DeleteUser=(payload)=>{
    return accountHttpsservices.post(config.DeleteUser, payload)
}
export const ActiveDeactiveUser=(payload)=>{
    return accountHttpsservices.post(config.ActiveDeactiveUser, payload)
}