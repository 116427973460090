import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './Dashboard.css'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import calendar from "../../../Assests/dashboard/images/calendar.svg"
import user_icon from "../../../Assests/dashboard/images/user_icon.svg"
import businessman from "../../../Assests/dashboard/images/businessman.svg"
import customer from "../../../Assests/dashboard/images/customer.svg"
import incidents from "../../../Assests/dashboard/images/incidents.svg"
import count_icon01 from "../../../Assests/dashboard/images/count_icon01.svg"
import count_icon02 from "../../../Assests/dashboard/images/count_icon02.svg"
import count_icon03 from "../../../Assests/dashboard/images/count_icon03.svg"
import count_icon04 from "../../../Assests/dashboard/images/count_icon04.svg"
import count_icon07 from "../../../Assests/dashboard/images/count_icon07.svg"
import { GetAdminDashboard, GetAdminDashboard1 } from '../../../Services/Allservices/Admin/adminservices';
import Loader from '../../Utills/Loader';
import { DateRangePicker } from 'react-nice-dates'
import { de } from 'date-fns/locale'
import moment from 'moment/moment';
const AdminDashboard = () => {
    const [user, setUser] = useState({})
    const [loading, setloader] = useState(false)

    const [datefrom, setDatefrom] = useState()
    const [dateto, setDateto] = useState()
    const [datefromvalue, setdatefromvalue] = useState()
    const [datetovalue, setdatetovalue] = useState()
    const handleSubmit = async () => {
        setdatefromvalue("")
        setdatetovalue("")
        setloader(true)
        const response = await GetAdminDashboard();
        try {

            if (response?.data?.result?.transactionMessage?.status === 200) {
                setUser(response?.data?.result)
                setDatefrom("")
                setDateto("")
                setloader(false)
            }
            else {
                console.log(response?.data?.result?.transactionMessage?.message)

            }
        }
        catch (err) {

        }
    }
    const handleSubmit1 = async () => {
        const payload = {
            DateFrom: moment(datefrom).format("YYYY-MM-DD"),
            DateTo: moment(dateto).format("YYYY-MM-DD")
        }
        const response = await GetAdminDashboard1(payload);
        try {

            if (response?.data?.result?.transactionMessage?.status === 200) {
                setUser(response?.data?.result)
                setdatefromvalue(datefrom)
                setdatetovalue(dateto)
            }
            else {
                console.log(response?.data?.result?.transactionMessage?.message)

            }
        }
        catch (err) {

        }
    }


    useEffect(() => {
        handleSubmit()
    }, [])
    const [isActive, setIsActive] = useState(true)
    const hideShowDiv = async () => {
        setIsActive(!isActive)
    }

    return (
        <React.Fragment>
            {
                loading ?
                    <Loader />
                    :

                    <div className="main_container ">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading_div">
                                    <h3 className="main_heading">Startseite <span className='rangedate'>{datefromvalue && datetovalue ? ('(' + moment(datefromvalue).format("DD.MM.YYYY")) + '-' + moment(datetovalue).format("DD.MM.YYYY") + ')' : ""}</span></h3>
                                    <div className="btn_group d-flex align-items-center">
                                        <NavLink className="head_btn_left" onClick={handleSubmit}>Filter löschen</NavLink>
                                        <OverlayTrigger
                                            placement="bottom"
                                            trigger="click" show={!isActive}
                                            overlay={(
                                                <Popover className="dateBox">
                                                    <div className="row justify-content-around dashboardblock new_Pop1">
                                                        <button type="button" className="close" onClick={hideShowDiv} trigger="click">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                        <DateRangePicker
                                                            startDate={datefrom}
                                                            endDate={dateto}
                                                            onStartDateChange={setDatefrom}
                                                            onEndDateChange={setDateto}
                                                            format='dd MMM yyyy'
                                                            locale={de}
                                                        >
                                                            {({ startDateInputProps, endDateInputProps, focus }) => (

                                                                <div className='date-range calblock'>
                                                                    <input
                                                                        className={'input form-control mb-3 dateicon' + (focus === datefrom ? ' -focused' : '')}
                                                                        {...startDateInputProps}
                                                                        placeholder="Von"

                                                                    />
                                                                    <span className='date-range_arrow' />
                                                                    <input
                                                                        className={'input form-control mb-3 dateicon' + (focus === dateto ? ' -focused' : '')}
                                                                        {...endDateInputProps}
                                                                        placeholder="Bis"
                                                                    />
                                                                </div>
                                                            )}
                                                        </DateRangePicker>

                                                    </div>
                                                    <div className="text-center mt-3">
                                                        <button disabled={(datefrom && dateto) === ""} onClick={handleSubmit1} className="theme_btn rounded-0">Speichern</button>
                                                    </div>
                                                </Popover>
                                            )}>

                                            <NavLink className="theme_btn" onClick={hideShowDiv}><img src={calendar} alt="img" /> Kalender</NavLink>

                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                                    <div className="card_box">
                                        <div className="t_count">

                                            <h4>{user.totalUser}</h4>
                                            <h5>User</h5>
                                        </div>
                                        <div>
                                            <img src={user_icon} alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                                    <div className="card_box card_box2">
                                        <div className="t_count">

                                            <h4>{user.locationProvider}</h4>
                                            <h5>Location Provider</h5>
                                        </div>
                                        <div>
                                            <img src={businessman} alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                                    <div className="card_box card_box3">
                                        <div className="t_count">

                                            <h4>{user.locationSeekar}</h4>
                                            <h5>Location Suchende</h5>
                                        </div>
                                        <div>
                                            <img src={customer} alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                                    <div className="card_box card_box4">
                                        <div className="t_count">

                                            <h4>{user.incidents}</h4>
                                            <h5>Incidents</h5>
                                        </div>
                                        <div>
                                            <img src={incidents} alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="countDetails">
                                    <div className="count_header">
                                        <h4>User</h4>
                                        <img src={count_icon01} alt="img" />
                                    </div>
                                    <div className="count_body">
                                        <div className="box_center">
                                            <h5>Aktiver User</h5>

                                            <h6>{user.activeUser}</h6>
                                        </div>
                                        <div className="box_center">
                                            <h5>Nicht aktiver User</h5>

                                            <h6>{user.deactiveUser}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="countDetails">
                                    <div className="count_header">
                                        <h4>Location</h4>
                                        <img src={count_icon02} alt="img" />
                                    </div>
                                    <div className="count_body">
                                        <div className="box_center">
                                            <h5>Anzahl</h5>

                                            <h6>{user.totalLocation}</h6>
                                        </div>
                                        <div className="box_center">
                                            <h5>Gelöscht</h5>
                                            <h6>{user.deletedLocation}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="countDetails">
                                    <div className="count_header">
                                        <h4>Reservierung</h4>
                                        <img src={count_icon03} alt="img" />
                                    </div>
                                    <div className="count_body">
                                        <div className="box_center">
                                            <h5>Anzahl</h5>

                                            <h6>{user.totalReservation}</h6>
                                        </div>
                                        <div className="box_center">
                                            <h5>Reservierung storniert</h5>
                                            <h6>{user.cancelledReservation}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="countDetails">
                                    <div className="count_header">
                                        <h4>Buchung</h4>
                                        <img src={count_icon04} alt="img" />
                                    </div>
                                    <div className="count_body">
                                        <div className="box_center">
                                            <h5>Anzahl</h5>

                                            <h6>{user.totalBooking}</h6>
                                        </div>
                                        <div className="box_center">
                                            <h5>Buchung storniert</h5>

                                            <h6>{user.bookingCancelled}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="countDetails">
                                    <div className="count_header">
                                        <h4>Abonnement</h4>
                                        <img src={count_icon07} alt="img" />
                                    </div>
                                    <div className="count_body">
                                        <div className="box_center">
                                            <h5>Free</h5>

                                            <h6>{user.freeSubscription}</h6>
                                        </div>
                                        <div className="box_center">
                                            <h5>Basis</h5>

                                            <h6>{user.basicSubscription}</h6>
                                        </div>
                                        <div className="box_center">
                                            <h5>Premium</h5>

                                            <h6>{user.premiumSubscription}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="countDetails">
                                    <div className="count_header">
                                        <h4>Abo-Umsatz (in Euro)</h4>
                                        <img src={count_icon07} alt="img" />
                                    </div>
                                    <div className="count_body">
                                        <div className="box_center">
                                            <h5>Basis</h5>
                                            <h6>{user?.basicSubscriptionCalculation}</h6>
                                        </div>
                                        <div className="box_center">
                                            <h5>Premium</h5>
                                            <h6>{user?.premiumSubscriptionCalculation}</h6>
                                        </div>
                                        <div className="box_center">
                                            <h5>Total</h5>
                                            <h6>{user?.totalCalculation}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </React.Fragment>
    )
}

export default AdminDashboard