import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, Col, CardBody, Button } from 'reactstrap'
import { GetLocation, SaveLocation } from '../../../Services/Allservices/LocationProvider/locationservices'
import { msg } from '../../../Services/Axios'

import "./CreateForm.css"

const DeineLocation = (props) => {
  const [title, settitle] = useState("")
  const [lDescription, setlDescription] = useState("")
  const [road, setroad] = useState("")
  const [num, setnum] = useState("")
  const [postcode, setpostcode] = useState("")
  const [place, setplace] = useState("")
  const [errors, setErros] = useState({});
  const { state } = useLocation();
  const { isEdit, purpose, editId } = state || {}
  const [show, setShow] = useState("")
  useEffect(() => {
    getData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    if (isEdit === 'edit' || isEdit === 'copy') {
      const response = await GetLocation({ id: editId })
      if (response.data.status) {
        let tempData = response.data.result;

        if (purpose === 'edit') {

          settitle(tempData.title)
          setlDescription(tempData.lDescription)
          setroad(tempData.road)
          setnum(tempData.num)
          setpostcode(tempData.postcode)
          setplace(tempData.place)

        }
        if (purpose === 'copy') {

          tempData['Id'] = editId
          tempData['title'] = tempData.title + " Kopie"
          const responseTwo = await SaveLocation(tempData)

          if (responseTwo.data.status) {

            tempData['title'] = ""

            const mData = responseTwo?.data?.result;
            setlDescription(mData[`lDescription`])
            setroad(mData[`road`])
            setnum(mData[`num`])
            setpostcode(mData[`postcode`])
            setplace(mData[`place`])
          }
        }


      }

    }

  }

  const handleSubmit = async (e) => {

    e.preventDefault()
    if (!validateForm()) {
      return
    }
    try {
      const payload = {
        Id: editId,
        title: title,
        postcode: postcode,
        lDescription: lDescription,
        road: road,
        num: num,
        place: place
      }

      const response = await SaveLocation(payload)

      if (response?.data?.status) {
        props.onSelect('second')
        props.setLocationId(response?.data?.result.id)
      }
      else {
        setShow(response?.data?.result?.transactionMessage?.message)
        setTimeout(() => {
          setShow("")
        }, 5000);

      }
    }
    catch (err) {
      console.log(err)
    }
  }


  const validateForm = () => {
    var isValid = true;
    var postcodeValid = postcode.match(/^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/,)
    var streetnumber = num.match(/[a-zA-ZäöüÄÖÜß0-9 ]$/,)
    var placetext = place.match(/[a-zA-ZäöüÄÖÜß]$/,)

    if (title.trim() === "") {
      errors.title = msg.Titel
      isValid = false;
    }
    else {
      errors.title = "";
    }
    if (road.trim() === "") {
      errors.road = msg.Straße
      isValid = false;
    }
    else {
      errors.road = "";
    }
    if (num.trim() === "") {
      errors.num = msg.Nr
      isValid = false;
    }
    else if (!(streetnumber)) {
      errors.num = msg.streetnumber;
      isValid = false;
    }
    else {
      errors.num = "";
    }
    if (postcode.trim() === "") {
      errors.postcode = msg.PLZ
      isValid = false;
    }
    else if (isNaN(postcode)) {
      errors.postcode = msg.PLZNumber
      isValid = false;
    }
    else if (!(postcodeValid)) {
      errors.postcode = msg.postcodepatterncheck;
      isValid = false;
    }
    else {
      errors.postcode = "";
    }
    if (place.trim() === "") {
      errors.place = msg.Ort
      isValid = false;
    }
    else if (!(placetext)) {
      errors.place = msg.placepatterncheck;
      isValid = false;
    }
    else {
      errors.place = "";
    }
    setErros({ ...errors, errors });
    return isValid;
  }

  return (
    <React.Fragment>
      <div className="form_outer">
        <div className="row">
          <Col sm="12">
            <Card>
              <h5>Deine Location</h5>
              <CardBody>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input type="text" name="title" value={title} onChange={(e) => settitle(e.target.value)} className="form-control" placeholder="Titel*" />
                    </div>
                    {!!errors.title && <div style={{ color: 'red' }}>{errors.title}</div>}
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input type="text" className="form-control" name="lDescription" value={lDescription} onChange={(e) => setlDescription(e.target.value)} placeholder="Beschreibung" />

                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <input type="text" name="road" value={road} onChange={(e) => setroad(e.target.value)} className="form-control" placeholder="Straße*" />
                      {!!errors.road && <div style={{ color: 'red' }}>{errors.road}</div>}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <input type="text" name="num" value={num} onChange={(e) => setnum(e.target.value)} className="form-control" placeholder="Nr.*" />
                      {!!errors.num && <div style={{ color: 'red' }}>{errors.num}</div>}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <input type="text" name="postcode" value={postcode} onChange={(e) => setpostcode(e.target.value)} className="form-control" placeholder="PLZ*" />
                      {!!errors.postcode && <div style={{ color: 'red' }}>{errors.postcode}</div>}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <input type="text" name="place" value={place} onChange={(e) => setplace(e.target.value)} className="form-control" placeholder="Ort*" />
                      {!!errors.place && <div style={{ color: 'red' }}>{errors.place}</div>}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
      <div className='msg_forerr'>{show ? show : ""}</div>
      <div className="nextPrevBtn">
        <Button onClick={handleSubmit} className="theme_btn" >
          Weiter
        </Button>

      </div>
    </React.Fragment>
  )
}

export default DeineLocation