import React, { useEffect, useState } from 'react'
import "./Profile.css"
import { Button } from 'react-bootstrap'
import { Formik } from 'formik';
import plus from "../../../Assests/dashboard/images/plus.svg"
import closeIcon from "../../../Assests/dashboard/images/closeIcon.svg"
import * as yup from 'yup'
import {getItemCookies,setItemCookies } from '../../Utills/Utills';
import { DeleteProfilePicture, GetUser, UserProfilePicture, UserRegistration } from '../../../Services/LoginAccount/accountservices';
import { NavLink } from 'react-router-dom';
import { AiFillCheckCircle } from "react-icons/ai";

const TextInputComponent =
  ({ value, onFocus, errors, onChangeText, onBlur, touched,
    autoFocus,editable= true, placeholder }, props) => {
    return (
      <>
        <input
          type="text"
          value={value}
          onBlur={onBlur}
          autoCapitalize={false}
          onChange={value => { onChangeText(value) }}
          autoFocus={autoFocus}
          placeholder={placeholder}
          autoCorrect={false}
          spellCheck={false}
          onFocus={onFocus}
          disabled={!editable}
          className="form-control"
          {...props}
        />
        {(errors && touched) && <NavLink style={{ color: 'red' }}>{errors}</NavLink>}
      </>
    )
  }

const pattern = {
  street_regex: /[a-zA-ZäöüÄÖÜß0-9 ]$/,
  postcode_regex: /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/,
  name_regex: /[a-zA-ZäöüÄÖÜß ]$/,
  mobile_regex: /^([\+][0-9]{1,3}[ \.\-])?([\(]{1}[0-9]{1,6}[\)])?([0-9 \.\-\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/,
}

const messages = {
  NAME_FORMAT: 'In diesem Feld sind nur Buchstaben erlaubt',
  STREET_FORMAT: 'Das erste Zeichen muss mit einem Buchstaben beginnen und es sind keine Sonderzeichen erlaubt',
  POSTCODE_FORMAT: 'PLZ sollte gültig sein',
  COMPANYNAME_REQUIRED: 'Firmenname ist erforderlich',
  FIRSTNAME_REQUIRED: 'Vorname ist erforderlich',
  LASTNAME_REQUIRED: 'Nachname ist erforderlich',
  MOBILE_REQUIRED: 'Rufnummer ist erforderlich',
  MOBILE_FORMAT: 'Rufnummer sollte gültig sein',
  EMAIL_REQUIRED: 'Es muss eine gültige E-Mail sein',
  STREET_REQUIRED: 'Straße ist erforderlich',
  NUMBER_REQUIRED: 'StraßenNr. ist erforderlich',
  POSTCODE_REQUIRED: 'PLZ ist erforderlich',
  PLACE_REQUIRED: 'Ort ist erforderlich',
}

const validationSchema = yup.object().shape({
  companyName: yup
    .string()
    .required(messages.COMPANYNAME_REQUIRED),
  firstName: yup
    .string()
    .matches(pattern.name_regex, messages.NAME_FORMAT)
    .required(messages.FIRSTNAME_REQUIRED),
  lastName: yup
    .string()
    .matches(pattern.name_regex, messages.NAME_FORMAT)
    .required(messages.LASTNAME_REQUIRED),
  userName: yup
    .string()
    .required(messages.EMAIL_REQUIRED)
    .email(messages.EMAIL_REQUIRED),
  mobileNumber: yup
    .string()
    .required(messages.MOBILE_REQUIRED),
  
  road: yup
    .string()
    .matches(pattern.street_regex, messages.STREET_FORMAT)
    .required(messages.STREET_REQUIRED),
  num: yup
    .string()
    .matches(pattern.street_regex, messages.STREET_FORMAT)
    .required(messages.NUMBER_REQUIRED),
  postCode: yup
    .string()
    .matches(pattern.postcode_regex, messages.POSTCODE_FORMAT)
    .required(messages.POSTCODE_REQUIRED),
  place: yup
    .string()
    .required(messages.PLACE_REQUIRED),
});

const validationSchemaTwo = yup.object().shape({
  firstName: yup
    .string()
    .matches(pattern.name_regex, messages.NAME_FORMAT)
    .required(messages.FIRSTNAME_REQUIRED),
  lastName: yup
    .string()
    .matches(pattern.name_regex, messages.NAME_FORMAT)
    .required(messages.LASTNAME_REQUIRED),
  userName: yup
    .string()
    .required(messages.EMAIL_REQUIRED)
    .email(messages.EMAIL_REQUIRED),
  mobileNumber: yup
    .string()
    .required(messages.MOBILE_REQUIRED),

  road: yup
    .string()
    .matches(pattern.street_regex, messages.STREET_FORMAT)
    .required(messages.STREET_REQUIRED),
  num: yup
    .string()
    .matches(pattern.street_regex, messages.STREET_FORMAT)
    .required(messages.NUMBER_REQUIRED),
  postCode: yup
    .string()
    .matches(pattern.postcode_regex, messages.POSTCODE_FORMAT)
    .required(messages.POSTCODE_REQUIRED),
  place: yup
    .string()
    .required(messages.PLACE_REQUIRED),
});

const Profile = () => {

const[show,setShow]=useState("");
const [isBusiness, setIsBusiness] = useState('private');
  const [salutation, setSalutation] = useState('MR.');
  const [isDefaultNumber, setDefaultNumber] = useState('Rufnummer*');
  const [profileImg, setProfileImg] = useState('');
  const [profileData, setProfileData] = useState({});
  const [isImageDeleted, setImageDeleted] = useState(false);
  const [picture, setPicture] = useState('');

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    let userId = getItemCookies('user_id');
    if (userId) {
      const response = await GetUser({ "Id": parseInt(userId) })

      if (response.data.status) {
        const result = response.data.result
        setSalutation(result.salutation)
        setIsBusiness(result.isBusiness ? 'business' : 'private')
        setProfileData(result)
        setDefaultNumber(result.isDefaultNumber ? 'Rufnummer*' : 'Geschäftsnummer');
        result?.pictureUrl ? setProfileImg(`https://venoon.com/API${result?.pictureUrl}`) : setImageDeleted(true);
      }
    }
  }

  const imageHandler = async (e) => {
    if (e.target.files.length) {
      var test = await URL.createObjectURL(e.target.files[0]);
      setImageDeleted(false)
      setProfileImg(test)
      setPicture(e.target.files[0]);
    }
  }

  const handleSubmit = async (values) => {

    if (isImageDeleted && !!!profileImg) {
     const response=  await DeleteProfilePicture();
    
     if(response?.status)
     {
      setItemCookies("user_profile",JSON.stringify(""))
     }
     profileSubmit(values);
    }
  
    else if (!profileImg?.includes('https://venoon.com/API')) {
     
      const formData = new FormData();
      formData.append(
        "ProfileImage",
        picture
      );
      const response = await UserProfilePicture(formData);
    
      if (response?.status){
        setItemCookies("user_profile",JSON.stringify(""))
        setItemCookies("user_profile", JSON.stringify(response?.data?.result))
      }
     
      profileSubmit(values);
    }
 else{
  getItemCookies("user_profile")
  profileSubmit(values);
 }
  }

  const profileSubmit = async (values) => {
    const data = {
      "id": profileData.id,
      "salutation": salutation,
      "isBusiness": isBusiness === 'business',
      "isDefaultNumber": isDefaultNumber === 'Rufnummer*',
      "dob": profileData.dob,
      "UserRoleId": parseInt(profileData.userRoleId),
      ...(isBusiness === 'private') && {
        "companyName": '',
        "mobileNumber2": '',
        "taxNumber": '',
        "vatId": ''
      },
      ...values
    }

    const response = await UserRegistration(data);
    if (response.data.status) {
      setShow(response?.data?.message)
     setTimeout(() => {
     setShow("")
     },2000);
    
   
    }
  }

  const initialValues = {
    userName: profileData.userName,
    companyName: profileData.companyName,
    firstName: profileData.firstName,
    lastName: profileData.lastName,
    road: profileData.road,
    num: profileData.num,
    postCode: profileData.postCode,
    place: profileData.place,
    mobileNumber: profileData.mobileNumber,
    mobileNumber2: profileData.mobileNumber2,
    taxNumber: profileData.taxNumber,
    vatId: profileData.vatId,
  }

  return (
    <React.Fragment>
      <>
        <div className="main_container">
          <div className="row">
            <div className="col-sm-12">
              <div className="heading_div">
                <h3 className="main_heading">Profil bearbeiten</h3>
              </div>
            </div>
            <div className="col-sm-12">
           
              <div className="data_outer">
                <Formik enableReinitialize={true} initialValues={initialValues}
                  onSubmit={(values, actions) => { handleSubmit(values, actions) }}
                  validationSchema={isBusiness === 'business' ? validationSchema : validationSchemaTwo}>
                  {({
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                  }) => (
                    <form>
              
                      <p>Bitte wählen Sie Ihr Profiltyp aus:</p>
                      <ul className="sel_service">
                        <li>
                          <input type="radio" id="myRadio1" onChange={() => setIsBusiness('private')} checked={isBusiness === 'private'} />
                          <label htmlFor="myRadio1">
                            <span>Privat</span>
                          </label>
                        </li>
                        <li>
                          <input type="radio" onChange={() => setIsBusiness('business')} checked={isBusiness === 'business'} id="myRadio2" />
                          <label htmlFor="myRadio2">
                            <span>Geschäftlich</span>
                          </label>
                        </li>
                      </ul>
                      <p>Bitte wählen Sie Ihr Profilbild aus:</p>

                      <div className="pikupload">

                        <div className="img-holder">
                          <img
                            src={!isImageDeleted && profileImg}
                            alt="" id="img" className="imground"
                          />
                        </div>
                        {
                          profileImg && !isImageDeleted ?
                            <div onClick={() => {
                              setImageDeleted(true);
                              setProfileImg("");
                            }} className="label">
                              <label className="image-upload" htmlFor="input"><img src={closeIcon} alt="close" /></label>
                            </div>
                            :
                            <>
                              <input type="file" accept="image/*" name="image-upload" id="input" onChange={imageHandler} />
                              <div className="label">
                                <label className="image-upload" htmlFor="input"><img src={plus} alt="plus" /></label>
                              </div>
                            </>
                        }

                      </div>
                      <p>Anrede*</p>
                      <ul className="sel_service">
                        <li>
                          <input type="radio" onChange={() => setSalutation('MR.')} checked={salutation === 'MR.'} id="myRadio3" />
                          <label htmlFor="myRadio3">
                            <span>Herr</span>
                          </label>
                        </li>
                        <li>
                          <input type="radio" onChange={() => setSalutation('MRS')} checked={salutation === 'MRS'} id="myRadio4" />
                          <label htmlFor="myRadio4">
                            <span>Frau</span>
                          </label>
                        </li>
                      </ul>
                      <div className="row">
                        {
                          isBusiness === 'business' ?
                            <div className="col-sm-12">
                              <div className="form-group">
                                <TextInputComponent placeholder="Unternehmen*" label={'Unternehmen*'} name={'companyName'} value={values.companyName} touched={touched.companyName} errors={errors.companyName} onChangeText={handleChange('companyName')} />
                              </div>
                            </div> : ''
                        }
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <TextInputComponent label={'Vorname*'} placeholder="Vorname" name={'firstName'} value={values.firstName} touched={touched.firstName} errors={errors.firstName} onChangeText={handleChange('firstName')} />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <TextInputComponent label={'Nachname*'} placeholder="Nachname" name={'lastName'} value={values.lastName} touched={touched.lastName} errors={errors.lastName} onChangeText={handleChange('lastName')} />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <TextInputComponent label={'Straße*'} placeholder="Straße" name={'road'} value={values.road} touched={touched.road} errors={errors.road} onChangeText={handleChange('road')} />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <TextInputComponent label={'Nr.*'} placeholder="Nr" name={'num'} value={values.num} touched={touched.num} errors={errors.num} onChangeText={handleChange('num')} />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <TextInputComponent label={'PLZ*'} placeholder="PLZ" name={'postCode'} value={values.postCode} touched={touched.postCode} errors={errors.postCode} onChangeText={handleChange('postCode')} />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <TextInputComponent label={'Ort*'} placeholder="Ort"  name={'place'} value={values.place} touched={touched.place} errors={errors.place} onChangeText={handleChange('place')} />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <TextInputComponent label={'Email*'} placeholder="Email" name={'userName'} editable={false} value={values.userName} touched={touched.userName} errors={errors.userName} onChangeText={handleChange('userName')} />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group n-form">
                            {
                              isBusiness==='private'?
                              <TextInputComponent
                              label={'Rufnummer*'} name={'mobileNumber'} value={values.mobileNumber} placeholder="Rufnummer"
                              touched={touched.mobileNumber} errors={errors.mobileNumber} onChangeText={handleChange('mobileNumber')}
                            />
                          :
                          <><TextInputComponent
                          label={'Rufnummer*'} name={'mobileNumber'} value={values.mobileNumber} placeholder="Rufnummer"
                          touched={touched.mobileNumber} errors={errors.mobileNumber} onChangeText={handleChange('mobileNumber')}
                        />
                      
                        <div class='defaultradio'><input type="radio" className='n-t-radio' name="isDefaultNumber" checked={isDefaultNumber==="Rufnummer*"} onChange={()=>setDefaultNumber("Rufnummer*")}/></div></>
                          
                            }
                            </div>
                        </div>
                        {
                          isBusiness === 'business' ?
                            <div className="w-100">
                              <div className="col-sm-12">
                                <div className="form-group n-form">
                             
                                  <TextInputComponent label={'Geschäftsnummer'}
                                   name={'mobileNumber2'} placeholder="Geschäftsnummer"
                                    value={values.mobileNumber2} errors={errors.mobileNumber2} 
                                    onChangeText={handleChange('mobileNumber2')}
                                  />
                                  <div class='defaultradio'><input type="radio" className='n-t-radio'  name="isDefaultNumber"  onChange={()=>setDefaultNumber("Geschäftsnummer")} checked={isDefaultNumber==="Geschäftsnummer"}/></div>
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <TextInputComponent placeholder="Steuernummer" label={'Steuernummer'} name={'taxNumber'} value={values.taxNumber} touched={touched.taxNumber} errors={errors.taxNumber} onChangeText={handleChange('taxNumber')} />
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <TextInputComponent  placeholder="UStID" label={'UStID'}    name={'vatId'} value={values.vatId} touched={touched.vatId} errors={errors.vatId} onChangeText={handleChange('vatId')} />
                                </div>
                              </div>
                            </div> : ''
                        }
                      </div>
                      <Button onClick={handleSubmit}  type="submit" className="theme_btn mt-3 mb-3">Speichern</Button>
                      <p className="msg_show"> {show ? <span><AiFillCheckCircle className='icon_color' style={{fontSize:"30px"}}/> {show}</span>:""}</p>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
)
}
export default Profile