
import './App.css';
import AllRoutes from './Routes/AllRoutes';

import "./scss/style.css"

function App() {
  return (
    <div>
 <AllRoutes/>
    </div>
  );
}

export default App;
