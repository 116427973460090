import React from 'react'
import {useNavigate } from 'react-router-dom'
import handimg from "../../Assests/dashboard/images/hand_img.svg"
import { getJsonObjCookies } from '../Utills/Utills';
import "./Welcome.css";
const Welcome = () => {
  const navigate=useNavigate()
  const handleDashboard=()=>{
    navigate("/dashboard")
    window.location.reload()
  }
  const handleAdmindashboard=()=>{
    navigate("/admindashboard" )
    window.location.reload()
  }
  return (
    <div>
      {
        getJsonObjCookies("user_Role")===1 ? <section className="welcome_screen">
        <div className="mid_center">
            <h1>Willkommen VENOON-Member</h1>
            <img src={handimg} alt="handimg" />
            <h3>Administrator-Board</h3>
            <p>Tracking & Analyse sowie Kalenderübersicht<br/>
            Verwalte User & Locations<br/>
            Feedback und Mitteilungen erstellen</p>
            <button className='button' onClick={handleAdmindashboard} style={{color: '7c2949!important', cursor: 'pointer'}} >Dashboard</button>
        </div>
        </section> :
          <section className="welcome_screen">
          <div className="mid_center">
              <h1>Willkommen Max</h1>
              <img src={handimg} alt="handimg" />
              <h3>Location bearbeiten</h3>
              <p>Verwalte deine gespeicherten Locations<br />
                  Verwalte die Verfügbarkeiten im Kalender sowie Fotos & Videos</p>
              <button className='button' onClick={handleDashboard} style={{color: '7c2949!important', cursor: 'pointer'}} >
                  Dashboard
              </button>

          </div>
      </section>
      }
         
    </div>
  )
}

export default Welcome