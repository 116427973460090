import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './LocationList.css';
import { Modal } from 'react-bootstrap';
import bin from '../../../Assests/dashboard/images/bin.svg';
import flag from '../../../Assests/dashboard/images/flag.svg';
import { NavLink } from 'react-router-dom';
import { ActiveDeactiveLocation, DeleteLocation, LocationListForAdmin } from '../../../Services/Allservices/LocationProvider/locationservices';
import moment from 'moment';
import Loader from '../../Utills/Loader';
import { DropDownList, SearchLocationListForAdmin } from '../../../Services/Allservices/Admin/adminservices';
const LocationList = () => {
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [locationlist, setLocationlist] = useState([])
  const [Deleteid, setDeleteid] = useState()
  const [loading, setloading] = useState(false)
  const [UserID, setUserId] = useState("")
  const [locationId, setlocationId] = useState("")
  const [locationname, setLocationname] = useState("")
  const [varname, setvarname] = useState("")
  const [Nachname, setNachname] = useState("")
  const [ort, setOrt] = useState("")
  const [occasionId, setOccasionId] = useState(0)
  const [adminLocationId, setAdminLocationId] = useState(0)
  const [locationdropdown, setLocationdropdown] = useState()
  const getLocationList = async () => {
    setUserId("")
    setlocationId("")
    setLocationname("")
    setvarname("")
    setNachname("")
    setOrt("")
    setOccasionId(0)
    setAdminLocationId(0)
    setloading(true)
    try {
      const response = await LocationListForAdmin()
      setLocationlist(response?.data?.result?.locationList)
      setloading(false)
      if (response?.data?.status) {

      }
      else {
        console.log(response?.data?.result?.message)
      }
    }
    catch (err) {
      console.log(err)
      setloading(false);
    }
  }
  const locationChangeStatus = async (id, status) => {
    const payload = {
      id: id,
      isactive: status
    }

    const response = await ActiveDeactiveLocation(payload)
    if (response?.data?.status) {

      getLocationList()

    }
    else {
      console.log(response?.data?.message)

    }
  }
  const LocationDelete = async (id) => {
    try {
      const payload = {
        Id: id,
      }
      const response = await DeleteLocation(payload)
      if (response?.data?.result?.transactionMessage?.status === 200) {
        setShow(false)
        getLocationList()
      }
      else {
        setShow(false)
        setShow1(true)
        console.log(response?.data?.result?.transactionMessage?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  // const searchLocationListForAdmin = async () => {

  //   try {
  //     const payload = {
  //       "search": locationname,
  //       "search1": ort,
  //       "search2": UserID,
  //       "search3": locationId,
  //       "FirstName": varname,
  //       "LastName": Nachname,
  //       OccasionId: parseInt(occasionId),
  //       AdminLocationId: parseInt(adminLocationId)
  //     }

  //     const response = await SearchLocationListForAdmin(payload)

  //     if (response?.status) {
  //       setLocationlist(response?.data?.result?.locationList)
  //     }

  //   }
  //   catch (err) {
  //     console.log(err)
  //   }
  // }

  // Search locations by filter
  const searchLocationListForAdmin = async () => {
    let venoonLocationId = parseInt(adminLocationId);
    let payload = {
      search: locationname,
      search1: ort,
      search2: UserID,
      search3: locationId,
      FirstName: varname,
      LastName: Nachname,
      OccasionId: parseInt(occasionId),
      VenoonLocationid: venoonLocationId, // Use adminLocationId as VenoonLocationid
    };

    try {
      const response = await SearchLocationListForAdmin(payload);
      if (response?.status) {
        setLocationlist(response?.data?.result?.locationList);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getLocationDropdown = async () => {
    try {
      const response = await DropDownList()

      if (response?.status) {
        setLocationdropdown(response?.data?.resetSubscription)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getLocationList()
    getLocationDropdown()
  }, [])

  return (
    <React.Fragment>
      {
        loading ? <Loader /> : <>
          <div className="main_container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading_div">
                  <h3 className="main_heading">Location</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="filter_div">
                  <h4>Filter</h4>
                  <form>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="UserID" name="UserID" value={UserID} onChange={(e) => setUserId(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="LocationID" name="locationId" value={locationId} onChange={(e) => setlocationId(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Locationname" name="locationname" value={locationname} onChange={(e) => setLocationname(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Vorname" name="varname" value={varname} onChange={(e) => setvarname(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Nachname" name="Nachname" value={Nachname} onChange={(e) => setNachname(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select className="form-control" onChange={(e) => setOrt(e.target.value)}>
                            <option disabled value="0" selected>Ort</option>
                            {
                              locationdropdown && locationdropdown.length ? locationdropdown.map((item, index) => (

                                <option key={index} value={item}>{item}</option>
                              )) : ''
                            }


                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select className="form-control" name="SubscriptionTypeId" value={occasionId} onChange={(e) => setOccasionId(e.target.value)}>
                            <option disabled value="0" selected>Kategorie</option>

                            <option value="1">Event</option>
                            <option value="2">Party</option>
                            <option value="3">Hochzeit</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select className="form-control" name="adminlocationlist" value={adminLocationId} onChange={(e) => setAdminLocationId(e.target.value)}>
                            <option disabled value="0" selected>Filtern Admin-Location</option>

                            <option value="1">Flag</option>
                            <option value="2">Not Flag</option>
                            <option value="3">Both</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <button type="button" className="theme_btn" onClick={searchLocationListForAdmin}>Suchen</button>
                    <button type="button" className="theme_btn" onClick={getLocationList}>Filter löschen</button>
                  </form>
                </div>
                <div className="table-responsive new_table">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>LocationID</th>
                        <th>Locationname</th>
                        <th>Kategorie</th>
                        <th>UserID</th>
                        <th>Name</th>
                        <th>Erstellungsdatum</th>
                        <th>Straße</th>
                        <th>PLZ</th>
                        <th>Ort</th>
                        <th>Aktiv</th>
                        <th>Aktion</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        locationlist && locationlist.length > 0 ? locationlist.map((item) => (
                          <tr key={item?.locationId}>
                            <td>{item?.locationId}
                               {/* Check for locationFlag === true */}
                              {item?.locationFlag === true && (
                                <img
                                  src={flag} // Using the imported flag image
                                  alt="Flag"
                                  style={{ marginLeft: '8px', width: '20px', height: '20px' }} // Adjust styling as needed
                                />
                              )}
                            </td>
                            <td>{item?.isCompleted ? item?.locationName : `${item?.locationName} (Entwurf)`}</td>
                            <td>{item?.occationName}</td>
                            <td>{item?.createdBy}</td>
                            <td><span>{item?.firstName}</span> <span>{item?.lastName}</span></td>
                            <td>{moment(item?.createdDate).format("DD.MM.YYYY")}  {moment(item?.createdDate).add(1,'hours').format("HH:mm:ss")}</td>
                            <td>{item?.road}, {item?.num}</td>
                            <td>{item?.postCode}</td>
                            <td>{item?.place}</td>
                            <td>
                              {
                                item?.isCompleted ? <label className="custom_check">
                                  <input type="checkbox" defaultChecked={item?.isActive} onChange={() => locationChangeStatus(item?.locationId, !item?.isActive)} />
                                  <span className="checkmark"></span>
                                </label> : ""
                              }

                            </td>
                            <td className="d-flex">
                              <NavLink onClick={() => { setShow(true); setDeleteid(item?.locationId) }}><img src={bin} alt="img" /></NavLink>
                            </td>
                          </tr>
                        )) :
                          <div className="no_record">Keine Location gefunden</div>
                      }
                    </tbody>
                  </table>
                </div>
              </div>

            </div>

            <Modal className="new_Pop" show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Body className="modalbg">
                <form>
                  <div className="text-center">
                    <p className="deletetext">Möchtest du die Location wirklich unwiderruflich löschen?</p>
                  </div>
                  <div className="text-center">
                    <NavLink className="theme_btn" onClick={() => { LocationDelete(Deleteid) }}>Ja</NavLink>
                    <NavLink className="theme_btn" onClick={() => setShow(false)}>Nein</NavLink>
                  </div>
                </form>
              </Modal.Body>
            </Modal>

            <Modal className="new_Pop" show={show1} onHide={() => setShow1(false)} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Body className="modalbg">
                <button type="button" className="close1" onClick={() => setShow1(false)} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <p className="deletetext">Diese Location kann nicht gelöscht werden. Eine Reservierung oder Buchung ist noch aktiv</p>
              </Modal.Body>
            </Modal>

          </div>

        </>
      }

    </React.Fragment>
  )
}

export default LocationList
