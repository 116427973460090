import React, { useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { Modal, Dropdown } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import '../../../App.css';
import logout from "../../../Assests/dashboard/images/logout.svg"
import hamburger_icon from "../../../Assests/dashboard/images/hamburger_icon.svg"
import lock from "../../../Assests/dashboard/images/lock.svg"
import icon01 from "../../../Assests/dashboard/images/icon01.svg"
import icon05 from "../../../Assests/dashboard/images/icon05.svg"
import icon06 from "../../../Assests/dashboard/images/icon06.svg"
import icon02 from "../../../Assests/dashboard/images/icon02.svg"
import icon03 from "../../../Assests/dashboard/images/icon03.svg"
import icon04 from "../../../Assests/dashboard/images/icon04.svg"
import iconS01 from "../../../Assests/dashboard/images/iconS01.svg"
import iconS02 from "../../../Assests/dashboard/images/iconS02.svg"
import iconS03 from "../../../Assests/dashboard/images/iconS03.svg"
import iconS04 from "../../../Assests/dashboard/images/iconS04.svg"
import userpro from "../../../Assests/dashboard/images/userpro.svg"
import {getJsonObjCookies,clearCookies, getItemCookies } from '../../Utills/Utills';
import user from "../../../Assests/dashboard/images/user.svg"
import { Logout} from '../../../Services/LoginAccount/accountservices';



const Sidenav = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [expanded, setexpanded] = useState(true)
    const Logout1 = async() => {
   
        try {
            const payload={
                FcmDeviceToken:null,
                token:getItemCookies("auth_token")
            }
            const response=await Logout(payload)
           
             if (response?.data?.status) {
             
        clearCookies("user_Role")
        clearCookies("user_Email")
        clearCookies("user_FullName")
        clearCookies("user_profile")
        clearCookies("auth_token")
        clearCookies("refresh_token")
        clearCookies("user_id")
        clearCookies("subscriptionEnd")
        clearCookies("subscriptionTypeId")
        clearCookies("numberOfVideos")
        clearCookies("numberOfPhotos")
        clearCookies("numberOfPhotos")
        clearCookies("numberOfLocation")
        navigate("/")
             }
             else {
               console.log(response?.data?.message)
             }
           }
           catch (err) {
             console.log(err)
           }
}

    return (
        <React.Fragment>
            {
                <>
                    <div className="top_navbar">
                        <ul>
                            <li><NavLink onClick={() => setShow(true)}><img src={logout} alt="img" /> <span>Ausloggen</span></NavLink></li>
                        </ul>
                    </div>
                    {
                        getJsonObjCookies("user_Role") === 1 ?

                            <>
                                <SideNav
                                    expanded={expanded}
                                    onToggle={(expanded) => {
                                        setexpanded(expanded);
                                    }}
                               
                                >

                                    <NavLink className="logo">Venoon</NavLink>
                                    <div className="user_div">
                                        <div className="user_Dimg">
                                            <span className="user_pik"><img src={getJsonObjCookies("user_profile") ? `https://venoon.com/API${getJsonObjCookies("user_profile")}` : user} alt="img" /></span>
                                            <span className="user_name">{getJsonObjCookies("user_FullName")} </span>
                                        </div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="">
                                                <img src={hamburger_icon} alt="img" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu right={true}>
                                                <Dropdown.Item href="/changepassword"><img src={lock} alt="img" /> Passwort ändern</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <SideNav.Toggle />
                                    <SideNav.Nav>
                                        <NavLink activeClassName="active" to="/admindashboard">
                                            <NavItem eventKey="startseite">
                                                <NavIcon>
                                                    <img src={icon01} alt="img" />
                                                </NavIcon>
                                                <NavText>
                                                    Startseite
                                                </NavText>
                                            </NavItem>
                                        </NavLink>
                                        <NavLink activeClassName="active" to="/locationlist">
                                            <NavItem eventKey="location">
                                                <NavIcon>
                                                    <img src={icon02} alt="img" />
                                                </NavIcon>
                                                <NavText>
                                                    Location
                                                </NavText>
                                            </NavItem>
                                        </NavLink>
                                        <NavLink activeClassName="active" to="/reservierungen">
                                            <NavItem eventKey="reservierungen">
                                                <NavIcon>
                                                    <img src={icon03} alt="img" />
                                                </NavIcon>
                                                <NavText>
                                                    Reservierungen
                                                </NavText>
                                            </NavItem>
                                        </NavLink>
                                        <NavItem eventKey="abonnement">
                                            <NavIcon>
                                                <img src={icon04} alt="img" />
                                            </NavIcon>
                                            <NavText>
                                                Abonnement
                                            </NavText>
                                            <NavItem eventKey="abonnementverwalten">
                                                <NavText>
                                                    <NavLink className="" to="/subscriptionlist">
                                                        <img src={iconS01} alt="img" />
                                                        Abonnement verwalten
                                                    </NavLink>
                                                </NavText>
                                            </NavItem>
                                            <NavItem eventKey="problemmelden">
                                                <NavText>
                                                    <NavLink activeClassName="active" to="/invoicelist">
                                                        <img src={iconS02} alt="img" />
                                                        Rechnungen verwalten
                                                    </NavLink>
                                                </NavText>
                                            </NavItem>
                                        </NavItem>
                                        <NavLink activeClassName="active" to="/profilelist" state={{ useFor: 'PROFILE' }}>
                                            <NavItem eventKey="profileuser">
                                                <NavIcon>
                                                    <img src={icon05} alt="img" />
                                                </NavIcon>
                                                <NavText>Benutzer</NavText>
                                            </NavItem>
                                        </NavLink>
                                        <NavItem eventKey="supporthilfe">
                                            <NavIcon>
                                                <img src={icon06} alt="img" />
                                            </NavIcon>
                                            <NavText>
                                                Support
                                            </NavText>
                                            <NavItem eventKey="abonnementverwalten">
                                                <NavText>
                                                    <NavLink className="" to="/anfragenandincidents">
                                                        <img src={iconS03} alt="img" />
                                                        Anfragen & Incidents
                                                    </NavLink>
                                                </NavText>
                                            </NavItem>
                                            <NavItem eventKey="feedbackmitteilung">
                                                <NavText>
                                                    <NavLink activeClassName="active" to="/mitteilung">
                                                        <img src={iconS04} alt="img" />
                                                        Mitteilung
                                                    </NavLink>
                                                </NavText>
                                            </NavItem>
                                        </NavItem>
                                        <p className="copy_right">Copyright © 2024 VENOON </p>
                                    </SideNav.Nav>
                                </SideNav>
                            </> :
                            getJsonObjCookies("user_Role") === 2 ?
                                <>
                                    <SideNav
                                        expanded={expanded}
                                        onToggle={(expanded) => {
                                            setexpanded(expanded);
                                        }}
                                    >
                                        <a href="/dashboardhome" className="logo">Venoon</a>
                                        <div className="user_div">
                                            <div className="user_Dimg">
                                                <span className="user_pik"><img src={getJsonObjCookies("user_profile") ? `https://venoon.com/API${getJsonObjCookies("user_profile")}` : user} alt="img" /></span>
                                                <span className="user_name">{getJsonObjCookies("user_FullName")}</span>
                                            </div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="">
                                                    <img src={hamburger_icon} alt="img" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="/editprofile"><img src={userpro} alt="img" /> Profil bearbeiten</Dropdown.Item>
                                                    <Dropdown.Item href="/changepassword"><img src={lock} alt="img" /> Passwort ändern</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <SideNav.Toggle />
                                        <SideNav.Nav>
                                            <NavLink activeClassName="active" to="/dashboard">
                                                <NavItem eventKey="startseite">
                                                    <NavIcon>
                                                        <img src={icon01} alt="img" />
                                                    </NavIcon>
                                                    <NavText>
                                                        Startseite
                                                    </NavText>
                                                </NavItem>
                                            </NavLink>
                                            <NavLink activeClassName="active" to="/mylocationlist">
                                                <NavItem eventKey="meinelocation">
                                                    <NavIcon>
                                                        <img src={icon02} alt="img" />
                                                    </NavIcon>
                                                    <NavText>
                                                        Meine Location
                                                    </NavText>
                                                </NavItem>
                                            </NavLink>
                                            <NavItem eventKey="supporthilfe">
                                                <NavIcon>
                                                    <img src={icon06} alt="img" />
                                                </NavIcon>
                                                <NavText>
                                                    Support
                                                </NavText>
                                                <NavItem eventKey="problemmelden">
                                                    <NavText>
                                                        <NavLink activeClassName="active" to="/reportproblem">
                                                            <img src={iconS03} alt="img" />
                                                            Problem melden
                                                        </NavLink>
                                                    </NavText>
                                                </NavItem>
                                            </NavItem>
                                            <p className="copy_right">Copyright © 2024 VENOON </p>
                                        </SideNav.Nav>
                                    </SideNav>
                                </>
                                : <></>
                    }

                </>
            }
            <Modal className="popUpDesign" show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    <Modal.Title><span style={{ fontSize: 24 }}>Möchtest du dich wirklich abmelden?</span></Modal.Title>
                    <button type="button" className="close" onClick={() => setShow(false)} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="modalbg">
                    <div className="d-flex justify-content-between">
                        <button className="theme_btn" onClick={Logout1} >Ja</button>
                        <button className="theme_btn" onClick={() => setShow(false)}>Nein</button>
                    </div>

                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default Sidenav