import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './ManageInvoiceDetail.css';
import { GetManageInvoice1 } from '../../../Services/Allservices/Admin/adminservices';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Loader from '../../Utills/Loader';
const ManageInvoiceDetail = () => {
  const { state } = useLocation()
  const [invoiceDataById, setInvoiceDataById] = useState([])
  const [loading, setloading] = useState(false)
  const navigate = useNavigate()
  const getInvoiceList = async () => {
    setloading(true)
    try {
      const response = await GetManageInvoice1({ RgNo: state })
      setInvoiceDataById(response?.data?.result)
      if (response?.data?.status) {
        setloading(false)
      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getInvoiceList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const back = () => {
    navigate("/invoicelist")
  }
  return (
    <React.Fragment>
      {
        loading ? <Loader /> :
          <>
            <div className="main_container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="heading_div">
                    <h3 className="main_heading">Rechnungsdetails</h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="subscriptview">
                    <div className="row">
                      <div className="col-6 col-sm-6 col-md-6 col-lg-5">
                        <ul className="list_group text-right listheadsub">
                          <li>UserID :</li>
                          <li>UserTyp :</li>
                          <li>Vorname :</li>
                          <li>Nachname :</li>
                          <li>Straße :</li>
                          <li>Firmenname :</li>
                          <li>Nr. :</li>
                          <li>PLZ :</li>
                          <li>Ort :</li>
                          <li>E-Mail :</li>
                          <li>Rufnummer :</li>
                          <li>Office Nummer :</li>
                          <li>Steuernummer :</li>
                          <li>UStID :</li>
                          <li>Abo :</li>
                          <li>Abo-Start :</li>
                          <li>Abo-Ende :</li>
                          <li>Bezahlen :</li>
                          <li>Fällig am :</li>
                          <li>Bezahlt :</li>
                          <li>RechnungsNr. :</li>
                          <li>Rechnungsdatum :</li>
                          <li>Rechnungsbetrag :</li>
                          <li>Zahlungstyp :</li>
                          <li>ZahlungstransaktionsID :</li>
                          <li>Referenz :</li>
                        </ul>
                      </div>
                      <div className="col-6 col-sm-6 col-md-6 col-lg-7">
                        {
                          invoiceDataById.map((item) => (
                            <ul className="list_group" key={item?.rgNo}>
                              <li>{item?.userId ? item?.userId : "-"}</li>
                              <li>{item.isBusiness ? <span>Geschäftlich</span> : <span>Privat</span>}</li>
                              <li>{item?.firstName ? item?.firstName : "-"}</li>
                              <li>{item?.lastName ? item?.lastName : "-"}</li>
                              <li>{item?.road ? item?.road : "-"}</li>
                              <li>{item?.companyName ? item?.companyName : "-"}</li>
                              <li>{item?.num ? item?.num : "-"}</li>
                              <li>{item?.postCode ? item?.postCode : "-"}</li>
                              <li>{item?.ort ? item?.ort : "-"}</li>
                              <li>{item?.email ? item?.email : "-"}</li>
                              <li>{item?.mobileNumber ? item?.mobileNumber : "-"}</li>
                              <li>{item?.mobileNumber2 ? item?.mobileNumber2 : "-"}</li>
                              <li>{item?.taxNumber ? item?.taxNumber : "-"}</li>
                              <li>{item?.vatId ? item?.vatId : "-"}</li>
                              <li>{item?.subscriptiontype ? item?.subscriptiontype : "-"}</li>
                              <li>{item?.isDeleted ? "-" : item?.dueOn ? moment(item?.subscriptionStart).format("DD.MM.YYYY ,HH:mm:ss") : "-"}</li>
                              <li>{item?.isDeleted ? "-" : item?.dueOn ? moment(item?.subscriptionEnd).format("DD.MM.YYYY ,HH:mm:ss") : "-"}</li>
                              <li>{item?.isDeleted ? "-" : item?.isPaid ? "Ja" : "Nein"}</li>
                              <li>{item?.isDeleted ? "-" : item?.dueOn ? moment(item?.dueOn).format("DD.MM.YYYY ,HH:mm:ss") : "-"}</li>
                              <li>{item?.isDeleted ? "-" : item?.paymentType === "Kreditkarte" ? moment(item?.rgDate).format("DD.MM.YYYY") : (item?.isPaid && item?.paymentType === "Rechung") ? moment(item?.paidOn).format("DD.MM.YYYY") : "-"}</li>
                              <li>{item?.rgNo ? item?.rgNo : "-"}</li>
                              <li>{(item?.isDeleted || item?.rgDate) ? moment(item?.rgDate).format("DD.MM.YYYY ,HH:mm:ss") : "-"}</li>
                              <li>{item?.amount ? item?.amount : "-"}</li>
                              <li>{item?.paymentType ? item?.paymentType : "-"}</li>
                              <li>{item?.transctionId ? item?.transctionId : "-"}</li>
                              <li> - </li>
                            </ul>
                          ))
                        }

                      </div>
                    </div>
                  </div>
                  <button type="submit" className="theme_btn" onClick={back} >Zurück</button>
                </div>
              </div>
            </div>
          </>
      }
    </React.Fragment>
  )
}

export default ManageInvoiceDetail
