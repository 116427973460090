import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import "./Login.css"
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { UserLogin } from '../../Services/LoginAccount/accountservices'
import { setItemCookies } from "../Utills/Utills"
import { msg } from '../../Services/Axios'
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
const Login = () => {
    const [show, setShow] = useState(false)
    const navigate = useNavigate()
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")
    const [errors, setErros] = useState({});
    const [passwordType, setPasswordType] = useState("password");

    const [show1, setShow1] = useState("")
    const location = useLocation()


    const handleSubmit = async (e) => {

        e.preventDefault()
        if (!validateForm()) {
            return
        }

        const payload = {

            Email: Email,
            Password: Password,
            GrantType: "password",
            IsWeb: true

        }
        const response = await UserLogin(payload);
        try {

            if (response?.data?.status) {

                navigate("/welcome")
                setItemCookies("user_Role", JSON.stringify(response?.data?.result?.userRoleId))
                setItemCookies("user_Email", JSON.stringify(response?.data?.result?.emailId))
                setItemCookies("user_FullName", JSON.stringify(response?.data?.result?.fullName))
                setItemCookies("user_profile", JSON.stringify(response?.data?.result?.picUrl))
                setItemCookies("auth_token", response?.data?.result?.token)
                setItemCookies("refresh_token", response?.data?.result?.refreshToken)
                setItemCookies("user_id", response?.data?.result?.userId)

            }
            else {

                setShow1(response?.data?.result?.transactionMessage?.message)
                setTimeout(() => {
                    setShow1("")
                }, 5000);

            }
        }
        catch (err) {

        }
    }
    const validateForm = () => {
        var emailValid = Email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        var isValid = true;

        if (Email.trim() === "") {
            errors.Email = msg.blankemailinput
            isValid = false;
        }
        if (!emailValid) {
            errors.Email = msg.correctformat;
            isValid = false;

        }
        else {
            errors.Email = "";
        }

        if (Password.trim() === "" || Password === "0") {
            errors.Password = msg.blankpasswordinput;
            isValid = false;

        }

        else {
            errors.Password = ""
        }
        if (Email.trim() === "" || Password.trim() === "") {
            errors.user = ""
        }

        setErros({ ...errors, errors });
        return isValid;
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return
        }
        setPasswordType("password");
    };
    const cleardata = () => {
        window.location.reload()

    }
    return (
        <div>
            <div className="banner_btn">
                {
                    location.pathname === "/" ? <NavLink onClick={() => setShow(true)}>
                        Login
                    </NavLink> :
                        <div className="banner_btn">
                            <NavLink to="/" className="home_btn mr-2">Home</NavLink>

                            <NavLink onClick={() => setShow(true)}>
                                Login
                            </NavLink>
                        </div>
                }

            </div>
            <Modal className="popUpDesign" show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    <Modal.Title>Anmelden</Modal.Title>
                    <button type="button" className="close" onClick={() => { setShow(false); cleardata() }} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="modalbg">
                    <div className="form-group">
                        <input type="email" name="Email" value={Email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="E-Mail-Adresse" onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleSubmit(e)

                            }
                        }} />

                        {!!errors.Email && <div style={{ color: 'red' }}>{errors.Email}</div>}

                    </div>
                    <div className="form-group position-relative">
                        <input name="Password" type={passwordType} value={Password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Kennwort" onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleSubmit(e)

                            }
                        }} />
                        <button className="showhidebtn" onClick={togglePassword}>
                            {passwordType === "password" ? (
                                <span><AiFillEyeInvisible /></span>

                            ) : (
                                <span><AiFillEye /></span>
                            )}
                        </button>

                        {!!errors.Password && <div style={{ color: 'red' }}>{errors.Password}</div>}

                    </div>
                    <button onClick={handleSubmit} className="btn" style={{ width: '100%' }}>login</button>
                    <div style={{ color: 'red' }}>{show1 ? show1 : ""}</div>

                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Login