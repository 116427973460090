import adminHttpsservices from "./adminHttpsservices"
import { config } from "../../Axios"

export const GetAdminDashboard = () => {
    return adminHttpsservices.post(config.Admindashboard, {})
}
export const GetAdminDashboard1 = (payload) => {
    return adminHttpsservices.post(config.Admindashboard, payload)
}
export const GetAllAnnouncement = () => {
    return adminHttpsservices.post(config.GetAllAnnouncement, {})
}
export const AnnoucmentByAdmin = (payload) => {
    return adminHttpsservices.post(config.AnnoucmentByAdmin, payload)
}
export const DeleteAndUpdateAnnouncement = (payload) => {
    return adminHttpsservices.post(config.DeleteAndUpdateAnnouncement, payload)
}
export const ListOfProblemMaiden = () => {
    return adminHttpsservices.post(config.ListOfProblemMaiden, {})
}
export const ListOfProblemMaiden1= (payload) => {
    return adminHttpsservices.post(config.ListOfProblemMaiden,payload)
}
export const GetProblemById = (payload) => {
    return adminHttpsservices.post(config.GetProblemById, payload)
}
export const ProblemSolutionByAdmin = (payload) => {
    return adminHttpsservices.post(config.ProblemSolutionByAdmin, payload)
}
export const GetUserProfile = () => {
    return adminHttpsservices.post(config.GetUserProfile, {})
}
export const GetManageInvoice = () => {
    return adminHttpsservices.post(config.GetManageInvoice, {})
}
export const GetManageInvoice1 = (payload) => {
    return adminHttpsservices.post(config.GetManageInvoice, payload)
}
export const GetUserProfilesById = (payload) => {
    return adminHttpsservices.post(config.GetUserProfilesById + `?Id=${payload}`, {})
}
export const SubscriptionTypeList = () => {
    return adminHttpsservices.post(config.SubscriptionTypeList, {})
}
export const GetUserSubscription = () => {
    return adminHttpsservices.post(config.GetUserSubscription, {})
}
export const ActiveDeactiveUserByAdmin = (payload) => {
    return adminHttpsservices.post(config.ActiveDeactiveUserByAdmin, payload)
}
export const ProblemStatusByAdmin = (id, status) => {
    return adminHttpsservices.post(config.ProblemStatusByAdmin + `?Id=${id}&ProblemStatus=${status}`, {})
}
export const VerifyUser = (payload) => {
    return adminHttpsservices.post(config.verifylocation + `?Id=${payload}`, {})
}
export const GetUserSubscription1 = (payload) => {
    return adminHttpsservices.post(config.GetUserSubscription, payload)
}
export const SubscriptionTypeDelete = (payload) => {
    return adminHttpsservices.post(config.SubscriptionTypeDelete, payload)
}
export const SaveInvoiceDocument = (payload) => {
    return adminHttpsservices.formpost(config.SaveInvoiceDocument, payload)
}
export const ProviderPaymentUpDate = (payload) => {

    return adminHttpsservices.post(config.ProviderPaymentUpDate + `?Id=${payload.Id}`, payload)
}

export const InvoicePaymentDelete = (payload) => {

    return adminHttpsservices.post(config.InvoicePaymentDelete + `?InvoiceId=${payload}`, {})
}
export const ReservationDeleteByAdmin = (payload) => {

    return adminHttpsservices.post(config.ReservationDeleteByAdmin + `?Id=${payload}`, {})
}
export const InvoiceClick = (payload) => {

    return adminHttpsservices.post(config.InvoiceClick + `?Id=${payload}`, {})
}
export const DownloadInvoiceDocument = (payload) => {

    return adminHttpsservices.post(config.DownloadInvoiceDocument + `?Id=${payload}`, {})
}
export const SearchLocationListForAdmin = (payload) => {
    return adminHttpsservices.post(config.SearchLocationListForAdmin, payload)
}
export const DropDownList = () => {
    return adminHttpsservices.post(config.DropDownList, {})
}
export const GetReservationSearch = (payload) => {
    return adminHttpsservices.post(config.GetReservationSearch, payload)
}
export const UserSubscriptionSearch = (payload) => {
    return adminHttpsservices.post(config.UserSubscriptionSearch, payload)
}
export const InvoiceListSearch = (payload) => {
    return adminHttpsservices.post(config.InvoiceListSearch, payload)
}
export const UserProfileSearch = (payload) => {
    return adminHttpsservices.post(config.UserProfileSearch, payload)
}
export const UserSubscriptionSearchAdmin = (payload) => {
    return adminHttpsservices.post(config.UserSubscriptionSearchAdmin, payload)
}
export const InvoiceListSearchAdmin = (payload) => {
    return adminHttpsservices.post(config.InvoiceListSearchAdmin, payload)
}
export const DeleteUserById = (payload) => {
    return adminHttpsservices.post(config.DeleteUserById, payload)
}
export const MailToAdmin = (payload) => {
    return adminHttpsservices.post(config.MailToAdmin, payload)
}




