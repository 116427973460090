import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, CardBody, Col, } from 'reactstrap';
import { FeaturesList, GetLocationFeatures, GetLocationPrice, SaveLocationFeatures, SaveLocationPrice } from '../../../Services/Allservices/LocationProvider/locationservices';
import "./CreateForm.css"
import { msg } from '../../../Services/Axios';
import { useLocation } from 'react-router-dom';

const Details = (props) => {
  const [featuresdata, setFeaturesdata] = useState([])
  const [MaxReservation, setMaxReservation] = useState(2)
  const [LocationFloor, setLocationFloor] = useState("")
  const [LocationArea, setLocationArea] = useState("")
  const [Extra, setExtra] = useState("")
  const [errors, setErros] = useState({});
  const { state } = useLocation();
  const { isEdit, editId } = state || {}
  const LocationAreaRef = useRef()
  useEffect(() => {
    featuresServices()
    getData()
    getLocationFeature()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {

    if (isEdit === 'edit' || isEdit === 'copy') {
      Promise.all([
        await GetLocationPrice({ LocationId: editId }),
      ])
        .then((res) => {

          if (res[0].status === 200) {
            setMaxReservation(res[0]?.data?.result?.maxReservation)
            setExtra(res[0]?.data?.result?.extra)
            setLocationFloor(res[0]?.data?.result?.locationFloor)
            setLocationArea(res[0]?.data?.result?.locationArea)
          }

        })
    }
    else {
      const response = await FeaturesList()
      setFeaturesdata(response?.data?.result?.serviceList);
    }
  }

  const getLocationFeature = async () => {
    try {
      const payload = {
        "locationId": editId
      }
      const response = await GetLocationFeatures(payload)

      setFeaturesdata(response?.data?.result?.locationFeatures);
    }
    catch (err) {
      console.log(err)
    }

  }
 
  const featuresServices = async () => {
    try {
      const response = await FeaturesList()
      setFeaturesdata(response?.data?.result?.featuresList)
    }
    catch (err) {
      console.log(err)
    }


  }
  const handleSubmit = async () => {
    if (!validateForm()) {
      return
    }
    let temp = [];

    featuresdata.forEach(element => {
      if (element.isSelected) {
        temp.push({ LocationId: (isEdit === 'edit' || isEdit === 'copy') ? editId : props.locationId, FeatureId: element.featureId })
      }
    });

    if (temp.length) {
      Promise.all([
        priceSubmit(),
        categorySubmit(temp)
      ])
      props.onSelect('fifth')
    } else {
      priceSubmit()
    }
  }
  const changeFeature = (index) => {
    let tempService = [...featuresdata]
    tempService[index].isSelected = !tempService[index].isSelected
    setFeaturesdata(tempService);
  }
  const priceSubmit = async () => {
    if (!validateForm()) {
      return
    }
    try {

      const payload = {
        "Id": editId,
        "LocationId": (isEdit === 'edit' || isEdit === 'copy') ? editId : props.locationId,
        "OccasionId": props.pricedata.data.OccasionId,
        "LPriceFrom": props.pricedata.data.LPriceFrom,
        "LPriceTo": props.pricedata.data.LPriceTo,
        "NOPFrom": parseInt(props.pricedata.data.NOPFrom),
        "NOPTo": parseInt(props.pricedata.data.NOPTo),
        "Cancellation": "test",
        "MaxReservation": parseInt(MaxReservation),
        "LocationFloor": LocationFloor,
        "LocationArea": LocationArea,
        "Extra": Extra
      }

      const response = await SaveLocationPrice(payload)

      if (response?.data?.status) {

        props.onSelect('fifth')
      }
      else {
        console.log(response?.data?.result?.transactionMessage?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const categorySubmit = async (services) => {
    await SaveLocationFeatures({ LocationId: (isEdit === 'edit' || isEdit === 'copy') ? editId : props.locationId, locationFeatures: services })
  }
  const validateForm = () => {
    var isValid = true;
    const pattern_reservation = /^\d+$/.test(MaxReservation)
    if (MaxReservation === "") {
      errors.MaxReservation = msg.detailsplanning
      isValid = false;
    }
    else if (MaxReservation <= 1) {
      errors.MaxReservation = msg.detailsplanningdays
      isValid = false;
    }
    else if (!pattern_reservation) {
      errors.MaxReservation = msg.detailsplanningnum
      isValid = false;
    }
    else {
      errors.MaxReservation = "";
    }
    setErros({ ...errors, errors });
    return isValid;
  }
  const handlelocationArea = (e) => {
    var isValid1 = true;
    setLocationArea(e.target.value)

    const pattern_LocationArea = /^\d+$/.test(LocationAreaRef?.current?.value)
    if (LocationAreaRef?.current?.value.trim() === "") {
      errors.LocationArea = ""
      isValid1 = false;
    }
    else if (!pattern_LocationArea) {
      errors.LocationArea = msg.locationarea
      isValid1 = false;
    }
    else {
      errors.LocationArea = "";
    }
    setErros({ ...errors, errors });
    return isValid1;
  }
  return (


    <React.Fragment>
      <div className="form_outer">
        <div className="row">
          <Col sm="12">
            <Card>
              <h5>Details</h5>
              <CardBody>
                <div className="row">

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="">Vorausplanungszeit (in Tage)*</label>
                      <input type="text" name="MaxReservation" value={MaxReservation} onChange={(e) => setMaxReservation(e.target.value)} className="form-control" placeholder="Bitte eingeben" />
                      {!!errors.MaxReservation && <div style={{ color: 'red' }}>{errors.MaxReservation}</div>}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Etage</label>
                      <input type="text" name="LocationFloor" value={LocationFloor} onChange={(e) => setLocationFloor(e.target.value)} className="form-control" placeholder="Bitte eingeben" />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Fläche (m²)</label>
                      <input type="text" name='LocationArea' ref={LocationAreaRef} value={LocationArea} onChange={handlelocationArea} className="form-control" placeholder="Bitte eingeben" />
                      {!!errors.LocationArea && <div style={{ color: 'red' }}>{errors.LocationArea}</div>}

                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input type="text" name="Extra" value={Extra} onChange={(e) => setExtra(e.target.value)} className="form-control" placeholder="Extras" />

                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="">Features</label>
                      <div className="pikFeature">
                        <ul className="featurelist">
                          {
                            featuresdata && featuresdata.length ?
                              featuresdata.map((item, index) => (
                                <li key={index}>
                                  <div className="featureName">{item.name}</div>
                                  <div className="featureStatus">
                                    <div className="my custom-control custom-switch">
                                      <input type="checkbox" className="custom-control-input " id={index} checked={item?.isSelected} onChange={() => changeFeature(index)} />
                                      <label className=" custom-control-label" htmlFor={index}></label>
                                    </div>
                                  </div>
                                </li>
                              )) : ''
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>

      <div className="nextPrevBtn">
        <Button className="theme_btn" onClick={() => props.onSelect('third')}>
          Zurück
        </Button>
        <Button className="theme_btn" disabled={errors.LocationArea} onClick={handleSubmit}>
          Weiter
        </Button>
      </div>
    </React.Fragment>

  )
}

export default Details