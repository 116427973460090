import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './Reservierungen.css';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { de } from 'date-fns/locale'
import { DatePicker } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import bin from '../../../Assests/dashboard/images/bin.svg';
import status from '../../../Assests/dashboard/images/status.svg';
import { NavLink } from 'react-router-dom';
import { BookLocation, GetReservationList } from '../../../Services/Allservices/LocationProvider/locationservices';
import Loader from '../../Utills/Loader';
import { ReservationDeleteByAdmin } from '../../../Services/Allservices/Admin/adminservices';
import { GetReservationSearch } from '../../../Services/Allservices/Admin/adminservices';

const Reservierungen = () => {
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [reservelist, setReservelist] = useState([])
  const [reservationid, setreservationId] = useState("")
  const [LocationName, setLocationName] = useState("")
  const [LocationID, setLocationID] = useState("")
  const [FirstName, setFirstName] = useState("")
  const [LastName, setLastName] = useState("")
  const [UserId, setUserId] = useState("")
  const [ReservationDate, setReservationDate] = useState(null)
  const [reserveid, setReserveId] = useState()
  const [locId, setLocId] = useState()
  const [loading, setloading] = useState(false)
  const [IsBooked, setIsBooked] = useState(2)

  const getReserveList = async () => {
    setIsBooked(2)
    setLastName("")
    setFirstName("")
    setLocationID("")
    setLocationName("")
    setUserId("")
    setreservationId("")
    setReservationDate(null)
    setloading(true)
    try {
      const response = await GetReservationList()
      setReservelist(response?.data?.result?.reservationList)
      setloading(false)
      if (response?.data?.status) {

      }
      else {
        console.log(response?.data?.result?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }


  const deleteReservation = async (id) => {
    try {
      const response = await ReservationDeleteByAdmin(id)
      if (response?.data?.status) {
        setShow2(false)
        getReserveList()
      }
      else {
        console.log(response?.data?.result?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const bookLocation = async (id, loc) => {
    try {
      const payload = {
        "Id": id,
        "locationId": loc
      }
      const response = await BookLocation(payload)
      if (response?.data?.status) {
        setShow1(false)
        getReserveList()
      }
      else {
        console.log(response?.data?.result?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  const searchreservationListForAdmin = async () => {

    try {
      const payload = {
        "ReservationId": reservationid ? parseInt(reservationid) : 0,
        "LocationName": LocationName,
        "FirstName": FirstName,
        "LastName": LastName,
        "ReservationDate": ReservationDate ? moment(ReservationDate).format("YYYY-MM-DD") : null,
        "UserId": UserId ? parseInt(UserId) : 0,
        "IsBooked": IsBooked ? parseInt(IsBooked) : 2,
        "LocationId": LocationID ? parseInt(LocationID) : 0
      }

      const response = await GetReservationSearch(payload)

      if (response?.status) {
        setReservelist(response?.data?.result?.reservationList)
      }

    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getReserveList()

  }, [])

  return (
    <React.Fragment>
      {
        loading ? <Loader /> : <>
          <div className="main_container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading_div">
                  <h3 className="main_heading">Reservierungen</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="filter_div">
                  <h4>Filter</h4>
                  <form>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" name="reservationid" value={reservationid} placeholder="ReservierungsID" onChange={(e) => setreservationId(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="LocationID" name="LocationID" value={LocationID} onChange={(e) => setLocationID(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Locationname" name="LocationName" value={LocationName} onChange={(e) => setLocationName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="UserID" name="UserId" value={UserId} onChange={(e) => setUserId(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Vorname" name="FirstName" value={FirstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Nachname" name="LastName" value={LastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">

                          <DatePicker
                            date={ReservationDate}
                            onDateChange={setReservationDate}
                            format='dd MMM yyyy'
                            locale={de}
                          >
                            {({ inputProps, focus }) => (

                              <div className='date-range'>
                                <input
                                  className={'input form-control mb-3 dateicon' + (focus === ReservationDate ? ' -focused' : '')}
                                  {...inputProps}
                                  placeholder="Reserviert am"

                                />

                              </div>
                            )}
                          </DatePicker>






                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select className="form-control" name="IsBooked" value={IsBooked} onChange={(e) => setIsBooked(e.target.value)}>
                            <option disabled={true} value="2" selected>Status</option>
                            <option value="1">Gebucht</option>
                            <option value="0">Reserivert</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <button type="button" className="theme_btn" onClick={searchreservationListForAdmin}>Suchen</button>
                    <button type="button" className="theme_btn" onClick={getReserveList}>Filter löschen</button>
                  </form>
                </div>
                <div className="table-responsive new_table">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>ReservID</th>
                        <th>Location ID</th>
                        <th>Locationname<br />Adresse</th>
                        <th>Reserviert am</th>
                        <th>Reserviert/Gebucht<br />Von-Bis</th>
                        <th>Status</th>
                        <th>UserID</th>
                        <th>Name</th>
                        <th>Aktion</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        reservelist && reservelist.length > 0 ?
                          reservelist.map((item) => (
                            <tr key={item?.reservationId}>
                              <td>{item?.reservationId}</td>
                              <td>{item?.locationId}</td>
                              <td>{item?.locationName}<br /><span>{`${item?.road} ${item?.num}, ${item?.postcode} ${item?.place}`}</span></td>
                              <td><span>{moment(item?.reservationDate).format("DD.MM.YYYY")}</span> <span>{moment(item?.reservationDate).add(1,'hours').format("HH:mm:ss")}</span></td>
                              <td>{moment(item?.reservationUntil).format("DD.MM.YYYY")}-<div>{moment(item?.dateOfReservations).format("DD.MM.YYYY")} </div></td>
                              <td>{item?.isBooked ? "Gebucht" : "Reserviert"}</td>
                              <td>{item?.userId}</td>
                              <td><span>{item?.firstName}</span>  <span>{item?.lastName}</span></td>
                              <td className="d-flex">
                                <button className='remove_border bookopacity' disabled={item?.isBooked} onClick={() => { setShow1(true); setReserveId(item?.reservationId); setLocId(item?.locationId) }}><img src={status} alt="img" /></button>
                                <button className='remove_border' onClick={() => { setShow2(true); setReserveId(item?.reservationId) }}><img src={bin} alt="img" /></button>
                              </td>
                            </tr>
                          ))
                          :
                          <div className="no_record">Kein Eintrag gefunden!</div>

                      }

                    </tbody>
                  </table>
                </div>
              </div>

            </div>

            <Modal className="new_Pop" show={show1} onHide={() => setShow1(false)} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header>
                <Modal.Title>Reservierungsstatus ändern</Modal.Title>
                <button type="button" className="close" onClick={() => setShow1(false)} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body className="modalbg">
                <form>
                  <div className="form-group">
                    <select className="form-control">
                      <option>Reserivert</option>
                      <option>Gebucht</option>
                    </select>
                  </div>
                  <div className="text-center">
                    <NavLink className="theme_btn" onClick={() => bookLocation(reserveid, locId)} >Speichern</NavLink>
                  </div>
                </form>
              </Modal.Body>
            </Modal>

            <Modal className="new_Pop" show={show2} onHide={() => setShow2(false)} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Body className="modalbg">
                <form>
                  <div className="text-center">
                    <p className="deletetext">Möchtest du die Reservierung oder Buchung wirklich löschen?</p>
                  </div>
                  <div className="text-center">
                    <NavLink className="theme_btn" onClick={() => deleteReservation(reserveid)}>Ja</NavLink>
                    <NavLink className="theme_btn" onClick={() => setShow2(false)}>Nein</NavLink>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default Reservierungen
