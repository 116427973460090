import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./NotFound.css"
import { clearCookies } from '../Utills/Utills'
const NotFound = () => {
    const navigate = useNavigate()
    const back = () => {
        clearCookies("user_Role")
        clearCookies("user_Email")
        clearCookies("user_FullName")
        clearCookies("user_profile")
        clearCookies("auth_token")
        clearCookies("refresh_token")
        clearCookies("user_id")
        clearCookies("subscriptionEnd")
        clearCookies("subscriptionTypeId")
        clearCookies("numberOfVideos")
        clearCookies("numberOfPhotos")
        clearCookies("numberOfPhotos")
        clearCookies("numberOfLocation")
        navigate("/")

    }
    return (
        <div className='notfoundpage'>
            <section className='pagesection'>
                <h1>404</h1>
                <h1>Not Found</h1>
                <button className="button" onClick={back}>Home</button>
            </section>
        </div>
    )
}

export default NotFound