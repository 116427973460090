import React from 'react'
import Login from '../../Login/Login'
import Footer from './Footer'
import logoImg from "../../../Images/logo.svg"
import "../../../App.css"
import { NavLink } from 'react-router-dom'

const Agb = () => {
  return (
    <div>  <section className="banner_sec innerPage">
    <div className="container">
        <div className="row">
        <div className="col-sm-12">
           <div className="logo">
           <img src={logoImg} alt="logo"/>
           </div> 
            <Login />
        </div>    
        </div>
    </div>
</section>
<section className="heading_innerPage">
    <div className="container">
        <div className="row">
            <div className="col-sm-12">
            <h3>Unsere AGB</h3>            
            </div>
        </div>
    </div>
</section>
<div className="container">
    <div className="contact_sec">
        <div className="row">
            <div className="col-sm-12">
                <div className="textList mt-0">
                <h5 className="heading2">1. Geltungsbereich </h5>
                <p><b className="text-underline">1.1</b> VENOON GmbH (“VENOON“) ist ein Anbieter einer mobilen App, die es den Location-Suchenden sowie Location-Anbietern als Nutzern der App (die „Nutzer“) ermöglicht, passende Location (z.B. Eventlocations, Meetingräume, Partylocations etc.) für die Durchführung von Veranstaltungen und Events anzubieten, zu suchen und diese zu reservieren.</p>
                <p><b>1.2</b> Die Location-Suchende können un-/verbindliche Reservierungsanfragen an die Location-Anbieter richten (“Anfrage”), aber auch verbindliche Anfragen zur Buchung einer Veranstaltung bei dem ausgewählten Location-Anbieter abgeben. Diese können akzeptiert oder abgelehnt werden.</p>
                <p><b>1.3</b> Der Vertragsschluss über die Durchführung der Veranstaltung erfolgt direkt mit dem jeweiligen Location-Anbieter und dem Location-Suchenden. VENOON ist nicht Partei des Event-Vertrages.</p>
                <p><b>1.4</b> VENOON dient lediglich als Anbieter einer App und schließt keine Vermittlungsverträge mit dem jeweiligen Nutzer, der die Leistungen von VENOON in Anspruch nimmt (der „Vermittlungsvertrag“). Gegenstand dieser AGB ist ausschließlich die Regelung des Vertragsverhältnisses zwischen VENOON und dem Nutzer.</p>
                <p><b>1.5</b> Die nachfolgenden Allgemeinen Nutzungsbedingungen (“AGB”) gelten in ihrer zum Zeitpunkt der Nutzung gültigen Fassung, auch wenn der Zugriff von außerhalb der Bundesrepublik Deutschland erfolgt. Für etwaige von VENOON initiierte oder unterstützte Sonderaktionen sowie andere Zugangswege (z.B. über Applikationen) können abweichende oder ergänzende Geschäftsbedingungen gelten.</p>
                <p><b>1.6</b> Die Verwendung des Apps als solches ist für den Nutzer kostenlos, soweit kein gesonderter Vertrag über die kostenpflichtige Nutzung des Apps oder darüber angebotener Leistungen von VENOON geschlossen wurde. </p>
                
                <h5 className="heading2">2. Leistungen von VENOON</h5>
                <p><b>2.1</b> VENOON ist Betreiber der App und ermöglicht den Nutzern die Inanspruchnahme der über die App angebotenen Serviceleistungen.</p>
                <p><b>2.2</b> In der App erstellt jeder Nutzer sein eigenes Profil. Die Location-Suchenden bestehen aus Profildaten (Name, Nachname, Adressen) und Medien (Profilfotos). Die Anbieter-Profile bestehen aus Profildaten (insbes. Beschreibungstexten und Raumtabellen) und Medien (insbes. Fotos, Videos), die der Anbieter VENOON zur Verfügung stellt bzw. selbst in die App hinzufügt, oder VENOON bei Beauftragung für den Anbieter die Profile erstellt/ hinterlegt. Auf die Richtigkeit bzw. Echtheit und Vollständigkeit der Profildaten sind die Nutzer selbst verantwortlich. VENOON übernimmt hierbei keinerlei Haftung.</p>
                <p><b>2.3</b> VENOON steht für den Nutzer zunächst beratend per E-Mail /Anfrage-Formular zur Verfügung. Insbesondere kann VENOON den Location-Anbieter empfehlen, Informationen zu den Locaiton-Anbietern mit dem Locaiton-Suchenden zu teilen oder Informationen im Auftrag des Locaiton-Suchenden einzuholen. Die Beratung, insbesondere das Vorschlagen von Location-Anbietern erfolgt nach Ermessen von VENOON, dient der allgemeinen Auskunft und begründet keine Ansprüche des Location-Suchenden gegenüber VENOON auf Richtigkeit und Vollständigkeit.</p>
                <p><b>2.4</b> VENOON prüft und verwaltet eingehende Anfragen der Nutzer. VENOON orientiert sich dabei insbesondere an den Wünschen des Location-Suchenden in der Anfrage sowie den Profildaten des Location-Anbieters und behält sich vor, Anfragen zu ergänzen, zu bearbeiten sowie unpassende oder fehlerhafte Anfragen nicht an den Location-Anbieter weiterzuleiten bzw. die Profildaten nach Bedarf aus der App zu löschen. </p>
                <p><b>2.5</b> Leistungen sind auch die E-Mails zwischen VENOON und Nutzer oder zwischen den einzelnen Nutzern (Location-Suchende, Location-Anbieter). Es handelt sich hierbei um Bestätigungsemails (u.a. Reservierung, Buchung, Stornierung, Registrierung) oder auch andere E-Mails (z. B. Abo-Bestätigung, Erinnerung für Zahlungsfrist, Abo ist abgelaufen/gekündigt).</p>
                <p><b>2.6</b> VENOON ermöglicht den Nutzer anhand der App sowie durch den Webauftritt die Dienste von VENOON zu nutzen. Eine Location-Suchende kann mithilfe der App nach passenden Locations suchen oder reservieren (z.B. Eventlocations, Meetingräume, Partylocations etc.). Ein Location-Anbieter kann mithilfe der App oder Webseite passende Location erstellen, vermitteln und diese auch (nach Vereinbarung) buchen.</p>
                <p><b>2.7</b> Sofern sich die Nutzer in der VENOON App nicht registrieren, können sie die App im "Gast-Modus" nur  mit eingeschränkten Funktionen nutzen (bspw. nur Location suchen). Eine Registrierung bietet viele Vorteile:</p>
                <ul className="agblist">
                    <li>Favoriten speichern</li>
                    <li>Suchen speichern</li>
                    <li>Kontaktaufnahme</li>
                    <li>Reservierung / Buchung</li>
                    <li>Location anbieten</li><br/>
                </ul>
                <p><b>2.8</b> Über VENOON können Location-Suchende un-/verbindliche Reservierungsanfragen an die Location-Anbieter richten (“Anfrage”). VENOON übernimmt keine Haftung, da diese Vereinbarungen allein zwischen dem Location-Suchenden und dem Location-Anbieter erfolgt.</p>
                <p><b>2.9</b> Über VENOON können Location-Suchende verbindliche Anfragen zur Buchung einer Veranstaltung bei dem ausgewählten Location-Anbieter abgeben. Der Location-Anbieter bestätigt die Buchungsanfrage nach Vereinbarung. Eine Buchung ist verbindlich für beide Nutzer - Location Suchende und Location Anbieter, da u.a. eine Vertrags- und Zahlungsvereinbarung bereits getroffen wurde. VENOON übernimmt hierbei keine Haftung, da diese Vereinbarung allein zwischen dem Location-Suchenden und dem Location-Anbieter erfolgt. Eine verbindliche Buchung kann durch VENOON gelöscht werden, wenn beide Nutzer einverstanden sind. Im Einzelfall kann VENOON die Nutzer sperren und gegebenenfalls löschen, wenn es sich um einen Betrugsversuch oder eine nicht-seriöse Buchungsanfrage handelt.</p>
                <p><b>2.10</b>  Der Location-Anbieter ist selbst verantwortlich für die Pflege seiner Verfügbarkeiten. Die frei verfügbaren Tage können durch den Location-Anbieter eingetragen werden. Der Location-Anbieter ist hierbei nicht verpflichtet, seine Verfügbarkeiten für eine Location einzutragen. Sofern der Location-Anbieter seine Verfügbarkeiten einträgt, kann der Location-Suchende diese innerhalb der VENOON App sehen und ein freies Datum auswählen, um eine Location zu reservieren. Die Vorrausplanungszeit kann frei von dem Location-Anbieter selbst festgelegt werden, damit die Location für den Anlass bzw. das Event durch den Location-Anbieter vorbereitet / organisiert wird. Eine Vorrausplanungszeit zeigt ebenfalls an, wie lange eine Reservierungsanfrage gültig ist. Sollte der Location-Anbieter die Vorrausplanungszeit für eine Location nicht festgelegt haben, dann wird durch die Anwendung zwei Tage vorgegeben.</p>
                <p><b>2.11</b> VENOON erhebt keine Gebühren für Nutzer als Location Suchende. Die Nutzer als Location Anbieter erhalten nach ihrer Erstregistrierung ein kostenfreies (Gratis-)Abonnement mit einer Laufzeit von 12 Monaten. Es ist zu beachten, dass der Location-Anbieter mit einem kostenfreien Abonnement nur eine Location zur gleichen Zeit anbieten kann. Sofern der Location-Anbieter mehr als eine Location anbieten möchte, muss er ein gebührenpflichtiges Abonnement in Anspruch nehmen. </p>
                <p><b>2.12</b> Wenn der Location-Anbieter nach Ablauf des Gratis-Abonnement kein weiteres Abonnement abschließt, kann er die VENOON App sowie Webseite kostenlos nutzen mit eingeschränkten Funktionen (Keine Location erstellen, keine Buchung / Reservierung, keine Verfügbarkeiten verwalten).</p>
                <p><b>2.13</b> Ein Kunde kann als Location-Anbieter jederzeit für die Nutzung der VENOON Services ein Abonnement abschließen. Für den Abschluss eines Abonnement-Vertrags zwischen dem Kunden und VENOON stehen dem Kunden die nachfolgenden zwei Optionen zur Verfügung:</p>
                <ul className="agblist">
                    <li>Basis-Paket für 4,99 Euro / Monat mit einer Vertragslaufzeit von 12 Monaten. Die Leistungsabrechnung erfolgt einmal pro Jahr (59,88 Euro zzgl. MwSt.).
                        <br/>Basis-Paket beinhaltet folgende Leistungen:
                        <br/>Mit diesem Abonnement kannst du eine Location bei VENOON anbieten und die Verfügbarkeiten für die nächsten 12 Monate eintragen.</li>
                    <li>Premium-Paket für 9,99 Euro / Monat mit einer Vertragslaufzeit von 12 Monaten. Die Leistungsabrechnung erfolgt einmal pro Jahr (119,88 Euro zzgl. MwSt.).
                        <br/>Premium-Paket beinhaltet folgende Leistungen:
                        <br/>Mit diesem Abonnement kannst du unbegrenzt viele Locations bei VENOON anbieten und die Verfügbarkeiten für die nächsten 12 Monate eintragen.</li><br/>
                </ul>
                <p>Es gilt die zum Zeitpunkt des Vertragsschlusses zum jeweiligen Abonnement ausgewiesene Preisliste. Die jeweils geltende gesetzliche Mehrwertsteuer wird zuzüglich berechnet. Etwaig anfallende Verbindungskosten zum jeweiligen Internet- bzw. Mobilfunkanbieter des Abonnenten sind nicht enthalten. Das Abonnement wird keinesfalls nach Ablauf der Vertragslaufzeit automatisch verlängert.</p>
                <p><b>2.14</b> Zur Bezahlung kann der Kunde (Location Anbieter) zwischen Zahlungsüberweisung via Rechnung oder über dritte Zahlungsdienstleister /  angebotene Bezahlsystemen bzw. “Kreditkartenabbuchung via Stripe” wählen. Der Kunde erklärt sich mit der Nutzung der jeweils durch VENOON verwendeten Zahlungsdienstleister (derzeit: Stripe Inc.) unter Geltung der Stripe-Nutzungsbedingungen, einsehbar unter <a href="https://stripe.com/de/legal" target="_blank">https://stripe.com/de/legal</a> einverstanden. Die Abonnementgebühren zzgl. Mehrwertsteuer (MwSt.) sind im Voraus zu entrichten. Mit der Kaufbestätigung bzw. dem Abschluss eines Abonnements wird der jeweilige Abonnementpreis fällig und a) ist auf das in der Rechnung aufgeführte Konto zu überweisen bzw. b) wird von der angegebenen Kreditkarte direkt abgebucht. Bei Überweisung via Rechnung sieht VENOON für Zahlungen im Normalfall eine Frist von 30 Tagen vor. Dann tritt der Verzug ein.</p>
                <p><b>2.15</b>  Das Abonnement kann mindestens um ein weiteres Jahr verlängert werden, wenn das laufende bzw. letzte Abonnement bereits bezahlt wurde. Die Laufzeit des Abonnements kann beliebig um mehrere Jahre verlängert werden</p>
                <p><b>2.16</b> Bei Zahlungsverzug ist VENOON berechtigt, nach Mahnung und Nachfristsetzung den Abonnementvertrag fristlos zu kündigen. Der Location-Anbieter kann die VENOON App und Webseite nur noch eingeschränkt nutzen (u.a. keine Location erstellen, keine Buchung / Reservierung, keine Verfügbarkeiten verwalten etc.).Der Verzögerungsschaden einschließlich der Mahnungskosten geht zu Lasten des Kunden</p>
                <p><b>2.17</b> Der Kunde hat das folgende gesetzliche Widerrufsrecht im Falle von Abonnements. Der Kunde hat das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem ein Abonnement-Vertrag abgeschlossen wurde. Um das Widerrufsrecht auszuüben, muss der Kunde an VENOON GmbH, Krumme Straße 32, 45665 Recklinghausen oder per E-Mail an: <a href="mailto:support@venoon.com">support@venoon.com</a> mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail mit Angabe der Abo-ID, UserID) über den Entschluss, diesen Vertrag zu widerrufen, informieren. Andernfalls kann der Kunde dafür das Widerrufsformular in der VENOON-App “Abo stornieren" verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass der Kunde die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absendet. Für weitergehende Fragen zur Ausübung der Widerrufrechts erreicht der Kunde unser Support-Team unter der E-Mail: <a href="mailto:support@venoon.com">support@venoon.com</a>.</p>
                <p><b>2.18</b> Wenn der Kunde diesen Vertrag widerruft, haben wir ihm alle Zahlungen, die wir erhalten haben, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über den Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das der Kunde bei der ursprünglichen Transaktion eingesetzt hat, es sei denn, mit dem Kunden wurde ausdrücklich etwas Anderes vereinbart; in keinem Fall werden dem Kunden wegen dieser Rückzahlung Entgelte berechnet.</p>
                <p><b>Muster:</b> Widerrufsformular für den Verbraucher. Es handelt sich hier um den gesetzlichen Mustertext, der sich als Anlage 2 zu Art. 246a § 1 Abs. 2 S. 1 Nr. 1 EGBGB findet:</p>
                <p>Widerrufsformular</p>
                <p>(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</p>
                <ul className="agblist">
                    <li>VENOON GmbH, Krumme Straße 32, 45665 Recklinghausen oder per E-Mail an: support@venoon.com</li>
                    <li>Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistung (*)</li>
                    <li>Kunden- und/oder Auftragsnummer- Bestellt am (*)/erhalten am (*)</li>
                    <li>Name des/der Verbraucher(s)</li>
                    <li>Anschrift des/der Verbraucher(s)</li>
                    <li>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</li>
                    <li>Datum</li>
                    <li>Angabe Kontonummer (IBAN) für die Rücküberweisung</li>
                </ul>
                <p>(*) Unzutreffendes streichen.</p>
                <p><b>2.19</b> Nach Ablauf des gesetzlichen Widerrufsrechts kann der Kunde seine Mitgliedschaft bzw. sein Abonnement nicht mehr stornieren, da eine verbindliche Vertragslaufzeit von 12 Monaten bindend ist. Es sei denn, mit dem Kunden wurde ausdrücklich etwas Anderes vereinbart. Das Abonnement wird nach Ablauf von 12 Monaten nicht automatisch verlängert. </p>
                <p><b>2.20</b>  VENOON bleibt das Recht vorbehalten, die angebotenen Dienste und Inhalte auf Wunsch des Kunden zu erweitern oder zu ändern, so dass die Daten korrigiert und angepasst werden. (z.B. Status der Reservierung ändern, Buchungen vornehmen, Location löschen bzw. auf aktiv / Inaktiv setzen, Abonnenten / Rechnungen verwalten sowie Userdaten anpassen).</p>
                <p><b>2.21</b> VENOON prüft und verwaltet eingehende Anfragen der Nutzer (z. B. Probleme, Fragen oder Feedback) per E-Mail oder über ein Formular in der App / Webseite. VENOON orientiert sich dabei insbesondere an den Wünschen der Nutzer in den Anfragen. VENOON behält sich das Recht vor, eine schnelle und zuverlässige Bearbeitung von Anfragen zu gewährleisten.</p>
                <h5 className="heading2">3. Abschluss von Event-Verträgen zwischen dem Location-Suchenden und dem Location-Anbieter</h5>
                <p><b>3.1</b> Der Vertragsschluss über die Durchführung der Veranstaltung erfolgt direkt mit dem jeweiligen Location-Anbieter (der „Event-Vertrag“). Dies erfolgt allein zwischen dem Location-Suchenden und dem Location-Anbieter. VENOON ist nicht Partei des Vertrages und übernimmt keine Haftung der abgeschlossenen Verträge zwischen Location-Suchenden und Location-Anbieter.</p>
                
                <h5 className="heading2">4. Richtigkeit dargestellter Informationen, Urheberrecht</h5>
                <p><b>4.1</b> VENOON stellt Informationen zu verschiedenen Location-Anbietern kostenlos zur Verfügung. Die auf dem Portal dargestellten Informationen dienen lediglich der Entscheidungsfindung des Location-Suchenden. Die angezeigte Reihenfolge der Anbieter-Profile auf dem Portal oder durch die Beratung lässt keinen Rückschluss über die Qualität des Location-Anbieters zu. VENOON ist nicht verantwortlich für Einschätzung/Echtheit der Werthaltigkeit des Location-Anbieters seitens des Location-Suchenden.</p>
                <p><b>4.2</b> Trotz der Bemühungen von VENOON, den Internetauftritt aktuell, informativ und korrekt zu halten, übernimmt VENOON keinerlei Gewährleistung oder Haftung für die Richtigkeit, Aktualität und Vollständigkeit des dargestellten Inhalts. Insbesondere übernimmt VENOON ausdrücklich keine Verantwortung für Inhalte (Texte, Bilder, Datenblätter, usw.), die der Location-Anbieter VENOON für die Erstellung der einzelnen Anbieter-Profile zur Verfügung stellt.</p>
                <p><b>4.3</b> Die Informationen auf dem Portal dienen der allgemeinen Auskunft und stellen keine rechtliche oder steuerliche Beratung dar. VENOON haftet zudem nicht für Pflichtverletzungen der Nutzer oder sonstiger Dritter.</p>
                <p><b>4.4</b> Jegliche Informationen über Location-Anbieter auf unserem Portal dienen der Erleichterung einer selbständigen Entscheidung des Location-Suchenden für einen oder mehrere Location-Anbieter, auf die VENOON keinen Einfluss hat. Keine der dargestellten Informationen ist als Zusage einer Garantie oder Zusicherung zu verstehen.</p>
                <p><b>4.5</b> Verlinkte Seiten werden von VENOON sorgsam nach Bedarf geprüft. VENOON grenzt sich ausdrücklich von allen Inhalten fremder Internet-Seiten ab und übernimmt keinerlei Haftung in Bezug auf solche Seiten und ggf. entstehende Schäden in Verbindung mit der Nutzung solcher Links.</p>
                <p><b>4.6</b> Bei Einstellung oder Unterbrechung der Website wird jede Haftung für ggf. entstehende Kosten, Schäden oder Verluste ausgeschlossen, insbesondere zum Beispiel wegen notwendiger Wartungsarbeiten oder aufgrund von höherer Gewalt oder technischer Störungen des Internet. VENOON strebt an, das Portal den Nutzern sowie der Öffentlichkeit mit einer Verfügbarkeit von 90 % im Jahresdurchschnitt zur Verfügung zu stellen. Ausfallzeiten, die zur Verbesserung, Neugestaltung und/oder Optimierung des VENOON Portals genutzt werden (z.B. Softwareupdates, Erhöhung der Sicherheit, Neugestaltung oder Optimierung der Inhalte und Darstellung) und technische Maßnahmen (z.B. Serverwartungen) werden im vorgenannten Jahresdurchschnitt nicht berücksichtigt.</p>
                <p><b>4.7</b> Es besteht kein Anspruch eines Nutzers gegenüber VENOON, dass unser Portal dauerhaft und/oder unverändert zur Verfügung steht. Änderungen sind jederzeit möglich.</p>
                <p><b>4.8</b> Sämtliche Daten und Informationen, insbesondere Bilder, Illustrationen, Videoclips und Beschreibungen sind durch Urheberrechte, Kennzeichen und andere Schutzrechte bezüglich des geistigen Eigentums geschützt. Die Daten und Informationen dürfen weder kopiert, noch vervielfältigt, neu veröffentlicht, heraufgeladen, versendet, übertragen oder in sonstiger Weise, einschließlich E-Mail und anderer elektronischer Mittel, verbreitet, und/oder der Öffentlichkeit zugänglich gemacht werden, es sei denn, dass es VENOON ausdrücklich erlaubt. Ohne die vorherige schriftliche Zustimmung von VENOON ist weder eine Veränderung der Daten und Informationen noch deren Nutzung auf anderen Websites bzw. auf durch Netzwerke verbundenen Computerumgebungen sowie deren Nutzung für andere als persönliche, nichtgewerbliche Zwecke, ein Verstoß gegen Urheberrechte, Kennzeichen und andere Schutzrechte bezüglich geistigen Eigentums und somit verboten. Im Übrigen ist es ohne Zustimmung von VENOON nicht gestattet, die Internetseite im Ganzen oder einzelne Teile davon herunterzuladen, zu vervielfältigen und zu verbreiten. Jede Nutzung der Inhalte für gewerbliche Zwecke ohne Genehmigung durch VENOON ist untersagt. Die Weitergabe von Inhalten an Dritte ist ohne Zustimmung von VENOON nicht gestattet. Die Einrichtung eines Hyperlinks auf dem Portal ist untersagt, wenn die Seite hinter diesem Link sittlich anstößige, gewaltverherrlichende, rassistische oder strafrechtlich relevante Informationen enthält.</p>
                
                <h5 className="heading2">5. Haftung</h5>
                <p><b>5.1</b> Bei Vorsatz haftet VENOON entsprechend den gesetzlichen Regelungen.</p>
                <p><b>5.2</b> Bei grober Fahrlässigkeit haftet VENOON entsprechend den gesetzlichen Regelungen.</p>
                <p><b>5.3</b> Bei einfacher Fahrlässigkeit haftet VENOON – vorbehaltlich eines milderen Haftungsmaßstabs nach gesetzlichen Vorschriften (z. B. für Sorgfalt in eigenen Angelegenheiten) – nur für Schäden aus der Verletzung einer wesentlichen Vertragspflicht (d.h. einer Verpflichtung, deren Erfüllung die ordnungsgemäße Durchführung dieses Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertraut und vertrauen darf); in diesem Fall ist die Haftung von VENOON jedoch auf den Ersatz des vorhersehbaren, typischerweise eintretenden Schadens begrenzt. Mittelbare Schäden und Folgeschäden, die Folge von Mängeln des Liefergegenstands bzw. des Werkes sind, sind dementsprechend nur ersatzfähig, soweit solche Schäden bei bestimmungsgemäßer Verwendung des Liefergegenstands bzw. Werkes typisch und vorhersehbar sind.</p>
                <p className="mb-1"><b>5.4</b> Die Beschränkungen aus Ziffer 5.3 gelten nicht.</p>
                <ul className="agblist">
                    <li>für Schäden aus der Verletzung von Körper, Leben oder Gesundheit;</li>
                    <li>für eine Haftung nach dem Produkthaftungsgesetz;</li>
                    <li>soweit VENOON einen Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Ware bzw. des Werkes übernommen hat.</li><br/>
                </ul>
                <p><b>5.5</b> Die sich aus diesem Abschnitt ergebenden Haftungsbeschränkungen gelten (i) hinsichtlich aller Schadensersatzansprüche gleich aus welchem Rechtsgrund, insbesondere aus Unmöglichkeit, Verzug, mangelhafter oder falscher Lieferung, Vertragsverletzung, Verletzung von Pflichten bei Vertragsverhandlungen und unerlaubter Handlung, soweit es dabei jeweils auf ein Verschulden ankommt, und (ii) auch bei Pflichtverletzungen durch Erfüllungsgehilfen und sonstige Personen, deren Verschulden VENOON nach gesetzlichen Vorschriften zu vertreten hat.</p>
                
                <h5 className="heading2">6. Pflichten des Nutzers </h5>
                <p><b>6.1</b> Dem Nutzer ist es untersagt, bei der Inanspruchnahme der Dienste von VENOON, die Rechte Dritter zu verletzen oder Dritte sonst zu belästigen, ebenso der Missbrauch oder die Vornahme rechtswidriger Handlungen. Der Nutzer ist verpflichtet, die Nutzung der Dienste von VENOON mit der größtmöglichen Sorgfalt durchzuführen. Der Nutzer hat jegliche Handlungen zu unterlassen, die geeignet sind, die Serverintegrität oder -stabilität von VENOON unmittelbar oder mittelbar zu beeinträchtigen.</p>
                <p><b>6.2</b> Die Eingabe und Übermittlung der persönlichen Daten durch den Nutzer erfolgt online über die von VENOON vorbereiteten Eingabemasken, per E-Mail, Telefon oder über andere ausdrücklich von VENOON im Einzelfall vorgesehene Übermittlungswege. Für die Vollständigkeit und Richtigkeit sämtlicher vom Nutzer eingegebenen oder sonst übermittelten Daten ist ausschließlich der Nutzer selbst verantwortlich. Für den Fall der Übermittlung von vorsätzlich falschen oder unwahren persönlichen Daten durch den Nutzer behält sich VENOON vor, ihn von der Inanspruchnahme der angebotenen, bzw. zur Verfügung gestellten Leistungen auszuschließen und Ersatz für dadurch eingetretene Schäden zu verlangen.</p>
                <p><b>6.3</b> Persönliche Zugangsdaten (wie Kennwort/Passwort) dürfen nicht an Dritte weitergegeben werden und sind vor dem Zugriff durch Dritte geschützt aufzubewahren. Sie müssen zur Sicherheit vor der ersten Inbetriebnahme sowie sodann in regelmäßigen Abständen geändert werden. Soweit Anlass zu der Vermutung besteht, dass unberechtigte Personen von den Zugangsdaten Kenntnis erlangt haben, hat der Nutzer diese unverzüglich zu melden und zu ändern. </p>
                
                <h5 className="heading2">7. Registrierung eines Benutzerkontos</h5>
                <p><b>7.1</b> Für die vollständige Nutzung der Dienste von VENOON ist eine Registrierung als Nutzer erforderlich. Es sind zwei Benutzerkonten (Location-Suchende, Location-Anbieter) mit unterschiedlichen Pflichten und Rollen. Ein Anspruch auf die Registrierung eines Benutzerkontos besteht nicht. Ein Benutzerkonto ist nicht übertragbar. Registrieren darf sich zudem nur, wer das 18. Lebensjahr vollendet hat.</p>
                <p><b>7.2</b> Die von VENOON bei der Registrierung abgefragten Daten sind vollständig und nach bestem Wissen und Gewissen vom Nutzer anzugeben und aktuell zu halten. Nutzer haften für sämtliche Aktivitäten, die unter Verwendung ihres Benutzerkontos vorgenommen werden.</p>
                <p><b>7.3</b> VENOON behält sich das Recht vor, Benutzerkonten jederzeit zu sperren oder zu löschen.</p>
                <p><b>7.4</b> Mit einem Gastkonto (Nicht registrierter Benutzer) kann ein Benutzer die Dienste von VENOON nur eingeschränkt nutzen (bspw. Location suchen, VENOON Webseite besuchen etc.). Weitere Dienste ( u.a. Location-Anbieter kontaktieren, Location reservieren, Favoritenliste) zu nutzen ist nur nach einer Registrierung möglich.</p>

                <h5 className="heading2">8. Bestimmungen für die Nutzung des Emailservices</h5>
                <p><b>8.1</b> Sofern nichts anderes festgelegt wird, werden alle im Rahmen der Nutzung der Dienstleistungen von VENOON erforderlichen Mitteilungen und sonstigen Nachrichten an Nutzer sowie zwischen Nutzern untereinander elektronisch übermittelt.</p>
                <p><b>8.2</b> VENOON duldet keinen Missbrauch der angebotenen Dienstleistungen. Der Emailservice von VENOON darf nicht für den Versand oder das Weiterleiten von Spam oder anderen Inhalten, die gegen unsere AGB verstoßen, verwendet werden. Es ist nicht erlaubt, andere Nutzer zu eigenen E-Mail-Verteilerlisten hinzuzufügen oder diese zu gewerblichen Zwecken anzurufen oder anderweitig zu kontaktieren, sofern der Nutzer nicht seine ausdrückliche Einwilligung dazu gegeben hat.</p>
                <p><b>8.3</b> VENOON scannen über unseren Emailservice versendete Nachrichten automatisch mit entsprechenden Analyse-Tools, um sie auf Spam, Viren, Phishing-Angriffe sowie andere böswillige Aktivitäten und rechtswidrige oder verbotene Inhalte zu prüfen. Im Einzelfall geschieht dies auch durch manuelle Verfahren. Im Falle eines Verstoßes gegen die Nutzungsbedingungen oder gesetzliche Vorschriften, behält VENOON sich vor, die Weiterleitung einer Nachricht und/oder eines unrechtmäßigen Inhalts zu blockieren.</p>
                
                <h5 className="heading2">9. Datenschutz</h5>
                <p><b>9.1</b> VENOON verarbeitet personenbezogene Daten der Nutzer zur Erbringung der vertraglich vereinbarten Leistungen im Rahmen dieser AGB. VENOON erhebt, verarbeitet und nutzt diese personenbezogenen Daten, soweit sie für die Vertragsbegründung und -abwicklung sowie zu Abrechnungszwecken erforderlich sind. Die Rechtsgrundlage für die Verarbeitung von Daten zu diesen Zwecken findet sich in Art. 6 Abs. 1 S.1 lit. (b) DSGVO.</p>
                <p><b>9.2</b> Ergänzend zu diesen AGB gelten die Regelungen unserer Datenschutzerklärung, die online einsehbar sind unter (<NavLink>www.venoon.com/datenschutz/</NavLink>).</p>
                
                <h5 className="heading2">10. Sonstige Regelungen</h5>
                <p><b>10.1</b> VENOON behält sich vor, unwesentliche Regelungen dieser AGB ohne Nennung von Gründen zu ändern. Die geänderten Regelungen werden den Benutzern per E-Mail spätestens zwei Wochen vor ihrem Inkrafttreten zugesandt oder auf dem Portal veröffentlicht. Widerspricht ein Nutzer der Geltung der geänderten AGB nicht innerhalb von zwei Wochen, gelten die geänderten AGB als angenommen.</p>
                <p><b>10.2</b> VENOON ist berechtigt die Daten vom Location-Anbieter u.a. Profile, Medien (Bilder / Videos) sowie die Anbieter-Namen im Rahmen von PR- und Werbemaßnahmen Offline sowie Online für das Portal VENOON, seine zugehörigen Seiten und Kooperationen zu verwenden. VENOON kann die Versanddaten von Newslettern jederzeit ändern.</p>
                
                <h5 className="heading2">11. Schlussbestimmungen</h5>
                <p><b>11.1</b> Diese AGB sind abschließend für die Regelung der rechtlichen Beziehungen zwischen VENOON und dem Nutzer. Sämtliche Abweichungen von diesen AGB bedürfen einer ausdrücklichen schriftlichen Bestätigung durch VENOON.</p>
                <p><b>11.2</b> Sollte eine Bestimmung dieser Allgemeinen Geschäftsbedingungen oder ein Teil einer solchen Bestimmung unwirksam sein oder werden, berührt dies nicht die Wirksamkeit dieser Allgemeinen Geschäftsbedingungen im Ganzen. Anstelle der unwirksamen Bestimmung gelten die einschlägigen gesetzlichen Vorschriften.</p>
                <p><b>11.3</b> Die vertraglichen Beziehungen der Parteien richten sich nach dem Recht der Bundesrepublik Deutschland. Gerichtsstand ist – soweit zulässig – Recklinghausen.</p>

                <p>Stand: 15.10.2023</p>
                </div>
            </div>
        </div>
    </div>
</div>

<Footer/>
</div>
  )
}

export default Agb