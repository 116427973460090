import Cookies from 'js-cookie'
export const getItemCookies=(key)=>{
const item=Cookies.get(key);
return item;
}
export const setItemCookies=(key,value)=>{
    Cookies.set(key,value);
}
export const getJsonObjCookies =(key)=>{
    const obj= Cookies.get(key)
    return (obj && obj !=="undefined") ? JSON.parse(obj) :null
}
export const clearCookies=(key)=>{
    Cookies.remove(key);
}

