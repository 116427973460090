import React, { useState } from 'react'
import { Tab, Row, Nav } from 'react-bootstrap'
import CategoryPrice from './CategoryPrice'
import DeineLocation from './DeineLocation'
import Details from './Details'
import FotoVideo from './FotoVideo'
import MeinKalender from './MeinKalender'
import "./CreateForm.css"


const AllLocation = () => {
  const [key, setKey] = useState('first')
  const [locationId, setLocationId] = useState(0);
  const [tabVerify, setTabVerify] = useState(false);
  const [pricedata, setPriceData] = useState({})

  const onSelect = (tab) => {
    setKey(tab)
  }
const TabClick=(key)=>{
  setTabVerify(key)
}
 

  return (
    <React.Fragment>
      <div className='main_container'>
        <div className="heading_div"><h3 className="main_heading">Location erstellen</h3></div>
        <Tab.Container mountOnEnter id="left-tabs-example" activeKey={key} onSelect={onSelect} defaultActiveKey="first">
          
            <Tab.Content>
              <Tab.Pane eventKey="first" >
                <DeineLocation onSelect={onSelect} setLocationId={(id) => setLocationId(id)} onTabClick={TabClick}   />
              </Tab.Pane>
              <Tab.Pane eventKey="second" >
                <FotoVideo onSelect={onSelect} locationId={locationId} onTabClick={TabClick}/>
              </Tab.Pane>
              <Tab.Pane eventKey="third" >
                <CategoryPrice onSelect={onSelect} locationId={locationId} setPriceData={(data) => setPriceData({ data })} onTabClick={TabClick} />
              </Tab.Pane>
              <Tab.Pane eventKey="forth" >
                <Details onSelect={onSelect} locationId={locationId} pricedata={pricedata} onTabClick={TabClick}/>
              </Tab.Pane>
              <Tab.Pane eventKey="fifth" >
                <MeinKalender onSelect={onSelect} locationId={locationId} onTabClick={TabClick}/>
              </Tab.Pane>
            </Tab.Content>
          
          <Row>
            <Nav variant="pills" className="fle">
              <Nav.Item className={tabVerify ? 'pep' : ""}>
                <Nav.Link eventKey="first"></Nav.Link>
              </Nav.Item >
              <Nav.Item className={tabVerify ? 'pep' : ""}>
                <Nav.Link eventKey="second"></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third"></Nav.Link>
              </Nav.Item>
              <Nav.Item > 
                <Nav.Link eventKey="forth"></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fifth"></Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
        </Tab.Container>
      </div>
    </React.Fragment>

  )
}

export default AllLocation