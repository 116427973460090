import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import "./ReplyMessage.css"
import { GetProblemById, ProblemSolutionByAdmin, ProblemStatusByAdmin } from '../../../Services/Allservices/Admin/adminservices';
import moment from 'moment';
import { AiFillCheckCircle } from "react-icons/ai";
import { Button } from 'react-bootstrap';
import Loader from '../../Utills/Loader';
import { msg } from '../../../Services/Axios';
const ReplyMessage = () => {
  const { state } = useLocation()
  const [errors, setErros] = useState({});
  const [loading, setloading] = useState(false)
  const navigate = useNavigate()
  const [solution, setSolution] = useState("")
  const [Status, setStatus] = useState("")
  const [show, setShow] = useState("")
  const [ProblemById, setProblemById] = useState([])
  const { status, id } = state || {}

  const problemSolutionByAdmin1 = async () => {
    try {
      const response = await ProblemStatusByAdmin(id, parseInt(Status) ? parseInt(Status) : status)
      if (response?.status) {
        navigate("/anfragenandincidents")
      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  const problemSolutionByAdmin = async () => {
    if (!validateForm()) {
      return
    }
    try {
      const payload = {
        id: id,
        AdminSolution: solution,
        status: parseInt(Status) ? parseInt(Status) : status
      }

      const response = await ProblemSolutionByAdmin(payload)
      setSolution("")
      setStatus("")
      setProblemById(response?.data?.result)
      if (response?.data?.status) {
        setShow("Antwort wurde erfolgreich übermittelt")

        setTimeout(() => {
          setShow("")
        }, 2000);

      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const getSemester = (date) => {
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
     // Check for specific date conditions
    if (month === 2 && day === 31) { // March 31
        return 2;
    } else if (month === 8 && day === 30) { // September 30
        return 1;
    } 
    
    let iSfirstSem = [3,4,5,6,7,8].includes(month);
    let iSsecondSem = [0,1,2,9,10,11].includes(month); 
    return iSfirstSem ? 2:1;    
    }
    
  const getProblemById = async () => {
    setloading(true)
    try {
      const payload = {
        id: id
      }

      const response = await GetProblemById(payload)
      setProblemById(response?.data?.result)
      setloading(false)
      if (response?.data?.status) {

      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getProblemById()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateForm = () => {
    var isValid = true;
    if (solution.trim() === "") {
      errors.solution = msg.solution
      isValid = false;
    }
    else {
      errors.solution = "";
    }
    setErros({ ...errors, errors });
    return isValid;
  }
  return (
    <React.Fragment>
      {
        loading ? <Loader /> : <>
          <div className="main_container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading_div">
                  <h3 className="main_heading">Anfragen & Incidents</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="support_div">
                  <h3>Problem ID {ProblemById[0]?.id < 10 ? `0${ProblemById[0]?.id}` : ProblemById[0]?.id} – {ProblemById[0]?.fullName}</h3>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul className="id_message">
                        <li>
                          <p><span className="textitem1">User ID&nbsp;&nbsp;:</span><span className="textitem2">{ProblemById[0]?.userId}</span></p>
                        </li>
                        <li>
                          <p><span className="textitem1">Name&nbsp;&nbsp;:</span><span className="textitem2">{ProblemById[0]?.fullName}</span></p>
                        </li>
                        <li>
                          <p><span className="textitem1">Firmenname&nbsp;&nbsp;:</span><span className="textitem2">{ProblemById[0]?.companyName ? ProblemById[0]?.companyName : "-"}</span></p>
                        </li>
                        <li>
                          <p><span className="textitem1">Datum&nbsp;&nbsp;:</span><span className="textitem2">{moment(ProblemById[0]?.createdDate).format('DD.MM.YYYY')}    {moment(ProblemById[0]?.createdDate).add(1,'hours').format('HH:mm:ss')}</span></p>
                        </li>
                        <li>
                          <p><span className="textitem1">Profiltyp&nbsp;&nbsp;:</span><span className="textitem2">{(ProblemById[0]?.userType) === 2 ? " Location-Anbieter" : (ProblemById[0]?.userType === 3) ? " Location-Suchende" : "Administratorin"}</span></p>
                        </li>
                        <li>
                          <p><span className="textitem1">Rufnummer&nbsp;&nbsp;:</span><span className="textitem2">{ProblemById[0]?.phonenumber}</span></p>
                        </li>
                        <li>
                          <p><span className="textitem1">E-Mail&nbsp;&nbsp;:</span><span className="textitem2">{ProblemById[0]?.emailId}</span></p>
                        </li>
                        <li>
                          <p><span className="textitem1">Nachrichtentext&nbsp;&nbsp;:</span><span className="textitem2">{ProblemById[0]?.queryMessage}</span></p>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-4 id_message_status">
                      <p>Status:</p>
                      <form>
                        <div className="form-groupl">
                          <label className="form-control">
                            <input type="radio" id="open" name="Status" value="1" onChange={(e) => setStatus(e.target.value)} defaultChecked={status === 1} />
                            Offen
                          </label>
                        </div>
                        <div className="form-groupl">
                          <label className="form-control">
                            <input type="radio" name="Status" value="2" onChange={(e) => setStatus(e.target.value)} defaultChecked={status === 2} />
                            In Bearbeitung
                          </label>
                        </div>
                        <div className="form-group">
                          <label className="form-control">
                            <input type="radio" name="Status" value="3" onChange={(e) => setStatus(e.target.value)} defaultChecked={status === 3} />
                            Geschlossen
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>
                  <form>
                    <div className="form-group">
                      <textarea className="form-control" placeholder="Bitte schreibe hier deine Nachricht" name="solution" value={solution} onChange={(e) => setSolution(e.target.value)} />
                      {!!errors.solution && <div style={{ color: 'red' }}>{errors.solution}</div>}
                    </div>
                    <NavLink to="/anfragenandincidents" className="theme_btn">Zurück</NavLink>
                    <Button onClick={problemSolutionByAdmin} className="theme_btn">Senden</Button>
                    <Button onClick={problemSolutionByAdmin1} className="theme_btn">Speichern</Button>
                  </form>
                  <div style={{ paddingTop: "20px" }}><p className="msg_show"> {show ? <span><AiFillCheckCircle className='icon_color' style={{ fontSize: "30px" }} /> {show}</span> : ""}</p></div>
                  <hr />
                  <p className='admin_msg_show'>Admin-Nachricht:</p>
                  {
                    ProblemById.slice(1).map((item, index) => (
                      <div className='admin_div'>
                        <div className="d-flex">
                          <p className='admin_msg_data'><span style={{ fontWeight: "bold" }}>({index + 1})</span>   {item?.adminSolution},</p>
                          <p className='admin_msg_datetime'>{moment(item?.loginTimeFormatted).format("DD.MM.YYYY")}  {moment(item?.loginTimeFormatted).add(getSemester(item?.loginTimeFormatted),'hours').format("HH:mm:ss")}</p>
                        </div>                        
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default ReplyMessage