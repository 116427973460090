import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { msg } from '../../Services/Axios'
import { ChangePassword } from '../../Services/LoginAccount/accountservices'
import "./ChangePassword.css"
import { AiFillEye, AiFillEyeInvisible, AiFillCheckCircle } from "react-icons/ai"
import { getJsonObjCookies } from '../Utills/Utills'
const Changepassword = () => {
  const [OldPassword, setOldPassword] = useState("")
  const [NewPassword, setNewPassword] = useState("")
  const [ConfirmPassword, setConfirmPassword] = useState("")
  const [errors, setErros] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const [show, setShow] = useState("")
  const [show1, setShow1] = useState("")
  const handleSubmit = async () => {
    if (!validateForm()) {
      return
    }
    try {
      const payload = {
        "OldPassword": OldPassword,
        "NewPassword": NewPassword,
        "ConfirmPassword": ConfirmPassword
      }
      const response = await ChangePassword(payload)
      if (response?.data?.result?.transactionMessage?.success) {
        setShow(response?.data?.message)
        setTimeout(() => {
          setShow("")
        }, 2000);
      }
      else {
        setShow1(response?.data?.message)
        setTimeout(() => {
          setShow1("")
        }, 2000);
        console.log(response?.data?.result?.transactionMessage?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  const validateForm = () => {
// @$!%*#?&+<>=_-
    var isValid = true;
    var passwordValid =  NewPassword.match(/^((?=.*[A-ZÄÖÜ])(?=.*[a-zäöüß])(?=.*[-/:;()&@.,?!'$"[\]{}\\#%^*+=_|~<>])(?=.*\d)).{8,24}$/,)
    var passwordValid1 = NewPassword.match(/^((?=.*[A-ZÄÖÜ])(?=.*[a-zäöüß])(?=.*[-/:;()&@.,?!'$"[\]{}\\#%^*+=_|~<>])(?=.*\d)).{10,24}$/,)
    var passwordValid2 = NewPassword.match(/^(?!.*(.)\1\1.*).*$/)
    if (OldPassword.trim() === "") {
      errors.oldpassword = msg.oldpassword
      isValid = false;
    }
    else {
      errors.oldpassword = "";
    }
    if (NewPassword.trim() === "") {
      errors.newpassword = msg.newpassword
      isValid = false;
    }
    else if (getJsonObjCookies("user_Role") === 1) {
      if (!(passwordValid1 && passwordValid2)) {
        errors.newpassword = msg.passwordpatterncheck;
        isValid = false;
      }
      else {
        errors.newpassword = "";
      }
    }
    else if (getJsonObjCookies("user_Role") === 2) {
      if (!(passwordValid && passwordValid2)) {
        errors.newpassword = msg.passwordpatterncheck;
        isValid = false;
      }
      else {
        errors.newpassword = "";
      }

    }
    else {
      errors.newpassword = "";
    }
    if (ConfirmPassword.trim() === "") {
      errors.confirmpassword = msg.confirmpassword
      isValid = false;
    }
    else if (NewPassword !== ConfirmPassword) {
      errors.confirmpassword = msg.samepassword
      isValid = false;
    }
    else {
      errors.confirmpassword = "";
    }
    setErros({ ...errors, errors });
    return isValid;
  }
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return
    }
    setPasswordType("password");
  };


  return (
    <div className="main_container">
      <div className="row">
        <div className="col-sm-12">
          <div className="heading_div">
            <h3 className="main_heading">Passwort ändern</h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="subscriptview">

            <div className="form-group position-relative">
              <input type={passwordType} name="OldPassword" value={OldPassword} onChange={(e) => setOldPassword(e.target.value)} className="form-control" placeholder="Altes Passwort*" required />
              {!!errors.oldpassword && <div style={{ color: 'red' }}>{errors.oldpassword}</div>}

              <button className="showhidebtn" onClick={togglePassword}>
                {passwordType === "password" ? (
                  <span><AiFillEyeInvisible /></span>

                ) : (
                  <span><AiFillEye /></span>
                )}
              </button>

            </div>

            <div className="form-group position-relative">
              <input name="NewPassword" type={passwordType} value={NewPassword} onChange={(e) => setNewPassword(e.target.value)} className="form-control" placeholder="Neues Passwort*" required />

              {!!errors.newpassword && <div style={{ color: 'red' }}>{errors.newpassword}</div>}
              <button className="showhidebtn" onClick={togglePassword}>
                {passwordType === "password" ? (
                  <span><AiFillEyeInvisible /></span>

                ) : (
                  <span><AiFillEye /></span>
                )}
              </button>
            </div>

            <div className="form-group position-relative">
              <input name="ConfirmPassword" value={ConfirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type={passwordType} className="form-control" placeholder="Neues Passwort bestätigen*" required />

              {!!errors.confirmpassword && <div style={{ color: 'red' }}>{errors.confirmpassword}</div>}
              <button className="showhidebtn" onClick={togglePassword}>
                {passwordType === "password" ? (
                  <span><AiFillEyeInvisible /></span>

                ) : (
                  <span><AiFillEye /></span>
                )}
              </button>

            </div>

            <Button onClick={handleSubmit} className="theme_btn">Passwort ändern</Button>
            <div style={{ paddingTop: '20px', color: '#7E2845' }}>{show ? <span><AiFillCheckCircle className='icon_color' style={{ fontSize: "30px" }} /> {show} </span> : ""}</div>
            <div style={{ color: 'red' }}>{show1 ? <span> {show1} </span> : ""}</div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <h4 className="main_heading">Kennwortrichtlinie</h4>
            </div>
            <div className="col-sm-12">
              <ol>
                <li>1. Passwörter dürfen nicht mehr als zwei sich wiederholende Zeichen enthalten</li>
                <li>2. Benutzer dürfen nicht zuvor bereits genutzte Passwörter erneut verwenden</li>
                {
                  getJsonObjCookies("user_Role") === 1 ? <li>3.  Das Passwort bei VENOON Member hat eine Minimallänge von 10 Zeichen</li> : <li>3. Das Passwort bei VENOON hat eine Minimallänge von 8 Zeichen</li>
                }
                <li>4. Das Konto eines Benutzers wird gesperrt nach fünf Falsch-Eingaben.{getJsonObjCookies("user_Role") === 1 ? "" : "Der Benutzer kann sein Passwort zurücksetzen bei Klicken „Passwort vergessen“"} </li>
                <li>5. Als Passwort wird jenes bezeichnet, dass aus den vier folgenden Zeichentypen besteht:
                  <ol>
                    <li>&nbsp;&nbsp;a. Großbuchstaben</li>
                    <li>&nbsp;&nbsp;b. Kleinbuchstaben</li>
                    <li>&nbsp;&nbsp;c. Zahlen</li>
                    <li>&nbsp;&nbsp;d. Sonderzeichen</li>
                  </ol>
                </li>

              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Changepassword