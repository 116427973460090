import { axiosInstance } from "../Axios";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    async get(endpoint, params) {
        const response = await axiosInstance.get(`${endpoint}`, { params });
        return response;
    },

    async post(endpoint, data) {
        const response = await axiosInstance.post(`${endpoint}`, data)
        return response;
    },

}