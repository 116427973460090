import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './InvoiceUpload.css';
import { Modal } from 'react-bootstrap';
import 'react-nice-dates/build/style.css'
import moment from 'moment';
import bin from '../../../Assests/dashboard/images/bin.svg';
import add from '../../../Assests/dashboard/images/add.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { InvoiceClick, InvoicePaymentDelete, SaveInvoiceDocument } from '../../../Services/Allservices/Admin/adminservices';
import Loader from '../../Utills/Loader';
import { setItemCookies } from '../../Utills/Utills';

const InvoiceUpload = () => {
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const { state } = useLocation()
  const [errors, setErros] = useState({});
  const [datafile, setdatafile] = useState()
  const [filedata, setfiledata] = useState({})
  const [invoiceid, setinvoiceid] = useState()
  const [invoiceDataById, setInvoiceDataById] = useState([])
  const [loading, setloading] = useState(false)
  const [invoicedelete, setinvoicedelete] = useState()
  useEffect(() => {
    setinvoiceid(state)

    uploadStatus()
    setItemCookies("invoicelength", invoiceDataById.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceid])
  const handleSelectFile = async (e) => {
    let file = e.target.files[0];
    var formdata = new FormData();
    formdata.append("File[0]", `VENOON-RG-${invoiceid}`);
    formdata.append("File", file);
    formdata.append("IsDelete", false);
    formdata.append("InvoiceId", invoiceid);
    formdata.append("DocumentPath", "https://venoon.com/API/DataContainer/InvoiceData/")
    setfiledata(formdata)
    setdatafile(file)

  };


  const uploadStatus = async () => {
    setloading(true)
    const response = await InvoiceClick(invoiceid)
    if (response?.status) {
      setloading(false)
      setInvoiceDataById(response?.data?.invoiceCheck)

    }
    else {
      setloading(false)
      console.log(response?.data?.message)

    }
  }
  const ValidataeForm = () => {
    const filevalidate = datafile.type.match("application/pdf")
    var isValid = true
    if (!filevalidate) {
      errors.file = "Bitte nur Datei in PDF-Format hochladen"
      isValid = false;
    }
    else {
      errors.file = "";
    }
    setErros({ ...errors, errors });
    return isValid;
  }

  const handleSubmit = async () => {
    if (!ValidataeForm()) {
      return
    }
    try {
      const response = await SaveInvoiceDocument(filedata)

      if (response.status) {
        uploadStatus()
        setShow(false)
      }
    }
    catch (err) {
      console.log(err)
    }

  };
  const tabledelete = async (indexid) => {
    try {
      const response = await InvoicePaymentDelete(invoiceid)

      if (response?.data?.status) {
        let temp = [...invoiceDataById]
        temp.splice(indexid, 1)
        setInvoiceDataById(temp)
      }
    }
    catch (err) {
      console.log(err)
    }
  }


  return (
    <React.Fragment>
      {
        loading ? <Loader /> : <>
          <div className="main_container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading_div">
                  <h3 className="main_heading">Rechnung hochladen</h3>
                  <button className="add_feed" onClick={() => setShow(true)} disabled={invoiceDataById.length === 1}><img src={add} alt="img" /></button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="table-responsive new_table">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>RechnungsNr.</th>
                        <th>Abo-Start</th>
                        <th>Abo-Ende</th>
                        <th>Abo</th>
                        <th>Betrag</th>
                        <th>Aktion</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        invoiceDataById.map((item, index) => (
                          <tr key={item?.id}>
                            <td>{item?.id}</td>
                            <td>{item?.isDeleted ? "-" : moment(item?.subscriptionStart).format("DD.MM.YYYY")}</td>
                            <td>{item?.isDeleted ? "-" : moment(item?.subscriptionEnd).format("DD.MM.YYYY")}</td>
                            <td>{item?.isDeleted ? "Kein Abo" : item?.subscriptiontype}</td>
                            <td>{item?.isDeleted ? "-" : item?.amount}</td>
                            <td><NavLink onClick={() => { setShow1(true); setinvoicedelete(index) }} ><img src={bin} alt="img" /></NavLink></td>
                          </tr>

                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


            <Modal className="new_Pop" show={show1} onHide={() => setShow1(false)} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Body className="modalbg">
                <form>
                  <div className="text-center">
                    <p className="deletetext">Möchtest du die Rechnung wirklich löschen?</p>
                  </div>
                  <div className="text-center">
                    <NavLink className="theme_btn" onClick={() => { tabledelete(invoicedelete); setShow1(false) }}>Ja</NavLink>
                    <NavLink className="theme_btn" onClick={() => setShow1(false)}>Nein</NavLink>
                  </div>
                </form>
              </Modal.Body>
            </Modal>

            <Modal className="new_Pop" show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header>
                <Modal.Title>Rechnung hochladen</Modal.Title>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body className="modalbg">
                <h4>RechnungsNr.:{invoiceid}</h4>
                <form>
                  <div className="form-group">
                    <input type="file" className="form-control" placeholder="" accept="application/pdf" onChange={handleSelectFile} />
                    {!!errors.file && <div style={{ color: 'red' }}>{errors.file}</div>}
                  </div>

                  <p className="small_text">Bitte die Rechnung in PDF-Format hochladen.</p>
                  <div className="text-center">
                    <button className="theme_btn" type="button" onClick={handleSubmit} >Speichern</button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default InvoiceUpload
