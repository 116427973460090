import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, CardBody, Col, } from 'reactstrap';
import { GetLocationPrice, GetLocationServices, OccasionList, SaveLocationPrice, SaveLocationServices, ServicesList } from '../../../Services/Allservices/LocationProvider/locationservices';
import { useLocation } from 'react-router-dom';
import "./CreateForm.css"
import { msg } from '../../../Services/Axios';


const CategoryPrice = (props) => {
  const [errors, setErros] = useState({});
  const [occasionId, setOccasionId] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [defaultLable, setDefaultLable] = useState('Anlass*');
  const [occasionList, setocccasionList] = useState([]);
  const [peopleFrom, setpeopleFrom] = useState("")
  const [peopleTo, setpeopleTo] = useState("")
  const [priceTo, setpriceTo] = useState("")
  const [priceFrom, setpriceFrom] = useState("")
  const { state } = useLocation();
  const { isEdit, editId } = state || {}
  const rbSheetRef = useRef()
  const priceFromref = useRef()
  const priceToref = useRef()
  const peopleToref = useRef()
  const peopleFromref = useRef()
  const anchorStyle = {
    background: 'linear-gradient(to top, #DC2430 0%, #7B4397 100%)', border: 'none'
  }
  const imgStyle = {
    filter: 'brightness(0) invert(1)', width: '50px'
  }



  const getData = async () => {

    if (isEdit === 'edit' || isEdit === "copy") {
      Promise.all([
        await GetLocationPrice({ LocationId: editId }),
        await OccasionList(),

      ])
        .then((res) => {

          if (res[1].status) {
            let tempCopyData = res[0].status === 200 ? res[0].data.result : {}
            setpriceFrom(tempCopyData.lPriceFrom);
            setpriceTo(tempCopyData.lPriceTo)
            setpeopleFrom(tempCopyData.nopFrom)
            setpeopleTo(tempCopyData.nopTo)
            let tempOccasionId = (tempCopyData?.occasionId ? tempCopyData?.occasionId : 0)
            let result = res[1].data.result.occasionList;
            let occasionData = result.find(({ id }) => id === (occasionId ?? tempOccasionId))

            setOccasionId(tempOccasionId || occasionId);
            setDefaultLable(occasionData?.name ?? defaultLable);

          }
        })
    }

    else {
      const response = await ServicesList()
      setServiceList(response?.data?.result?.serviceList);
    }
  }

  const getOccasionList = async () => {
    try {
      const response = await OccasionList()
      setocccasionList(response?.data?.result?.occasionList)

    }
    catch (err) {
      console.log(err)
    }
  }

  const getLocationCategory = async () => {
    try {
      const response = await ServicesList()
      setServiceList(response?.data?.result?.serviceList)
      if (response?.data?.result?.transactionMessage?.status) {

      }
      else {
        console.log(response?.data?.result?.transactionMessage?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleChangeOccassionList = (e) => {

    const data = JSON.parse(e.target.value)
    setOccasionId(parseInt(data))
    setDefaultLable(data.name);


  }

  const getLocationService = async () => {
    try {
      const payload = {
        "locationId": editId
      }
      const response = await GetLocationServices(payload)
      setServiceList(response?.data?.result?.locationServices)

    }
    catch (err) {
      console.log(err)
    }

  }
  const handleSubmit = async () => {
    let temp = []
    serviceList.forEach(element => {
      if (element.isSelected) {
        temp.push({ LocationId: (isEdit === 'edit' || isEdit === "copy") ? editId : props.locationId, ServiceId: element.serviceId })
      }
    });

    if (temp.length) {
      Promise.all([
        priceSubmit(),
        categorySubmit(temp)
      ])
    } else {
      priceSubmit()
    }
  }

  const validatepricefrom = () => {
    const pattern_price = /^\d+$/.test(priceFromref?.current?.value)
    var isValid = true
    if (priceFromref?.current?.value.trim() === "") {
      errors.priceFrom = ""
      isValid = false;
    }
    else if (!pattern_price) {

      errors.priceFrom = msg.PreisVon
      isValid = false;
      setErros({ ...errors, errors });
      return isValid;
    }
    else {
      errors.priceFrom = "";
    }
    setErros({ ...errors, errors });
    return isValid;
  }


  const priceSubmit = async () => {
    if (!validateForm()) {
      return
    }
    try {
      const payload = {
        "Id": editId,
        "LocationId": (isEdit === 'edit' || isEdit === "copy") ? editId : props.locationId,
        "OccasionId": occasionId,
        "LPriceFrom": priceFrom,
        "LPriceTo": priceTo,
        "NOPFrom": parseInt(peopleFrom),
        "NOPTo": parseInt(peopleTo),
        "Cancellation": "test",
        "MaxReservation": 2,
        "LocationFloor": "",
        "LocationArea": "",
        "Extra": ""
      }

      const response = await SaveLocationPrice(payload)

      if (response?.data?.status) {
        props.setPriceData({ OccasionId: occasionId, LPriceFrom: priceFrom, LPriceTo: priceTo, NOPFrom: peopleFrom, NOPTo: peopleTo })
        props.onSelect('forth')
      }
      else {
        console.log(response?.data?.result?.transactionMessage?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const categorySubmit = async (services) => {
    await SaveLocationServices({ LocationId: (isEdit === 'edit' || isEdit === "copy") ? editId : props.locationId, locationServices: services })
  }

  const changeService = (index) => {
    let tempService = [...serviceList]
    tempService[index].isSelected = !tempService[index].isSelected
    setServiceList(tempService);
  }

  const handleChangePeopleFrom = (e) => {
    setpeopleFrom(e.target.value)
    if (!validateForm()) {
      return
    }
  }
  const handleChangePeopleTo = (e) => {
    setpeopleTo(e.target.value)
    if (!validateForm()) {
      return
    }
  }
  const handleChangePriceTo = (e) => {
    setpriceTo(e.target.value)
    if (!validatePriceto()) {
      return
    }
  }
  const handleChangePriceFrom = (e) => {
    setpriceFrom(e.target.value)
    if (!validatepricefrom()) {
      return

    }

  }
  const validateForm = () => {
    var isValid = true
    const pattern_people = /^\d+$/.test(peopleFromref?.current?.value)
    const pattern_peopleto = /^\d+$/.test(peopleToref?.current?.value)
    if (occasionId === null) {
      errors.occasionId = msg.Anlass
      isValid = false;
    }
    else {
      errors.occasionId = "";
    }
    if (peopleFromref?.current?.value.trim() === "") {
      errors.peopleFrom = msg.PersonenVon
      isValid = false;
    } else if (!pattern_people) {
      errors.peopleFrom = msg.PersonenVon1
      isValid = false;
    } else {
      errors.peopleFrom = "";
    }
    if (peopleToref?.current?.value.trim() === "") {
      errors.peopleTo = msg.PersonenBis
      isValid = false;
    }
    else if ((!pattern_peopleto)) {
      errors.peopleTo = msg.PersonenBis1
      isValid = false;
    }
    else if (parseInt(peopleToref?.current?.value) < parseInt(peopleFromref?.current?.value)) {
      errors.peopleTo = msg.smallmsg

      isValid = false;
    }
    else {
      errors.peopleTo = "";
    }
    setErros({ ...errors, errors });
    return isValid;
  }

  useEffect(() => {
    getData()
    getOccasionList()
    getLocationService()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const validatePriceto = () => {
    const pattern_priceTo = /^\d+$/.test(priceToref?.current?.value)
    var isValid = true
    if (priceToref?.current?.value.trim() === "") {
      errors.priceTo = ""
      isValid = false;
    }
    else if (!pattern_priceTo) {
      errors.priceTo = msg.PreisBis
      isValid = false;
    }
    else if (parseInt(priceToref?.current?.value) < parseInt(priceFromref?.current?.value)) {
      errors.priceTo = msg.smallmsg
      isValid = false;
    }
    else {
      errors.priceTo = "";
    }
    setErros({ ...errors, errors });
    return isValid;
  }



  return (

    <React.Fragment>
      <div className="form_outer">
        <div className="row">
          <Col sm="12">
            <Card>
              <h5>Kategorie & Preis</h5>
              <CardBody>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <select className="form-control" ref={rbSheetRef} value={occasionId} onChange={handleChangeOccassionList} >
                        <option value="0" selected disabled>Anlass*</option>
                        {
                          occasionList && occasionList.length ? occasionList.map((item) => (

                            <option key={item?.id} value={item?.id}>{item?.name}</option>
                          )) : ''
                        }
                      </select>
                    </div>
                    {!!errors.occasionId && <div style={{ color: 'red' }}>{errors.occasionId}</div>}
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Anzahl von Personen* </label>
                      <input type="text" ref={peopleFromref} name="peopleFrom" value={peopleFrom} onChange={handleChangePeopleFrom} className="form-control" placeholder="von" />
                      {!!errors.peopleFrom && <div style={{ color: 'red' }}>{errors.peopleFrom}</div>}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label className="d-none d-md-block" htmlFor="">&nbsp;</label>
                      <input type="text" ref={peopleToref} name="peopleTo" value={peopleTo} onChange={handleChangePeopleTo} className="form-control" placeholder="bis" />
                      {!!errors.peopleTo && <div style={{ color: 'red' }}>{errors.peopleTo}</div>}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Preis </label>
                      <input type="text" ref={priceFromref} name="priceFrom" value={priceFrom} onChange={handleChangePriceFrom} className="form-control icon_right" placeholder="von" />
                      {!!errors.priceFrom && <div style={{ color: 'red' }}>{errors.priceFrom}</div>}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label className="d-none d-md-block" htmlFor="">&nbsp;</label>
                      <input type="text" ref={priceToref} name="priceTo" value={priceTo} onChange={handleChangePriceTo} className="form-control icon_right" placeholder="bis" />
                      {!!errors.priceTo && <div style={{ color: 'red' }}>{errors.priceTo}</div>}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="">Services </label>
                      <ul className="servicesPik">
                        {
                          serviceList && serviceList.length ? serviceList.map((item, index) => (
                            <li key={index} onClick={() => changeService(index)} style={{ cursor: 'pointer' }}>
                              <button style={item && item.isSelected ? anchorStyle : {}}>
                                <img style={item && item.isSelected ? imgStyle : { width: '50px' }} src={`https://venoon.com/API${item?.iconUrl}`} alt="music" />
                                <span style={item && item.isSelected ? { color: 'white' } : {}}>{item.name}</span>
                              </button>
                            </li>
                          )) : ''
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
      <div className="nextPrevBtn">
        <Button className="theme_btn" onClick={() => props.onSelect('second')}>
          Zurück
        </Button>
        <Button className="theme_btn" disabled={errors.priceTo || errors.priceFrom} onClick={handleSubmit}>
          Weiter
        </Button>
      </div>
    </React.Fragment>

  )
}
export default CategoryPrice