import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Card, CardBody, Col, } from 'reactstrap';
import cameraIcon from "../../../Assests/dashboard/images/cameraIcon.svg"
import closeIcon from "../../../Assests/dashboard/images/closeIcon.svg"
import videoIcon from "../../../Assests/dashboard/images/videoIcon.svg"
import { DeletePhotosAndVideos, GetPhotosAndVideos, SavePhotosAndVideos } from '../../../Services/Allservices/LocationProvider/locationservices';
import { msg } from '../../../Services/Axios';
import { getItemCookies } from '../../Utills/Utills';

import "./CreateForm.css"

const FotoVideo = (props) => {
  const [images, setImages] = useState([]);
  const [videoSource, setVideoSource] = useState([])
  const { state } = useLocation();
  const { isEdit, editId } = state || {}

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingimg, setLoadingimg] = useState(false);
  useEffect(() => {
    if (isEdit === 'edit' || isEdit === "copy") {
      getAvailableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAvailableData = async () => {
    const response = await GetPhotosAndVideos({ "LocationId": (isEdit === 'edit' || isEdit === "copy") ? editId : props.locationId })
    if (response.data.status) {
      let result = response.data.result.photosAndVideosList
      const tempList = result
      setImages(tempList.filter(data => !data.isVideo));
      setVideoSource(tempList.filter(data => data.isVideo));
    }
  }
  const handleSubmit = () => {
    props.onTabClick(!validateForm1())
    if (!validateForm1()) {
      return
    }
    props.onSelect('third')
  }

  const handleSelectPhoto = async (e) => {
    if (!validateForm()) {
      return
    }
    let file = e.target.files[0];
    setLoadingimg(true)
    try {
      // Check if an image is selected
    if (file) {
      // Define the maximum allowed size in bytes (e.g., 10MB)
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

      // Check if the selected image size exceeds the maximum allowed size
      if (file.size > maxSizeInBytes) {
        // Display an error message if the image size is too large
        setErrors({ images: "Die maximal zulässige Bildgröße beträgt 10 MB" });
        // Clear the error message after 2 seconds (adjust the delay as needed)
        setTimeout(() => {
          setErrors({ images: "" });
        }, 2000);
      } else {
        // Clear any existing error messages
        setErrors({ images: "" });

        // Upload the image
      var formdata = new FormData();
      formdata.append("File", file);
      formdata.append("IsVideo", false);
      formdata.append("LocationId", isEdit === 'edit' ? editId : props.locationId)
      const response = await SavePhotosAndVideos(formdata)
      if (response.data.status) {
        let tempPhoto = response.data.result;
        setImages([...images, tempPhoto]);
      }
        }
      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setLoadingimg(false);
      // Clear the input value to allow selecting the same file again
      e.target.value = null;
    }
  };

  const removePhoto = async (photoid) => {

    try {
      const payload = {
        Id: photoid,
        LocationId: isEdit === 'edit' ? editId : props.locationId,
        IsDeleted: true
      }

      const response = await DeletePhotosAndVideos(payload)

      if (response?.data?.status) {
        errors.images = true
        setImages([...images].filter(el => response.data.result.id !== el.id))
      }
      else {
        console.log(response?.data?.result?.transactionMessage?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleSelectVideo = async (e) => {
    if (!validateFormvideo()) {
      return
    }

    let file = e.target.files[0];
    setLoading(true);
    try {
      // Check if an image is selected
    if (file) {
       // Generate a new unique name for the video file
       const newFileName = generateUniqueFileName(file.name);
      
       // Set the new name for the file
       const renamedFile = new File([file], newFileName, { type: file.type });

       
      // Define the maximum allowed size in bytes (e.g., 50MB)
      const maxSizeInBytes = 50 * 1024 * 1024; // 50MB

      // Check if the selected video size exceeds the maximum allowed size
      if (renamedFile.size  > maxSizeInBytes) {
        // Display an error message if the image size is too large
        setErrors({ videoSource: "Die maximal erlaubte Videogröße ist 50 MB" });
        // Clear the error message after 2 seconds (adjust the delay as needed)
        setTimeout(() => {
          setErrors({ videoSource: "" });
        }, 2000);
      } else {
        // Clear any existing error messages
        setErrors({ videoSource: "" });

        // Upload the image
      var formdata = new FormData();
      formdata.append("File", renamedFile);
      formdata.append("IsVideo", true);
      formdata.append("LocationId", isEdit === 'edit' ? editId : props.locationId);
      const response = await SavePhotosAndVideos(formdata)
      if (response.data.status) {
        let tempVideo = response.data.result;
        setVideoSource([...videoSource, tempVideo]);
      }
      }
    }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setLoading(false);
      // Clear the input value to allow selecting the same file again
      e.target.value = null;
    }
  };

  const generateUniqueFileName = (originalFileName) => {
    // Prepend "file" to the name, add a random number, and append current date and time
    const date = new Date();
    const formattedDate = `${date.getFullYear()}${padZero(date.getMonth() + 1)}${padZero(date.getDate())}`;
    const formattedTime = `${padZero(date.getHours())}${padZero(date.getMinutes())}${padZero(date.getSeconds())}`;
    const randomNumber = Math.floor(Math.random() * 10000); // Change 10000 to any desired range of random numbers
    const extension = originalFileName.split('.').pop();
    
    return `file${randomNumber}${formattedDate}${formattedTime}.${extension}`;
  };
  
  const padZero = (num) => (num < 10 ? `0${num}` : num);

  const removeVideo = async (videoid) => {

    try {
      const payload = {
        Id: videoid,
        LocationId: isEdit === 'edit' ? editId : props.locationId,
        IsDeleted: true
      }

      const response = await DeletePhotosAndVideos(payload)
      if (response?.data?.status) {
        errors.videoSource = true
        setVideoSource([...videoSource].filter(el => response.data.result.id !== el.id))
      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const validateForm1 = () => {
    var isValid = true;
    if (images.length === 0) {
      errors.images = msg.Foto_Video
      errors.videoSource="";
      isValid = false;
    }
    else {
      errors.images = "";
      errors.videoSource="";
    }
    setErrors({ ...errors, errors });
    return isValid;
  }

  const validateForm = () => {
    var isValid = true;

    if (getItemCookies("subscriptionTypeId") === '3' && images.length === 10) {
      setErrors({
        ...errors,
        images: msg.foto_msg,
      });
      isValid = false;
    }
    else if (getItemCookies("subscriptionTypeId") === '2' && images.length === 5) {
      setErrors({
        ...errors,
        images: msg.foto_msg,
      });
      isValid = false;
    }
    else if (getItemCookies("subscriptionTypeId") === '1' && images.length === 5) {
      setErrors({
        ...errors,
        images: msg.foto_msg,
      });
      isValid = false;
    }
    else {
      setErrors({
        ...errors,
        images: '',
      });
    }
    // setErrors({ ...errors, errors });
    // if (!isValid) {
    //   // Set a timeout to clear the error message after a few seconds
    //   setTimeout(() => {
    //     setErrors({ ...errors, images: '' });
    //   }, 2000); // 3000 milliseconds (3 seconds)
    // }
    return isValid;
  }

  const validateFormvideo = () => {
    var isValid = true;

    if (getItemCookies("subscriptionTypeId") === '3' && videoSource.length === 2) {
      setErrors({
        ...errors,
        videoSource: msg.video_msg,
      });
      isValid = false;
    }
    else if (getItemCookies("subscriptionTypeId") === '2' && videoSource.length === 1) {
      setErrors({
        ...errors,
        videoSource: msg.video_msg,
      });
      isValid = false;
    }
    else if (getItemCookies("subscriptionTypeId") === '1' && videoSource.length === 1) {
      setErrors({
        ...errors,
        videoSource: msg.video_msg,
      });
      isValid = false;
    }
    else {
      setErrors({
        ...errors,
        videoSource: '',
      });
    }
    // setErrors({ ...errors, errors });
    // if (!isValid) {
    //   // Set a timeout to clear the error message after a few seconds
    //   setTimeout(() => {
    //     setErrors({ ...errors, videoSource: '' });
    //   }, 2000); // 3000 milliseconds (3 seconds)
    // }
    return isValid;
  }

  // const validateForm = () => {
  //   var isValid = true;

  //   if (getItemCookies("subscriptionTypeId") === '3' && images.length === 10) {
  //     errors.images = msg.foto_msg
  //     isValid = false;
  //   }
  //   else if (getItemCookies("subscriptionTypeId") === '2' && images.length === 5) {
  //     errors.images = msg.foto_msg
  //     isValid = false;
  //   }
  //   else if (getItemCookies("subscriptionTypeId") === '1' && images.length === 5) {
  //     errors.images = msg.foto_msg
  //     isValid = false;
  //   }
  //   else {
  //     errors.images = "";
  //   }
  //   if (getItemCookies("subscriptionTypeId") === '3' && videoSource.length === 2) {
  //     errors.videoSource = msg.foto_msg
  //     isValid = false;
  //   }
  //   else if (getItemCookies("subscriptionTypeId") === '2' && videoSource.length === 1) {
  //     errors.videoSource = msg.foto_msg
  //     isValid = false;
  //   }
  //   else if (getItemCookies("subscriptionTypeId") === '1' && videoSource.length === 1) {
  //     errors.videoSource = msg.foto_msg
  //     isValid = false;
  //   }
  //   else {
  //     errors.videoSource = "";
  //   }
  //   setErrors({ ...errors, errors });
  //   return isValid;
  // }


  return (

    <React.Fragment>

      <div className="upload__image-wrapper">

        <div className="form_outer">
          <div className="row">
            <Col sm="12">
              <Card>
                <div className="head">
                  <h5>Foto & Video</h5>

                </div>
                <CardBody>
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="add_photoVideo">
                        {
                          getItemCookies("subscriptionTypeId") === "1" || getItemCookies("subscriptionTypeId") === "2" ?
                            <h6><span className="photolabel">Foto – Hochladen</span> <small>(Hier sind maximal 5 Fotos erlaubt)</small></h6>
                            : <h6><span className="photolabel">Foto – Hochladen</span> <small>(Hier sind maximal 10 Fotos erlaubt)</small></h6>
                        }

                        <li>
                          <div className="setPhoto">
                            {images.map((image, index) => (
                              <div key={index} className="image-item">
                                <img src={`https://venoon.com/API${image.url}`} alt="" width="100" />
                                <div className="image-item__btn-wrapper">
                                  <button >
                                    <img onClick={() => removePhoto(image.id)} src={closeIcon} alt="close" />
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                          <label style={{ display: 'block' }} htmlFor="myInput">
                            <div className="poto_upload">
                              <img src={cameraIcon} alt="videoIcon" />
                            </div>
                          </label>
                          <input
                            id="myInput"
                            style={{ display: 'none' }}
                            type="file"
                            accept="images/png,images/jpeg,image/*"
                            onChange={handleSelectPhoto}

                          />
                        </li>
                        {loadingimg && <div>Laden...</div>}
                      </ul>
                      {!!errors.images && <div style={{ color: 'red' }}>{errors.images}</div>}

                      <ul className="add_photoVideo">
                        {
                          getItemCookies("subscriptionTypeId") === "1" || getItemCookies("subscriptionTypeId") === "2" ?
                            <h6><span className="photolabel">Video – Hochladen</span> <small>(Hier sind maximal 1 Videos erlaubt)</small></h6> :
                            <h6><span className="photolabel">Video – Hochladen</span> <small>(Hier sind maximal 2 Videos erlaubt)</small></h6>
                        }

                        <li>
                          <div className="videoThumb">
                            {
                              videoSource.map((video, index) => (
                                <div key={index}>
                                  <video id="video" width={80} height={70} style={{ border: '1px solid rgba(0,0,0,0.2)' }} controls>
                                    <source src={`https://venoon.com/API${video.url}`} id="source" />
                                  </video>
                                  <button><img onClick={() => removeVideo(video.id)} src={closeIcon} alt="close" /></button>
                                </div>
                              ))
                            }
                          </div>
                          <label style={{ display: 'block' }} htmlFor="myInputTwo">
                            <div className="poto_upload">
                              <img src={videoIcon} alt="videoIcon" />
                            </div>
                          </label>
                          <input
                            id="myInputTwo"
                            style={{ display: 'none' }}
                            type="file"
                            accept="video/mp4,video/x-m4v,video/*"
                            onChange={handleSelectVideo}
                          />
                        </li>
                        {loading && <div>Laden...</div>}
                      </ul>
                      {!!errors.videoSource && <div style={{ color: 'red' }}>{errors.videoSource}</div>}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>

        <div className="nextPrevBtn">
          <Button className="theme_btn" onClick={() => props.onSelect('first')} >
            Zurück
          </Button>
          <Button className="theme_btn" onClick={() => handleSubmit()} >
            Weiter
          </Button>
        </div>

      </div>
    </React.Fragment>


  )
}

export default FotoVideo