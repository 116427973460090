import React, { useState } from 'react'

import "./ReportProblem.css"
import { Button } from 'react-bootstrap'
import { ProblemMaidenByProvider } from '../../../Services/Allservices/LocationProvider/locationservices'
import { msg } from '../../../Services/Axios'
import { AiFillCheckCircle } from "react-icons/ai";
const ReportProblem = () => {
    const [problem, setProblem] = useState("")
    const [errors, setErros] = useState({});
    const [show, setShow] = useState("")

    const handlesubmit = async () => {
        if (!validateForm()) {
            return
        }
        try {
            const payload = {
                UserQuery: problem

            }

            const response = await ProblemMaidenByProvider(payload)

            if (response?.data?.result?.transactionMessage?.status === 201) {
                setProblem("")
                setShow(response?.data?.message)
                setTimeout(() => {
                   setShow("")
                
                }, 2000);
            }
            else {
                console.log(response?.data?.result?.transactionMessage?.message)
            }
        }
        catch (err) {
            console.log(err)
        }


    }
    const validateForm = () => {
        var isValid = true;
        if (problem === "") {
            errors.problem = msg.ProblemMaiden
            isValid = false;
        }
        else {
            errors.problem = "";
        }


        setErros({ ...errors, errors });
        return isValid;
    }
    return (
        <div className="main_container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="heading_div">
                        <h3 className="main_heading">Ein Problem melden</h3>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="data_outer">
                        <p>Wir werden uns bestmöglich um dein Problem kümmern.</p>
                        <form>
                            <div className="form-group">
                                <textarea name="problem" value={problem} onChange={(e) => setProblem(e.target.value)} className="form-control text_msg" placeholder="Bitte schreibe hier dein Problem"></textarea>
                                {!!errors.problem && <div style={{ color: 'red' }}>{errors.problem}</div>}
                            </div>
                            <Button onClick={handlesubmit} className="theme_btn">Senden</Button>
                        </form>
                    </div>

                    <div style={{ paddingTop: "20px" }}><p className="msg_show"> {show ? <span><AiFillCheckCircle className='icon_color' style={{ fontSize: "30px" }} /> {show}</span> : ""}</p></div>
                </div>
            </div>
        </div>
    )
}

export default ReportProblem