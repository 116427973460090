import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from '../Component/Home/Home'
import Agb from '../Component/Layout/Footer/Agb'
import Datenschutz from '../Component/Layout/Footer/Datenschutz'
import Impressum from '../Component/Layout/Footer/Impressum'
import KontaKt from '../Component/Layout/Footer/Kontakt'
import Welcome from '../Component/Welcome/Welcome'
import AllRouting from './allRouting'
import NotFound from '../Component/NotFound/NotFound'
import Accountdeleterequest from '../Component/Layout/AccountDeleteRequest/Accountdeleterequest'

const Routing = () => {
  const location = useLocation()
  return (
    <div>

      {
        location.pathname === "/" || location.pathname === "/datenschutz" || location.pathname === "/agb" || location.pathname === "/impressum" || location.pathname === "/kontakt" || location.pathname === "/welcome"||location.pathname==="/account-delete-request"
          ?

          <Routes>
            <Route exact path="/" element={<Home />} /> 
            <Route exact path="/*" element={<NotFound/>}/>
          <Route exact path="/datenschutz" element={<Datenschutz />} />
            <Route exact path="/agb" element={<Agb />} />
            <Route exact path="/impressum" element={<Impressum />} />
            <Route exact path="/kontakt" element={<KontaKt />} />
            <Route exact path="/welcome" element={<Welcome />} />
          <Route exact path="/account-delete-request" element={<Accountdeleterequest/>}/>
          </Routes>

          :
          <AllRouting />




      }


    </div>
  )
}

export default Routing