import React, { useState, useEffect } from 'react'
import dash_i01 from "../../../Assests/dashboard/images/dash_i01.svg"
import dash_i02 from "../../../Assests/dashboard/images/dash_i02.svg"
import dash_i03 from "../../../Assests/dashboard/images/dash_i03.svg"
import dash_i04 from "../../../Assests/dashboard/images/dash_i04.svg"
import count_icon08 from "../../../Assests/dashboard/images/count_icon08.svg"
import count_icon03 from "../../../Assests/dashboard/images/count_icon03.svg"
import { GetLocationProviderDashboard } from '../../../Services/Allservices/LocationProvider/locationservices'
import "./Dashboard.css"
import Loader from '../../Utills/Loader'

const LocationDashboard = () => {
    const [user, setUser] = useState({})
    const [loading, setloading] = useState(false)
    const handleSubmit = async () => {
        setloading(true)
        const response = await GetLocationProviderDashboard();
        try {

            if (response?.data?.result?.transactionMessage?.status === 200) {

                setUser(response?.data?.result)
                setloading(false)
            }
            else {
                console.log(response?.data?.result?.transactionMessage?.message)

            }
        }
        catch (err) {

        }
    }
    useEffect(() => {
        handleSubmit()
    }, [])

    return (
        <React.Fragment>

            {
                loading ?
                    <Loader />
                    :
                    <div className="main_container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading_div">
                                    <h3 className="main_heading">Startseite</h3>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                                <div className="card_box card_boxW">
                                    <div className="t_count">

                                        <h4>{user.locations}</h4>
                                        <h5>Anzahl der Locations</h5>
                                    </div>
                                    <div>
                                        <img src={dash_i01} alt="img" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                                <div className="card_box card_boxW">
                                    <div className="t_count">

                                        <h4>{user.freeDays}</h4>
                                        <h5>Freie Tage</h5>
                                    </div>
                                    <div>
                                        <img src={dash_i02} alt="img" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                                <div className="card_box card_boxW">
                                    <div className="t_count">

                                        <h4>{user.insertstedPerson}</h4>
                                        <h5>Interessenten</h5>
                                    </div>
                                    <div>
                                        <img src={dash_i03} alt="img" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                                <div className="card_box card_boxW">
                                    <div className="t_count">

                                        <h4>{user.visitors}</h4>
                                        <h5>Besucher</h5>
                                    </div>
                                    <div>
                                        <img src={dash_i04} alt="img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="countDetails">
                                    <div className="count_header">
                                        <h4>Reservierungen </h4>
                                        <img src={count_icon03} alt="img" />
                                    </div>
                                    <div className="count_body">
                                        <div className="box_center">
                                            <h5>Reserviert(%)</h5>
                                            <h6>{user.reservationPercentage}</h6>
                                        </div>
                                        <div className="box_center">
                                            <h5>Aktive Reservierungen</h5>

                                            <h6>{user.activeReservation}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="countDetails">
                                    <div className="count_header">
                                        <h4>Buchungen</h4>
                                        <img src={count_icon08} alt="img" />
                                    </div>
                                    <div className="count_body">
                                        <div className="box_center">
                                            <h5>Gebucht (%)</h5>

                                            <h6>{user.bookingPercentage}</h6>
                                        </div>
                                        <div className="box_center">
                                            <h5>Aktive Buchungen</h5>

                                            <h6>{user.activeBooking}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </React.Fragment>
    )
}

export default LocationDashboard