import React, { useState, useEffect } from 'react'
import "./MyLocationList.css"
import { Modal, Dropdown } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import add2 from '../../../Assests/dashboard/images/add2.svg';
import search from '../../../Assests/dashboard/images/search.svg';
import menu_i01 from '../../../Assests/dashboard/images/menu_i01.svg';
import menu_i02 from '../../../Assests/dashboard/images/menu_i02.svg';
import menu_i03 from '../../../Assests/dashboard/images/menu_i03.svg';
import menu_i04 from '../../../Assests/dashboard/images/menu_i04.svg';
import menu from '../../../Assests/dashboard/images/menu.svg';
import image_default from "../../../Images/default.jpg"
import { LocationListForProvider, DeleteLocation, CopyLocation, CheckSubscription } from '../../../Services/Allservices/LocationProvider/locationservices';
import { getItemCookies, setItemCookies } from '../../Utills/Utills';
import Loader from '../../Utills/Loader';
import { msg } from '../../../Services/Axios';

const MyLocationList = () => {
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const [locationList, setLocationList] = useState([])
    const [searchText, setsearchText] = useState("")
    const [Deleteid, setDeleteId] = useState()
    const [Copyid, setCopyId] = useState()
    const [loading, setloading] = useState(false)
    const [errors, setErros] = useState({});
    const [searchLocation, setsearchLocation] = useState("")
    const navigate = useNavigate()
    useEffect(() => {
        Promise.all([
            checkSubscription(),
            getLocationData('withoutSearch')
        ])

    }, [])

    const checkSubscription = async () => {
        const checkSubscription = await CheckSubscription();
        setItemCookies('subscriptionTypeId', JSON.stringify(checkSubscription.data.result.subscriptionTypeId))
        setItemCookies('numberOfLocation', checkSubscription.data.result.numberOfLocation)
        setItemCookies('numberOfPhotos', checkSubscription.data.result.numberOfPhotos)
        setItemCookies('numberOfVideos', checkSubscription.data.result.numberOfVideos)
        setItemCookies('subscriptionEnd', JSON.stringify(checkSubscription.data.result.subscriptionEnd))
    }

    const getLocationData = async (val) => {

        setsearchText(val);
        let search;
        search = val === 'withoutSearch' ? "" : val;
        setloading(true)
        if (getItemCookies("auth_token")) {
            const response = await LocationListForProvider(search ?? "");
            try {

                if (response?.data?.status) {
                    setLocationList(response?.data?.result?.locationList)
                    setloading(false)
                    //Current date formate YYYY-MM-DD
                    var d = new Date(),
                        month = '' + (d.getMonth() + 1),
                        day = '' + d.getDate(),
                        year = d.getFullYear();

                    if (month.length < 2)
                        month = '0' + month;
                    if (day.length < 2)
                        day = '0' + day;

                    var finalCuDate = [year, month, day].join('-');


                    //subscriptionEnd formate YYYY-MM-DD
                    const subDate = getItemCookies("subscriptionEnd");
                    const subDate1 = subDate.split('T');
                    const subDate2 = subDate1[0].split('"');

                    if (subDate2[1] < finalCuDate) {
                        setLocationList([])
                    }
                }
                else {

                    console.log(response?.data?.message)

                }
            }


            catch (err) {

            }
        }
    }

    const LocationDelete = async (id) => {
        try {
            const payload = {
                Id: id,

            }

            const response = await DeleteLocation(payload)
            if (response?.data?.result?.transactionMessage?.status === 200) {
                getLocationData("withoutSearch")
            }
            else {
                console.log(response?.data?.result?.transactionMessage?.message)
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    const LocationCopy = async (id) => {
        try {
            const payload = {
                Id: id,

            }

            const response = await CopyLocation(payload)

            if (response?.data?.result?.transactionMessage?.status === 200) {
                const locaedit = response?.data?.result?.id
                const state = { isEdit: 'copy', editId: locaedit, purpose: 'copy' };

                setShow1(false);
                navigate("/alllocation", { state })
            }
            else {
                console.log(response?.data?.result?.transactionMessage?.message)
            }
        }
        catch (err) {
            console.log(err)
        }


    }

    const increaseId = () => {

        if (!validateForm()) {
            return
        }

    }

    const validateForm = () => {
        var isValid = true;
        if ((getItemCookies("subscriptionTypeId") === "2" || getItemCookies("subscriptionTypeId") === "1") && locationList.length === 1) {
            errors.location = msg.location_msg
            isValid = false;
        }

        else {
            errors.location = "";
        }
        setErros({ ...errors, errors });
        return isValid;
    }

    return (
        <React.Fragment>
            {
                loading ? <Loader />
                    : <div className="main_container new-cont">
                        <div className="row data_outer1">
                            <div className="col-sm-12">
                                <div className="heading_div new_heading">
                                    <h3 className="main_heading">Meine Location</h3>

                                    <NavLink className="searchremove" onClick={() => getLocationData('withoutSearch')}>Filter löschen</NavLink>
                                    <div className="has-search mr-0">

                                        <button className="search_icon" onClick={() => getLocationData(searchLocation)}><img src={search} alt="search" /></button>
                                        <input type="text" name="searchLocation" onInput={(e) => setsearchLocation(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    getLocationData(e.target.value)

                                                }
                                            }}
                                            className="form-control" placeholder="Nach Location suchen ..." />
                                    </div>

                                </div>
                            </div>
                            {(searchText === 'withoutSearch' && locationList?.length >= 0) || locationList?.length > 0 ?
                                <div className={locationList && locationList.length ? "col-sm-12 col-md-4" : "col-sm-12 col-md-4"}>
                                    <div className="locat_div" style={locationList && !locationList.length ? { padding: '200px' } : {}}>
                                        <div className="center_add1" >

                                            {
                                                ((getItemCookies("subscriptionTypeId") === "2" || getItemCookies("subscriptionTypeId") === "1") && locationList.length === 1) ?
                                                    <button onClick={increaseId} className="add_location"> <img src={add2} alt="img" /></button> :
                                                    <NavLink to="/alllocation" state={{ isEdit: 'create', editId: 0, purpose: 'create' }} className="add_location"> <img src={add2} alt="img" /></NavLink>

                                            }
                                            <h4>Bitte erstelle deine Location</h4>
                                        </div>
                                    </div>
                                </div> : <div className='norecord'>Keine Location gefunden!</div>
                            }
                            {
                                locationList && locationList.length > 0 ?
                                    <>
                                        {
                                            locationList.map((item) => (
                                                <div className="col-sm-12 col-md-4" key={item?.locationId}>
                                                    <div className="locat_div" >
                                                        <div className="imgBox"><img src={item?.photoUrl ? `https://venoon.com/API${item?.photoUrl}` : image_default} alt="img" /></div>
                                                        {
                                                            !item.isCompleted && !item.isActive ?
                                                                <span className="new_drop_menu">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                                                    </svg>
                                                                </span> : ''
                                                        }
                                                        <div className="drop_menu">
                                                            <Dropdown>
                                                                <Dropdown.Toggle>
                                                                    <img src={menu} alt="menu" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item disabled={!item?.isCompleted || !item?.isActive}><NavLink state={{ editId: item?.locationId }} to="/changeLocation"><img src={menu_i01} alt="img" />Locationdaten anzeigen&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavLink></Dropdown.Item>
                                                                    <Dropdown.Item disabled={!item?.isActive}><NavLink state={{ isEdit: 'edit', editId: item?.locationId, purpose: 'edit' }} to={{ pathname: "/alllocation" }}><img src={menu_i02} alt="img" />Details ändern&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavLink></Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => { setDeleteId(item?.locationId); setShow(true) }} ><img src={menu_i03} alt="img" />Location löschen</Dropdown.Item>
                                                                    <Dropdown.Item disabled={(getItemCookies("subscriptionTypeId") === "2" &&locationList.length===1)||(!item?.isCompleted || !item?.isActive) || getItemCookies("subscriptionTypeId") === "0" || getItemCookies("subscriptionTypeId") === "1" } onClick={() => { setCopyId(item?.locationId); setShow1(true) }}><img src={menu_i04} alt="img" />Location kopieren</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </div>
                                                        <div className="detail_div">
                                                            <h5>{item.locationName}</h5>
                                                            <p>{item.place}</p>
                                                            {
                                                                !item?.isCompleted ? <p className='draft_message'>Entwurf</p> : ""
                                                            }
                                                            {
                                                                !item?.isActive ? <p className='draft_message'>Inaktiv</p> : ""
                                                            }
                                                        </div>

                                                    </div>

                                                </div>
                                            ))


                                        }
                                    </>
                                    :
                                    <p className='hellomsg'></p>
                            }
                            {!!errors.location && <div style={{ color: 'red' }}>{errors.location}</div>}
                        </div>
                        <Modal className="new_Pop" show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <Modal.Body className="p-4 modalbg">
                                <p className="modalPara">Möchtest du diese Location wirklich unwiderruflich löschen?</p>
                                <div className="text-center">
                                    <button className="theme_btn" onClick={() => { setShow(false); LocationDelete(Deleteid) }} >Ja</button>
                                    <button onClick={() => setShow(false)} className="theme_btn" >Nein</button>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal className="new_Pop" show={show1} onHide={() => setShow1(false)} aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <Modal.Body className="p-4 modalbg">
                                <p className="modalPara">Möchtest du diese Location wirklich erneut kopieren?</p>
                                <div className="text-center">
                                    <button className="theme_btn" onClick={() => { LocationCopy(Copyid) }} > Ja</button>
                                    <button className="theme_btn" onClick={() => setShow1(false)}>Nein</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
            }
        </React.Fragment >
    )
}

export default MyLocationList