import React, { useState } from 'react'
import { Nav, Tab, Col, Row, Carousel, Button } from 'react-bootstrap'
import about_img from "../../Images/about_img.png"
import Suchen_A from "../../Images/Suchen_A.svg"
import Suchen_B from "../../Images/Suchen_B.svg"
import Suchen_C from "../../Images/Suchen_C.svg"
import Suchen_D from "../../Images/Suchen_D.svg"
import Suchen_E from "../../Images/Suchen_E.svg"
import Anbieten_A from "../../Images/Anbieten_A.svg"
import Anbieten_B from "../../Images/Anbieten_B.svg"
import Anbieten_C from "../../Images/Anbieten_C.svg"
import Anbieten_D from "../../Images/Anbieten_D.svg"
import Anbieten_E from "../../Images/Anbieten_E.svg"
import banner from "../../Images/banner.jpg"
import locationIcon01 from "../../Images/locationIcon01.svg"
import locationIcon02 from "../../Images/locationIcon02.svg"
import locationIcon03 from "../../Images/locationIcon03.svg"
import line02 from "../../Images/line02.svg"
import line01 from "../../Images/line01.svg"
import line03 from "../../Images/line03.svg"
import arrow_right from "../../Images/arrow_right.svg"
import arrow_left from "../../Images/arrow_left.svg"
import mobi_img2 from "../../Images/mobi_img2.png"
import google_play from "../../Images/google-play.png"
import i_download from "../../Images/i-download.png"
import ELasticCarousel from 'react-elastic-carousel';
import { NavLink } from 'react-router-dom'
import "./Home.css"
import Login from '../Login/Login'
import Footer from '../Layout/Footer/Footer'
import { SendAppLink } from '../../Services/LoginAccount/accountservices'
import { AiFillCheckCircle } from "react-icons/ai";
const Home = () => {
  const index = 1
  const direction = null
  const nextIcon = <img src={arrow_right} alt="next" />
  const prevIcon = <img src={arrow_left} alt="prev" />
  const [platform, setplatform] = useState()
  const [email, setEmail] = useState("")
  const [errors, setErros] = useState({})
  const [show, setShow] = useState("")

  const Items = ([
    {
      id: 1, title:
        <Nav.Item>
          <Nav.Link eventKey="first">
            <div className="ws_content">
              <div className="count">
                <span>1</span>
              </div>
              <div>
                <h4>Kategorie auswählen</h4>
                <p>Anlass auswählen z. B. Event (Musik, Theater, Business), Party (Geburtstag) oder Hochzeit.</p>
              </div>
            </div>
          </Nav.Link>
        </Nav.Item>
    },
    {
      id: 2, title:
        <Nav.Item>
          <Nav.Link eventKey="second">
            <div className="ws_content">
              <div className="count">
                <span>2</span>
              </div>
              <div>
                <h4>Location suchen</h4>
                <p>Über die Standortkarte oder Suchmaske findest du deine gewünschte Location.</p>
              </div>
            </div>
          </Nav.Link>
        </Nav.Item>
    },
    {
      id: 3, title:
        <Nav.Item>
          <Nav.Link eventKey="third">
            <div className="ws_content">
              <div className="count">
                <span>3</span>
              </div>
              <div>
                <h4>Favoritenliste</h4>
                <p>Du kannst jederzeit eine Location als Favorit speichern. Die Location findest du in deiner Favoritenliste.</p>
              </div>
            </div>
          </Nav.Link>
        </Nav.Item>
    },
    {
      id: 4, title:
        <Nav.Item>
          <Nav.Link eventKey="fourth">
            <div className="ws_content">
              <div className="count">
                <span>4</span>
              </div>
              <div>
                <h4>Suchfilter</h4>
                <p>Über den Suchfilter gibst du Suchbedingungen für deine Wunschlocation ein z. B. Verfügbarkeit, Entfernung, Preis und Catering-Services.</p>
              </div>
            </div>
          </Nav.Link>
        </Nav.Item>
    },
    {
      id: 5, title:
        <Nav.Item>
          <Nav.Link eventKey="fifth">
            <div className="ws_content">
              <div className="count">
                <span>5</span>
              </div>
              <div>
                <h4>Location reservieren</h4>
                <p>Überprüfe die Locationdaten und reserviere deine Location. Nehme direkt Kontakt mit den Location Anbieter auf.</p>
              </div>
            </div>
          </Nav.Link>
        </Nav.Item>
    }])
  const newItems = ([
    {
      id: 1, title:
        <Nav.Item>
          <Nav.Link eventKey="first">
            <div className="ws_content">
              <div className="count">
                <span>1</span>
              </div>
              <div>
                <h4>Location erstellen</h4>
                <p>Füge deine Location hinzu und gebe deine Locationdaten ein. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
              </div>
            </div>
          </Nav.Link>
        </Nav.Item>
    },
    {
      id: 2, title:
        <Nav.Item>
          <Nav.Link eventKey="second">
            <div className="ws_content">
              <div className="count">
                <span>2</span>
              </div>
              <div>
                <h4>Fotos & Videos</h4>
                <p>Lade die Fotos und Videos deiner Location in VENOON-App. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
              </div>
            </div>
          </Nav.Link>
        </Nav.Item>
    },
    {
      id: 3, title:
        <Nav.Item>
          <Nav.Link eventKey="third">
            <div className="ws_content">
              <div className="count">
                <span>3</span>
              </div>
              <div>
                <h4>Meine Location</h4>
                <p>Finde deine erstellte Location in der Übersicht „Meine Location“ und ändere deine Locationdaten.</p>
              </div>
            </div>
          </Nav.Link>
        </Nav.Item>
    },
    {
      id: 4, title:
        <Nav.Item>
          <Nav.Link eventKey="fourth">
            <div className="ws_content">
              <div className="count">
                <span>4</span>
              </div>
              <div>
                <h4>Kalenderfunktion</h4>
                <p>Überprüfe deine frei verfügbare Tage und verwalte jederzeit die Verfügbarkeiten deiner Location.</p>
              </div>
            </div>
          </Nav.Link>
        </Nav.Item>
    },
    {
      id: 5, title:
        <Nav.Item>
          <Nav.Link eventKey="fifth">
            <div className="ws_content">
              <div className="count">
                <span>5</span>
              </div>
              <div>
                <h4>Location verwalten</h4>
                <p>Nutze das Dashboard um wichtige Kennzahlen auf einen Blick zu haben. Verwalte deine Location z. B. Verfügbarkeiten und Stornierung.</p>
              </div>
            </div>
          </Nav.Link>
        </Nav.Item>
    }
  ])
  const handleSubmit = async (e) => {

    e.preventDefault()
    if (!validateForm()) {
      return
    }
    const value = platform === "Android" ? true : false
    const response = await SendAppLink(email, value);
    try {
      if (response?.data?.status) {
        setEmail("")
       
        setShow("Link wurde erfolgreich gesendet")
        setTimeout(() => {
          setShow("")
        }, 2000);

      }
      else {

      }
    }
    catch (err) {

    }
  }

  const validateForm = () => {
    var isValid = true;
    if (email.trim() === "") {
      errors.email = "Email erforderlich"
      isValid = false;
    }
    else {
      errors.email = "";
    }
    if (platform === undefined) {
      errors.platform = "Plattform benötigt"
      isValid = false;
    }
    else {
      errors.platform = "";
    }
    setErros({ ...errors, errors });
    return isValid;
  }

  return (
    <div>


      <section className="banner_sec sweet-loading">
        <img src={banner} className="w-100" alt="Banner" />
        <Login />
      </section>
      <section className="about_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 align-self-center">
              <div className="about_text">
                <h3>Was ist VENOON</h3>
                <h4>VENOON – Find Your Location & Services</h4>
                <p>VENOON bietet eine Onlineplattform für Location-Suchende und Location-Anbieter an.</p>
                <ul className="hometext">
                  <li><b>Location-Suchende</b> finden über die VENOON-App eine Location für jeden Anlass in ihrer Umgebung und können diese Location online in der App reservieren.</li>
                  <li><b>Location-Anbieter</b> können ebenfalls ihre Location in der App zur Reservierung anbieten und verwalten.</li>
                </ul>
                <p>Kontaktaufnahme und Kommunikation werden in der VENOON-App schneller, einfacher und flexibler.</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 animate__animated animate__bounce">
              <img src={about_img} alt="img" />
            </div>
          </div>
        </div>
      </section>


      <section className="middle_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <div className="tab-content w-100">
                <h3>Einfach, flexibel und benutzerfreundlich</h3>
                <p>Entdecke die Features der VENOON-App für einen schnellen und problemlosen Zugriff</p>
                <div id="menu1" className="tab-pane active">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="pills" className="bottomSpace">
                      <Nav.Item>
                        <Nav.Link className="btnset" eventKey="first"> SUCHEN</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link className="btnset" eventKey="second">ANBIETEN</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content style={{ textAlign: 'initial' }}>
                      <Tab.Pane eventKey="first">
                        <div id="menu1" className="tab-pane active">
                          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row className="justify-content-center">
                              <Col sm={12} md={12} lg={5}>
                                <Nav variant="pills" className="flex-column">
                                  <ELasticCarousel itemsToShow={window.innerWidth <= 1198 ? 2 : 3} verticalMode className="vertical">
                                    {Items.map(item => <div key={item.id}>{item.title}</div>)}
                                  </ELasticCarousel>
                                </Nav>
                              </Col>
                              <Col sm={12} md={12} lg={4}>
                                <Tab.Content>
                                  <Tab.Pane eventKey="first">
                                    <img src={Suchen_A} className="slideImgM animate__animated animate__fadeIn" alt="img" />
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="second">
                                    <img src={Suchen_B} className="slideImgM animate__animated animate__fadeIn" alt="img" />
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="third">
                                    <img src={Suchen_C} className="slideImgM animate__animated animate__fadeIn" alt="img" />
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="fourth">
                                    <img src={Suchen_D} className="slideImgM animate__animated animate__fadeIn" alt="img" />
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="fifth">
                                    <img src={Suchen_E} className="slideImgM animate__animated animate__fadeIn" alt="img" />
                                  </Tab.Pane>

                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div id="menu1" className="tab-pane active">
                          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row className="justify-content-center">
                              <Col sm={12} md={12} lg={5}>
                                <Nav variant="pills" className="flex-column">
                                  <ELasticCarousel itemsToShow={window.innerWidth <= 1198 ? 2 : 3} verticalMode style={{ marginTop: '30px', marginBottom: '30px' }}>
                                    {newItems.map(item => <div key={item.id}>{item.title}</div>)}
                                  </ELasticCarousel>
                                </Nav>
                              </Col>
                              <Col sm={12} md={12} lg={4}>
                                <Tab.Content>
                                  <Tab.Pane eventKey="first">
                                    <img src={Anbieten_A} className="slideImgM animate__animated animate__fadeIn" alt="img" />
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="second">
                                    <img src={Anbieten_B} className="slideImgM animate__animated animate__fadeIn" alt="img" />
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="third">
                                    <img src={Anbieten_C} className="slideImgM animate__animated animate__fadeIn" alt="img" />
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="fourth">
                                    <img src={Anbieten_D} className="slideImgM animate__animated animate__fadeIn" alt="img" />
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="fifth">
                                    <img src={Anbieten_E} className="slideImgM animate__animated animate__fadeIn" alt="img" />
                                  </Tab.Pane>
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="location_sec">
        <div className="container">
          <Carousel nextIcon={nextIcon} prevIcon={prevIcon} index={index} direction={direction} >
            <Carousel.Item>
              <div style={{ textAlign: 'center' }}>
                <h3>Location-Suchende</h3>
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <div className="locat_box">
                      <img src={locationIcon01} alt="img" />
                      <h4>Bei VENOON-App <span>registrieren</span></h4>
                      <p>Klicke auf die Schaltfläche Registrierung,
                        um sofort auf alle Features der VENOON-App zugreifen.</p>
                      <img src={line01} alt="img" />
                      <h6>Schritt 1</h6>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="locat_box">
                      <img src={locationIcon02} alt="img" />
                      <h4>Location suchen und <span>reservieren</span></h4>
                      <p>Finde eine Location für einen besonderen
                        Anlass z. B. Hochzeit, Geburtstag oder Event (Musik, Theater, Business).</p>
                      <img src={line02} alt="img" />
                      <h6>Schritt 2</h6>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="locat_box">
                      <img src={locationIcon03} alt="img" />
                      <h4>Reservierungsbestätigung <span>erhalten</span></h4>
                      <p>Erhalte über die VENOON-App eine
                        Bestätigung für eine Reservierung oder <span>Buchung.</span></p>
                      <img src={line03} alt="img" />
                      <h6>Schritt 3</h6>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ textAlign: 'center' }}>
                <h3>Location-Anbieter </h3>
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <div className="locat_box">
                      <img src={locationIcon01} alt="img" />
                      <h4>Bei VENOON-App <span>registrieren</span></h4>
                      <p>Klicke auf die Schaltfläche Anmelden / Registrierung, um sofort auf alle FEATURES der VENOON-App zuzugreifen.</p>
                      <img src={line01} alt="img" />
                      <h6>Schritt 1</h6>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="locat_box">
                      <img src={locationIcon02} alt="img" />
                      <h4>Location <span>erstellen</span></h4>
                      <p>Biete deine Location zur Vermietung an. Verwalte die Verfügbarkeiten sowie Fotos & Videos.</p>
                      <img src={line02} alt="img" />
                      <h6>Schritt 2</h6>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="locat_box">
                      <img src={locationIcon03} alt="img" />
                      <h4>Reservierungen <span>verwalten</span></h4>
                      <p>Verwalte die Reservierungen und Buchungen. Nehme Kontakt mit den <span>Kunden auf.</span></p>
                      <img src={line03} alt="img" />
                      <h6>Schritt 3</h6>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
      <section className="download_sec">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-sm-12 col-md-5">
              <img src={mobi_img2} className="mobi_img2" alt="img" />
            </div>
            <div className="col-sm-12 col-md-7 align-self-center">
              <div className="form_design">
                <h3>Download VENOON-App</h3>
                <h4>Finde oder biete deine LOCATION an</h4>
                <p>
                  Wir senden dir einen Link. Öffne diesen Link auf deinem Smartphone, um die App herunterzuladen.
                </p>
                <form>
                  <div className="form-row align-items-center appdownload">
                    <div className="col-sm-12 col-md-12 col-lg-3 my-1">
                      <select onChange={(e) => setplatform(e.target.value)} defaultValue="Mobile OS" className="form-control">
                        <option disabled>Mobile OS</option>
                        <option value="Android">Android</option>
                        <option value="iOS">iOS</option>
                      </select>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8 my-1">
                      <div className="input-group">
                        <input type="email" className="form-control" placeholder="Bitte E-Mail Adresse eintragen" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />

                        <div className="input-group-prepend">
                          <div className="input-group-text"><Button style={{ color: 'white' }} className="input-group-text" onClick={handleSubmit} >E-Mail</Button></div>
                        </div>
                      </div>
                    </div>
                    {!!errors.platform && <div style={{ color: 'red' }}>{errors.platform}</div>}
                    {!!errors.email && <div style={{ color: 'red', paddingLeft: "30px" }}>{errors.email}</div>}
                    <div className="col-sm-12 col-md-12 col-lg-8 my-1">
                      {show ? <div style={{ color: '#7E2845' }}><span><AiFillCheckCircle className='icon_color' style={{ fontSize: "30px" }} /></span>{show}</div> : ""}
                    </div>
                  </div>
                  <p className="appterms">
                  * Es gelten die Datenschutzbestimmungen und Allgemeinen Geschäftsbedingungen von VENOON
                  </p>
                  <div className="app_download">
                  <a href="https://play.google.com/store/apps/details?id=com.venoon" target='_blank'><img src={google_play} alt="1" /></a>
                  <a href="https://apps.apple.com/app/venoon/id6447560755"  target='_blank'><img src={i_download} alt="2" /></a>               
                   
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Home