import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './ProfileList.css';
import { Modal } from 'react-bootstrap';
import eye from '../../../Assests/dashboard/images/eye.svg';
import bin from '../../../Assests/dashboard/images//bin.svg';
import kill_session from "../../../Images/kill-session.svg"
import { NavLink, useNavigate } from 'react-router-dom';
import { DeleteUserById, GetUserProfile, VerifyUser } from '../../../Services/Allservices/Admin/adminservices';
import moment from 'moment';
import { ActiveDeactiveUser, DeleteUser } from '../../../Services/LoginAccount/accountservices';
import Loader from '../../Utills/Loader';
import { UserProfileSearch } from '../../../Services/Allservices/Admin/adminservices';


const ProfileList = () => {
  const [show, setShow] = useState(false)
  const[killmessage,setkillmessage]=useState("")
  const [userData, setUserData] = useState([])
  const [Deleteid, setDeleteid] = useState()
  const [loading, setloading] = useState(false)
  const [FirstName, setFirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [email, setemail] = useState("")
  const [phone, setphone] = useState("")
  const [company, setcompany] = useState("")
  const [phone1, setphone1] = useState("")
  const [isbusiness, setisbusiness] = useState("")
  const [UserRoleId, setUserRoleId] = useState("")
  const [show1, setShow1] = useState(false)
  const [message, setmessage] = useState("")
  const navigate=useNavigate()
  const getProfileList = async () => {
    setFirstName("")
    setlastName("")
    setemail("")
    setphone("")
    setcompany("")
    setphone1("")
    setisbusiness("2")
    setloading(true)
    setUserRoleId("0")
    try {
      const response = await GetUserProfile()
      setUserData(response?.data?.result)
      setloading(false)
      if (response?.data?.status) {

      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  const Deleteuser = async (id) => {
    try {
      const payload = {
        id: id,
        isDeleted: true

      }
      const response = await DeleteUser(payload)

      if (response?.status) {
        setShow(false)
        getProfileList()
        setShow1(true)
        setmessage(response?.data?.result?.transactionMessage?.message)

      }
      else {
        console.log(response?.data?.message)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const profileChangeStatus = async (id, status) => {

    const payload = {
      id: id,
      isactive: status
    }

    const response = await ActiveDeactiveUser(payload)
    if (response?.data?.status) {
      getProfileList()
    }
    else {
      console.log(response?.data?.message)

    }
  }
  const Verifyuser = async (id) => {
    const response = await VerifyUser(id)
    if (response?.status) {

      getProfileList()
    }
    else {
      console.log(response?.data?.message)

    }
  }
  const getprofileSearch = async () => {
const payload = {
      "IsBusiness": parseInt(isbusiness),
      "FirstName": FirstName,
      "LastName": lastName,
      "UserName": email,
      "MobileNumber": phone,
      "CompanyName": company,
      "MobileNumber2": phone1,
      UserRoleId: parseInt(UserRoleId)
    }

    const response = await UserProfileSearch(payload)
    if (response?.data?.status) {
      setUserData(response?.data?.result?.reservationList)
    }
    else {
      console.log(response?.data?.message)

    }
  }
const killSession=async(id)=>{
var result = window.confirm("Möchten Sie sich wirklich überall abmelden?");
  if (result === true) {
    try {
      const payload = {
      UserId:id
      }
      const response = await DeleteUserById(payload)
if (response?.data?.status) {
  window.scrollTo({
    top: 250,
    behavior: 'smooth'
  });
  setkillmessage("Die Benutzerabmeldung wurde erfolgreich durchgeführt")
  setTimeout(() => {
    setkillmessage("")
  }, 5000);
}
  }
    catch (err) {
      console.log(err)
    } 
  }
 }
  useEffect(() => {
    getProfileList()
  }, [])

  return (
    <React.Fragment>
      {
        loading ? <Loader /> : <>
          <div className="main_container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading_div">
                  <h3 className="main_heading">Benutzer</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="filter_div">
                
                  <h4>Filter</h4>
                  <form>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Vorname" name="FirstName" value={FirstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Nachname" name="lastName" value={lastName} onChange={(e) => setlastName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Email" name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Rufnummer" name="phone" value={phone} onChange={(e) => setphone(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Firmenname" name="company" value={company} onChange={(e) => setcompany(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select className="form-control" name="isbusiness" value={isbusiness} onChange={(e) => setisbusiness(e.target.value)}>
                            <option disabled value="2" selected>UserTyp</option>
                            <option value="0">Privat</option>
                            <option value="1">Geschäftlich</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Office Nummer" name="phone1" value={phone1} onChange={(e) => setphone1(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select className="form-control" name="UserRoleId" value={UserRoleId} onChange={(e) => setUserRoleId(e.target.value)}>
                            <option disabled value="0" selected>ProfileTyp</option>
                            <option value="2">Location Anbieter</option>
                            <option value="3">Location Suchende</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <button type="button" className="theme_btn" onClick={getprofileSearch}>Suchen</button>
                    <button type="button" className="theme_btn" onClick={getProfileList}>Filter löschen</button>
                  </form>
                </div>
                <div>
                <p className='killmessage'>{killmessage?killmessage:""}</p>
                </div>
                
                <div className="table-responsive new_table">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>UserID</th>
                        <th>UserTyp</th>
                        <th>Name</th>
                        <th>ProfileTyp</th>
                        <th>Firmenname</th>
                        <th>Email<br />Rufnummer / Officenummer</th>
                        <th>Letzte<br />Anmeldung</th>
                        <th>Erstellt<br />am</th>
                        <th>Verifiziert</th>
                        <th>Aktiv</th>
                        <th>Aktion</th>
                      </tr>
                    </thead>
                    <tbody>
                      {

                        userData && userData.length > 0 ?
                          userData.map((item) => (
                            <tr key={item?.id}>
                              <td>{item?.id}</td>
                              <td>{item.isBusiness ? <span>Geschäftlich</span> : <span>Privat</span>}</td>
                              <td><span>{item?.firstName}</span> <span>{item?.lastName}</span></td>
                              <td>{item?.userRoleId === 2 ? " Location Anbieter" : item?.userRoleId === 3 ? " Location Suchende" : "Administrator"} </td>
                              <td>{item?.companyName ? item?.companyName : "-"}</td>
                              <td>{item?.userName ? item?.userName : "-"}<br />{item?.mobileNumber ? item?.mobileNumber : "-"}<br />{item?.mobileNumber2 ? item?.mobileNumber2 : "-"}</td>
                              <td>{item?.login !== "0001-01-01T00:00:00" && item?.login ? <span>{moment(item?.login).format('DD.MM.YYYY')} {moment(item?.login).add(1,'hours').format('HH:mm:ss')}</span> : "-"}</td>
                              <td><span>{moment(item?.createdDate).format('DD.MM.YYYY')} {moment(item?.createdDate).add(1,'hours').format('HH:mm:ss')}</span></td>
                              <td>
                                {
                                  item?.userRoleId === 3 ? "" : <label className="custom_check">
                                    <input type="checkbox" defaultChecked={item?.isVarified} onChange={() => Verifyuser(item?.id)} />
                                    <span className="checkmark"></span>
                                  </label>
                                }
                              </td>
                              <td>
                                <label className="custom_check">
                                  <input type="checkbox" defaultChecked={item?.isActive} onChange={() => profileChangeStatus(item?.id, !item?.isActive)} />
                                  <span className="checkmark"></span>
                                </label>
                              </td>
                              <td className="d-flex">
                                <NavLink to="/profiledetail" state={{ id: item?.id }} ><img src={eye} alt="img" /></NavLink>
                                <NavLink onClick={() => { setDeleteid(item?.id); setShow(true) }}><img src={bin} alt="img" /></NavLink>
                                <NavLink onClick={()=>{killSession(item?.id)}}><img src={kill_session} alt="img" /></NavLink>
                              </td>

                            </tr>

                          ))

                          :
                          <div className="no_record"> Kein Eintrag gefunden!</div>
                      }
                    </tbody>
                  </table>
                </div>
              </div>

            </div>

            <Modal className="new_Pop" show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Body className="modalbg">
                <form>
                  <div className="text-center">
                    <p className="deletetext">Möchtest du den Benutzer wirklich löschen?</p>
                  </div>
                  <div className="text-center">
                    <NavLink onClick={() => Deleteuser(Deleteid)} className="theme_btn">Ja</NavLink>
                    <NavLink className="theme_btn" onClick={() => setShow(false)}>Nein</NavLink>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
            <Modal className="new_Pop" show={show1} onHide={() => setShow1(false)} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Body className="modalbg">
                <button type="button" className="close1" onClick={() => setShow1(false)} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="text-center">
                  <p className="deletetext">{message ? message : ""}</p>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </>
      }
    </React.Fragment>
  )
}

export default ProfileList
