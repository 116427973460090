import React, { useEffect, useState } from 'react'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './ManageInvoice.css';
import { de } from 'date-fns/locale'
import { DatePicker } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import eye from '../../../Assests/dashboard/images/eye.svg';
import download from '../../../Assests/dashboard/images/download.svg';
import upload from '../../../Assests/dashboard/images/upload.svg';
import { NavLink } from 'react-router-dom';
import { DownloadInvoiceDocument, GetManageInvoice, InvoiceClick, InvoiceListSearch, InvoiceListSearchAdmin, ProviderPaymentUpDate } from '../../../Services/Allservices/Admin/adminservices';
import moment from 'moment';
import Loader from '../../Utills/Loader';


const ManageInvoice = () => {
  const [invoiceData, setInvoiceData] = useState([])
  const [loading, setloading] = useState(false)
  const [invoiceId, setinvoiceId] = useState()
  const [ReservationDate, setReservationDate] = useState(null)
  const [SubscriptionTypeId, setSubscriptionTypeId] = useState()
  const [companyName, setcompanyName] = useState()
  const [FirstName, setFirstName] = useState()
  const [lastName, setlastName] = useState()
  const [PaymentType, setPaymentType] = useState()
  const [IsBusiness, setIsBusiness] = useState()

  const getInvoiceList = async () => {
    setinvoiceId("")
    setReservationDate(null)
    setSubscriptionTypeId(0)
    setcompanyName("")
    setlastName("")
    setFirstName("")
    setPaymentType("")
    setIsBusiness(2)
    setloading(true)
    try {
      const response = await GetManageInvoice()
      setInvoiceData(response?.data?.result)
      setloading(false)
    }
    catch (err) {
      console.log(err)
    }
  }

  const PaymentChangeStatus = async (id, status) => {


    const response = await ProviderPaymentUpDate({ Id: id, IsPaid: status })
    if (response?.data?.status) {
      getInvoiceList()
    }
    else {
      console.log(response?.data?.message)

    }
  }
  const DownloadStatus = async (id) => {
    setloading(true)
    const response = await InvoiceClick(id)
    if (response?.status) {
      if (response?.data?.invoiceCheck?.length > 0) {
        try {
          const response = await DownloadInvoiceDocument(id)
          if (response?.data?.status) {
            setloading(false)
            const path = `https://venoon.com${response?.data?.result?.documentPath}`;
            const link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute('href', path);
            link.setAttribute('download', `VENOON-RG-${id}`);
            document.body.appendChild(link)
            link.click();
            link.remove();
          }
          else {
            console.log(response?.data?.message)
          }
        }
        catch (err) {
          console.log(err)
        }
      } else {
        setloading(false)
      }

    }
    else {
      setloading(false)
      console.log(response?.data?.message)

    }
  }

  const invoiceListSearch = async () => {
    if (SubscriptionTypeId === "4") {
      const payload = {
        "SubscriptionTypeId": 4
      }
      const response = await InvoiceListSearchAdmin(payload)
      if (response?.data?.status) {
        setInvoiceData(response?.data?.result?.reservationList)
      }
      else {
        console.log(response?.data?.message)

      }
    }
    else {
      const payload =
      {
        "RgNo": invoiceId ? parseInt(invoiceId) : 0,
        "SubscriptionTypeId": SubscriptionTypeId ? parseInt(SubscriptionTypeId) : 0,
        "CompanyName": companyName ? companyName : "",
        "FirstName": FirstName ? FirstName : "",
        "LastName": lastName ? lastName : "",
        "PaymentType": PaymentType ? PaymentType : "",
        "IsBusiness": IsBusiness ? parseInt(IsBusiness) : 2,
        "RgDate": ReservationDate ? moment(ReservationDate).format("YYYY-MM-DD") : null


      }

      const response = await InvoiceListSearch(payload)
      if (response?.data?.status) {
        setInvoiceData(response?.data?.result?.reservationList)
      }
      else {
        console.log(response?.data?.message)

      }
    }
  }
  useEffect(() => {
    getInvoiceList()
  }, [])

  return (
    <React.Fragment>
      {
        loading ? <Loader /> : <>
          <div className="main_container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading_div">
                  <h3 className="main_heading">Rechnungen verwalten</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="filter_div">
                  <h4>Filter</h4>
                  <form>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="RG-Nr." name="invoiceId" value={invoiceId} onChange={(e) => setinvoiceId(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <DatePicker
                            date={ReservationDate}
                            onDateChange={setReservationDate}
                            format='dd MMM yyyy'
                            locale={de}
                          >
                            {({ inputProps, focus }) => (
                              <div className='date-range'>
                                <input
                                  className={'input form-control mb-3 dateicon' + (focus === ReservationDate ? ' -focused' : '')}
                                  {...inputProps}
                                  placeholder="RG-Datum"
                                />

                              </div>
                            )}
                          </DatePicker >

                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select className="form-control" name="SubscriptionTypeId" value={SubscriptionTypeId} onChange={(e) => setSubscriptionTypeId(e.target.value)}>
                            <option disabled value="0" selected >Abo</option>
                            <option value="2">Basis</option>
                            <option value="3">Premium</option>
                            <option value="4">Kein Abo</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Firmenname" name="companyName" value={companyName} onChange={(e) => setcompanyName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Vorname" name="FirstName" value={FirstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Nachname" name="lastName" value={lastName} onChange={(e) => setlastName(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select className="form-control" name="IsBusiness" value={IsBusiness} onChange={(e) => setIsBusiness(e.target.value)}>
                            <option disabled value="2" selected>UserTyp</option>
                            <option value="0">Privat</option>
                            <option value="1">Geschäftlich</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                          <select className="form-control" name="PaymentType" value={PaymentType} onChange={(e) => setPaymentType(e.target.value)}>
                            <option disabled value="" selected>Zahlungstyp</option>
                            <option value="Kreditkarte">Kreditkarte</option>
                            <option value="Rechung">Rechung</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <button type="button" className="theme_btn" onClick={invoiceListSearch}>Suchen</button>
                    <button type="button" className="theme_btn" onClick={getInvoiceList}>Filter löschen</button>
                  </form>
                </div>
                <div className="table-responsive new_table">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>RG-Nr.</th>
                        <th>RG-Datum</th>
                        <th>Zahlungstyp</th>
                        <th>Bezahlt</th>
                        <th>Abo</th>
                        <th>Fällig am</th>
                        <th>Name</th>
                        <th>Firmenname</th>
                        <th>UserTyp</th>
                        <th>Bezahlen</th>
                        <th>RG-Betrag</th>
                        <th>Aktion</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        invoiceData && invoiceData.length > 0 ?
                          invoiceData.map((item) => (
                            <tr key={item?.rgNo}>
                              <td>{item?.rgNo}<div>{item?.isDeleted ? "storniert" : ""}</div></td>
                              <td>{moment(item?.rgDate).format("DD.MM.YYYY")}</td>
                              <td>{item?.paymentType ? item?.paymentType : "-"}</td>
                              <td> {item?.isDeleted ? "-" : item?.paymentType === "Kreditkarte" ? moment(item?.rgDate).format("DD.MM.YYYY") : (item?.isPaid && item?.paymentType === "Rechung") ? moment(item?.paidOn).format("DD.MM.YYYY") : "-"}</td>
                              <td>{item?.subscriptiontype ? item?.subscriptiontype : "-"}</td>
                              <td>{item?.isDeleted ? "-" : moment(item?.dueOn).format("DD.MM.YYYY")}</td>
                              <td><span>{item?.firstName}</span> <span>{item?.lastName}</span></td>
                              <td>{item?.companyName ? item?.companyName : "-"} </td>
                              <td>{item.isBusiness ? <span>Geschäftlich</span> : <span>Privat</span>}</td>
                              <td>
                                <label className="custom_check">
                                  <input type="checkbox" defaultChecked={item?.isPaid} disabled={item?.isDeleted || item?.paymentType === "Kreditkarte"} onChange={() => PaymentChangeStatus(item?.rgNo, !item?.isPaid)} />
                                  <span className="checkmark cancelledcheck"></span>
                                </label>
                              </td>
                              <td>{item?.isDeleted ? "-" : item?.amount} </td>
                              <td className="d-flex"><NavLink to="/invoicedetail" state={item?.rgNo}><img src={eye} alt="img" /></NavLink>
                                <button className="button_outline1" disabled={!(item?.statusOn)} onClick={() => { DownloadStatus(item?.rgNo) }}><img src={download} alt="img" /></button>
                                <NavLink to="/invoiceupload" state={item?.rgNo} ><img src={upload} alt="img" /></NavLink></td>
                            </tr>
                          ))
                          :
                          <div className="no_record"> Kein Eintrag gefunden!</div>
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      }

    </React.Fragment>
  )
}

export default ManageInvoice
